<template>
  <div id="modal">
    <div class="layerWrap">
      <div class="toast membership re-name md-invite">
        <div class="inner">
          <div class="title-wrap"><p>멤버 초대하기</p></div>
          <div class="cont-wrap">
            <div>
              <div class="invite-complete-wrap">
                <div class="tit">
                  <h1>패밀리 생성 완료!<br />이제, 멤버를 초대해보세요</h1>
                </div>
                <div class="desc-invite">
                  <dl class="invite">
                    <dt>멤버 초대 방법</dt>
                    <dd>
                      <div class="text-box">
                        <p>
                          멤버십 화면의 ‘패밀리 멤버 추가하기’를 눌러<br />
                          언제든 초대가 가능해요!
                        </p>
                        <!-- <sub>가족, 친구 누구나 가능 (최대 3명)</sub> -->
                      </div>
                      <figure>
                        <img src="@/assets/images/member/img-invite-join.png" alt="패밀리 멤버 추가하기 버튼" />
                      </figure>
                    </dd>
                  </dl>
                  <dl class="accept">
                    <dt>초대 수락 안내</dt>
                    <dd>
                      <div class="text-box">
                        <p>친구에게 아래 초대 수락 과정을<br />미리 알려주면 좋아요.</p>
                      </div>
                      <div class="join-info">
                        <ul>
                          <li>
                            <strong>
                              패밀리 서비스를 이용할<br />앱 선택하기 <span>GS SHOP 또는 우리동네GS</span>
                            </strong>
                          </li>
                          <li>
                            <strong>초대한 사람의<br />휴대폰 번호 뒤 4자리 입력</strong>
                          </li>
                          <li class="comp"><strong>패밀리 가입 완료!</strong></li>
                        </ul>
                      </div>
                    </dd>
                  </dl>
                  <p class="desc">
                    카카오톡 초대장 수락 유효 기간은 3일이며<br />
                    친구가 깜빡해서 3일이 지났다면 다시 초대해주세요.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="b-btn-wrap">
            <div>
              <button class="check" @click="famyInvite"><span>지금 초대하러 가기</span></button>
            </div>
          </div>
        </div>
        <div class="close">
          <button aria-label="닫기" @click="onEmitCencel"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalToastTerms',
  props: {
    join: Boolean
  },
  methods: {
    onEmitCencel() {
      this.$emit('famyInvite', false)
    },
    famyInvite() {
      this.$emit('famyInvite', true)
    }
  }
}
</script>
