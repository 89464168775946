var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"membership-box"},[_c('div',{staticClass:"family-membership"},[_vm._m(0),_c('div',{staticClass:"family-ipt",class:{
        'ipt-typing': _vm.inputCheck == true,
        'ipt-end': _vm.inputEnd == true && _vm.isError == false,
        'ipt-error': _vm.isError == true
      }},[_c('label',{attrs:{"for":"familyName"}},[_vm._v("패밀리 명")]),_c('input',{ref:"familyName",attrs:{"disabled":_vm.nameLoad,"type":"text","placeholder":"패밀리명을 입력해주세요","maxlength":_vm.maxlength},domProps:{"value":_vm.familyNameValue},on:{"input":[_vm.typing,_vm.typingCheck],"focus":_vm.typingRe,"blur":_vm.typingEnd}}),_c('button',{staticClass:"family-ipt-remove",attrs:{"type":"button"},on:{"click":_vm.reset,"mousedown":function($event){return _vm.clear($event)}}},[_c('span',{staticClass:"hidden"},[_vm._v("지우기")])]),_vm._m(1),_c('div',{staticClass:"ipt-error-box"},[_c('p',{staticClass:"ipt-error-txt"},[_vm._v(_vm._s(_vm.validMessage))]),_vm._m(2)])]),_c('div',{staticClass:"b-btn-wrap fixed"},[_c('div',[(!_vm.nameLoad && !(_vm.isError || _vm.familyNameValue.length == 0))?_c('button',{staticClass:"check",class:{loading: _vm.nameLoad == true, disabled: _vm.familyNameValue.length == 0 || _vm.isError},on:{"click":_vm.onLoad}},[_c('span',[_vm._v("완료")])]):(!_vm.nameLoad && (_vm.isError || _vm.familyNameValue.length == 0))?_c('button',{staticClass:"check",class:{loading: _vm.nameLoad == true, disabled: _vm.familyNameValue.length == 0 || _vm.isError}},[_c('span',[_vm._v("완료")])]):_c('button',{staticClass:"check",class:{loading: _vm.nameLoad == true, disabled: _vm.familyNameValue.length == 0 || _vm.isError}},[_vm._m(3)])])])]),(_vm.isAlert)?_c('ModalAlert',{attrs:{"alert":_vm.alert,"type":_vm.alert.type},on:{"onLayer":_vm.onLayer}}):_vm._e(),(_vm.isSns)?_c('SnsInvite',{ref:"snsInvite",attrs:{"famyGrpId":_vm.famyGrpId,"encFamyGrpId":_vm.encFamyGrpId}}):_vm._e(),(_vm.isFamyInvite)?_c('ModalFamilyInvite',{on:{"famyInvite":_vm.famyInvite}}):_vm._e(),(true)?_c('TagManager',{ref:"tagManager"}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"fm-tit"},[_vm._v("패밀리 이름을"),_c('br'),_vm._v("만들어주세요")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ipt-note-box"},[_c('p',{staticClass:"ipt-note-txt"},[_vm._v("한글 6자 또는 영문/숫자 최대 12자")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"ipt-error-btn",attrs:{"type":"button"}},[_c('span',{staticClass:"hidden"},[_vm._v("오류")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('span',{staticClass:"hidden"},[_vm._v("로딩")])])
}]

export { render, staticRenderFns }