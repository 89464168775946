<template>
  <div class="membership-top">
    <h2>{{ injectTitle }}</h2>
    <button
      v-if="'familyAcceptInvite' != $route.name && 'IntgFamilyInvite' != $route.name"
      type="button"
      @click="goBack"
    >
      <span class="hidden">이전으로</span>
    </button>
  </div>
</template>
<script>
export default {
  name: 'DefaultHeader',
  inject: ['injectTitle'],
  data() {
    return {
      chanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
      chanlMyDomain: this.$store.state.apiStore.bodyParam.chanlMyDomain,
      isApp:
        /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) ||
        '2010' === this.$store.state.apiStore.bodyParam.chanlCd,
      closeUrl: this.$store.state.apiStore.historyBack.closeUrl,
      isAct: !this.$isEmpty(this.$route.query.act),
      act: !this.$isEmpty(this.$route.query.act) ? this.$route.query.act : '',
      rtnUrl: !this.$isEmpty(this.$route.query.rtnUrl) ? this.$route.query.rtnUrl : ''
    }
  },
  created() {
    console.log('## header isAct : ' + this.isAct + ', act : ' + this.act + ', rtnUrl : ' + this.rtnUrl)
  },
  mounted() {},
  methods: {
    // app/web 체크하여 창닫기 함수.
    goBack() {
      if (this.isApp) {
        if ('3010' == this.chanlCd && this.backClose()) {
          sessionStorage.clear()
          if (!this.isAct) {
            location.href = 'toapp://newpage?closeYn=Y&url=' + this.chanlMyDomain + '/mygsshop/myshopInfo.gs'
          } else {
            if ('close' == this.act) {
              console.log('## header gsBack app close')
              location.href = 'toapp://close'
            } else if ('back' == this.act) {
              console.log('## header gsBack app back')
              location.href = 'toapp://back'
            } else if ('new' == this.act && !this.$isEmpty(this.rtnUrl)) {
              console.log('## header gsBack app new : ' + this.rtnUrl)
              location.href = 'toapp://newpage?closeYn=Y&url=' + this.chanlMyDomain + this.rtnUrl
            } else {
              console.log('## header gsBack app empty 마이쇼핑강제 이동')
              location.href = 'toapp://newpage?closeYn=Y&url=' + this.chanlMyDomain + '/mygsshop/myshopInfo.gs'
            }
          }
        } else if ('2010' == this.chanlCd && this.backClose()) {
          sessionStorage.clear()
          location.href = 'toapp://close'
        } else if ('2020' == this.chanlCd && this.backClose()) {
          sessionStorage.clear()
          location.href = 'toapp://back'
        } else {
          // GS샵 채널 app : 통합멤버십 모든 화면에 대한 header key 컨트롤 기능 추가
          //  대상 화면 : 기본 close 정의 외 모든 화면
          if ('3010' == this.chanlCd && this.isAct) {
            sessionStorage.clear()
            if ('close' == this.act) {
              console.log('## header isAct app close')
              location.href = 'toapp://close'
            } else if ('new' == this.act && !this.$isEmpty(this.rtnUrl)) {
              console.log('## header isAct app new : ' + this.rtnUrl)
              location.href = 'toapp://newpage?closeYn=Y&url=' + this.chanlMyDomain + this.rtnUrl
            } else {
              location.href = 'toapp://back'
            }
          } else {
            location.href = 'toapp://back'
          }
        }
      } else {
        if ('3010' === this.chanlCd && this.backClose()) {
          // GS샵 action 설정이 없는 경우 : 기존 마이쇼핑 강제이동
          console.log('## header goBack isApp : ' + this.isApp + ', rtnUrl : ' + this.rtnUrl + ', act : ' + this.act)
          if (!this.isAct) {
            location.href = this.chanlMyDomain + '/mygsshop/myshopInfo.gs'
          } else {
            if ('new' == this.act && !this.$isEmpty(this.rtnUrl)) {
              console.log('## header gsBack web new')
              location.href = this.chanlMyDomain + this.rtnUrl
            } else {
              console.log('## header gsBack web empty 마이쇼핑강제 이동')
              location.href = this.chanlMyDomain + '/mygsshop/myshopInfo.gs'
            }
          }
        } else if ('2020' === this.chanlCd && this.backClose()) {
          location.href = this.chanlMyDomain + '/cu/my_page'
        } else {
          this.$router.go(-1)
        }
      }
    },
    backClose() {
      var name = this.$route.name
      var value = false
      this.closeUrl.forEach((e) => {
        if (e === name) {
          value = true
          console.log(' 닫기해야하는 url : ' + name)
        }
      })
      return value
    }
  }
}
</script>
<style lang="scss"></style>
