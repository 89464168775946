var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"modal"}},[_c('div',{staticClass:"layerWrap"},[_c('div',{staticClass:"toast membership re-name"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"cont-wrap"},[_c('div',[_c('h2',[_vm._v("패밀리 초대자 휴대폰 번호 입력")]),_c('div',{staticClass:"membership-box"},[_vm._m(0),_c('div',{staticClass:"family-membership"},[_c('div',{staticClass:"family-ipt",class:{
                    'ipt-typing': _vm.inputCheck == true,
                    'ipt-end': _vm.inputEnd == true && _vm.isError == false,
                    'ipt-error': _vm.isError == true
                  }},[_c('label',{attrs:{"for":"certNo"}},[_vm._v("초대자 휴대폰 번호 뒤 4자리")]),_c('input',{ref:"certNo",attrs:{"disabled":_vm.nameLoad,"type":"tel","placeholder":"휴대폰 번호 뒤 4자리","maxlength":_vm.maxlength},domProps:{"value":_vm.certNo},on:{"input":_vm.typingCheck}}),_c('div',{staticClass:"ipt-error-box"},[_c('p',{staticClass:"ipt-error-txt"},[_vm._v(_vm._s(_vm.validMessage))]),_vm._m(1)])])])])])]),_c('div',{staticClass:"b-btn-wrap"},[_c('div',[_c('button',{staticClass:"check",class:{loading: _vm.nameLoad == true, disabled: _vm.certNo.length < 4 || _vm.isError},on:{"click":_vm.closeApply}},[_c('span',[_vm._v("확인")])])])])]),_c('div',{staticClass:"close"},[_c('button',{attrs:{"aria-label":"닫기"},on:{"click":function($event){return _vm.onLayer('cert')}}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification"},[_c('div',{staticClass:"text"},[_c('i',{staticClass:"ico-info_circle_full"}),_c('p',{staticClass:"desc"},[_vm._v("패밀리 가입을 위해, 나를 초대한 분의 휴대폰 번호 맨 뒤 4자리를 입력해 주세요!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"ipt-error-btn",attrs:{"type":"button"}},[_c('span',{staticClass:"hidden"},[_vm._v("오류")])])
}]

export { render, staticRenderFns }