import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '@/App.vue'
import routes from '@/router'
import store from '@/store'
import Axios from 'axios'
import common from './mixins/common'
import VueCookies from 'vue-cookies'
import dayjs from 'dayjs'
import device from 'vue-device-detector'
// Amplitude 적용
import amplitude from 'amplitude-js'

var domainList = [
  {
    domainName: 'localhost', //로컬 도메인명
    kakaoApi: 'e04ecdccd2bedf2c25ca58b7570a94f9',
    apiUrl: 'http://10.53.43.35:8080/montblanc',
    chanlDomain: 'http://localhost:8080',
    chanlMyDomain: 'http://localhost:8000',
    ampkey: process.env.VUE_APP_WDG_AMPLITUDE_KEY,
    chanlCd: '3010',
    siteId: '3000',
    // intgMemNo: '9000472736', //마이계정
    // intgMemNo: '1000027525', //웰컴등급 테스트 계정
    // intgMemNo: '1000027514', //intvip1 웰컴등급 테스트 계정
    // intgMemNo: '1000137514', //intvip1 웰컴등급 테스트 계정
    // intgMemNo: '1000002141', //패밀리 멤버 4명 테스트 계정
    // intgMemNo: '1001224665', //패밀리 멤버 4명 테스트 계정
    // intgMemNo: '1003800182', //멤버십(개인) 등급 산정 전 계정
    intgMemNo: '1000029498', //테스트게정(개인) 등급 산정 
    gshBnftLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_MYSHOP_URL,
    gsrBnftLink: process.env.VUE_APP_GSR_DOMAIN + process.env.VUE_APP_GSR_MYSHOP_DEEP_LINK,
    wdgBnftLink: process.env.VUE_APP_WDG_MYSHOP_DEEP_LINK,
    gshBnftVipLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_VIP_URL
  },
  {
    domainName: process.env.VUE_APP_GSHS_DOMAIN_NAME, //몽블랑 도메인명
    chanlCd: process.env.VUE_APP_GSH_CHANL_CD,
    siteId: process.env.VUE_APP_GSH_SITE_ID,
    kakaoApi: process.env.VUE_APP_GSH_KAKAO,
    apiUrl: process.env.VUE_APP_GSHS_API,
    chanlDomain: process.env.VUE_APP_GSHS_DOMAIN,
    chanlMyDomain: process.env.VUE_APP_GSH_MY_DOMAIN,
    ampkey: process.env.VUE_APP_GSH_AMPLITUDE_KEY,
    //혜택 deep link 추가
    gshBnftLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_MYSHOP_URL,
    gsrBnftLink: process.env.VUE_APP_GSR_DOMAIN + process.env.VUE_APP_GSR_MYSHOP_DEEP_LINK,
    wdgBnftLink: process.env.VUE_APP_WDG_MYSHOP_DEEP_LINK,
    gshBnftVipLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_VIP_URL
  },
  {
    domainName: process.env.VUE_APP_GSH_DOMAIN_NAME, //GS샵 도메인명
    chanlCd: process.env.VUE_APP_GSH_CHANL_CD,
    siteId: process.env.VUE_APP_GSH_SITE_ID,
    kakaoApi: process.env.VUE_APP_GSH_KAKAO,
    apiUrl: process.env.VUE_APP_GSH_API,
    chanlDomain: process.env.VUE_APP_GSH_DOMAIN,
    chanlMyDomain: process.env.VUE_APP_GSH_MY_DOMAIN,
    ampkey: process.env.VUE_APP_GSH_AMPLITUDE_KEY,
    //혜택 deep link 추가
    gshBnftLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_MYSHOP_URL,
    gsrBnftLink: process.env.VUE_APP_GSR_DOMAIN + process.env.VUE_APP_GSR_MYSHOP_DEEP_LINK,
    wdgBnftLink: process.env.VUE_APP_WDG_MYSHOP_DEEP_LINK,
    gshBnftVipLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_VIP_URL
  },
  {
    domainName: process.env.VUE_APP_WDG_DOMAIN_NAME, //우동G 도메인명
    chanlCd: process.env.VUE_APP_WDG_CHANL_CD,
    siteId: process.env.VUE_APP_WDG_SITE_ID,
    kakaoApi: process.env.VUE_APP_WDG_KAKAO,
    apiUrl: process.env.VUE_APP_WDG_API,
    chanlDomain: process.env.VUE_APP_WDG_DOMAIN,
    chanlMyDomain: process.env.VUE_APP_WDG_MY_DOMAIN,
    ampkey: process.env.VUE_APP_WDG_AMPLITUDE_KEY,
    //혜택 deep link 추가
    gshBnftLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_MYSHOP_URL,
    gsrBnftLink: process.env.VUE_APP_GSR_DOMAIN + process.env.VUE_APP_GSR_MYSHOP_DEEP_LINK,
    wdgBnftLink: process.env.VUE_APP_WDG_MYSHOP_DEEP_LINK,
    gshBnftVipLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_VIP_URL
  },
  {
    domainName: process.env.VUE_APP_GSR_STG_DOMAIN_NAME, //GS프래쉬 스테이지 도메인명
    chanlCd: process.env.VUE_APP_GSR_CHANL_CD,
    siteId: process.env.VUE_APP_GSR_SITE_ID,
    kakaoApi: process.env.VUE_APP_GSR_KAKAO,
    apiUrl: process.env.VUE_APP_GSR_STG_API,
    chanlDomain: process.env.VUE_APP_GSR_STG_DOMAIN,
    chanlMyDomain: process.env.VUE_APP_GSR_STG_MY_DOMAIN,
    ampkey: process.env.VUE_APP_GSR_AMPLITUDE_KEY,
    //혜택 deep link 추가
    gshBnftLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_MYSHOP_URL,
    gsrBnftLink: process.env.VUE_APP_GSR_STG_DOMAIN + process.env.VUE_APP_GSR_MYSHOP_DEEP_LINK,
    wdgBnftLink: process.env.VUE_APP_WDG_MYSHOP_DEEP_LINK,
    gshBnftVipLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_VIP_URL
  },
  {
    domainName: process.env.VUE_APP_GSR_DOMAIN_NAME, //GS프래쉬 도메인명
    chanlCd: process.env.VUE_APP_GSR_CHANL_CD,
    siteId: process.env.VUE_APP_GSR_SITE_ID,
    kakaoApi: process.env.VUE_APP_GSR_KAKAO,
    apiUrl: process.env.VUE_APP_GSR_API,
    chanlDomain: process.env.VUE_APP_GSR_DOMAIN,
    chanlMyDomain: process.env.VUE_APP_GSR_MY_DOMAIN,
    ampkey: process.env.VUE_APP_GSR_AMPLITUDE_KEY,
    //혜택 deep link 추가
    gshBnftLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_MYSHOP_URL,
    gsrBnftLink: process.env.VUE_APP_GSR_DOMAIN + process.env.VUE_APP_GSR_MYSHOP_DEEP_LINK,
    wdgBnftLink: process.env.VUE_APP_WDG_MYSHOP_DEEP_LINK,
    gshBnftVipLink: process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_DEEP_LINK
      + process.env.VUE_APP_GSH_DOMAIN + process.env.VUE_APP_GSH_VIP_URL
  }
]

var hostname = window.location.hostname //- 웹 호스트의 도메인 네임 반환
hostname = hostname.toLowerCase()
var index = domainList.findIndex((i) => hostname.includes(i.domainName))
if (index > -1) {
  domainList[index].domainName = hostname
  store.commit('chageApiBodyParam', domainList[index])
  Axios.defaults.baseURL = domainList[index].apiUrl
}

Axios.defaults.withCredentials = true
Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.prototype.$axios = Axios
Vue.use(common)
Vue.use(VueCookies)
Vue.use(dayjs)
Vue.prototype.$dayjs = dayjs
Vue.use(device)
// Amplitude 적용
Vue.use(amplitude)
Vue.prototype.$amplitude = amplitude
// Amplitude 적용

const router = new VueRouter({
  mode: 'history',
  routes
})

var isApp = /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) || '2010' == domainList[index].chanlCd
var chanlCode = domainList[index].chanlCd
var chanlDomain = domainList[index].chanlDomain
var chanlMyDomain = domainList[index].chanlMyDomain
var ampkey = domainList[index].ampkey

// Amplitude 적용
amplitude.getInstance().init(ampkey)
// Amplitude 적용

router.beforeEach(function (to, from, next) {
  console.log(
    'env.NODE_ENV : ' + process.env.NODE_ENV + ', isApp:' + isApp + ', router-> to:' + to.name + ', from:' + from.name + ', session:' + sessionStorage.getItem('inCheck1')
  )
  // 앱 닫고 마이페이지 새로고침 하는 케이스
  if (
    ('FamilyExit' == from.name && 'ExitPage' == to.name) || // 패밀리 탈퇴 후
    ('FamilyGrade' == from.name && 'FamilyName' == to.name) || // 패밀리 생성 후 뒤로(패밀리 등급) 으로 갈때
    ('FamilyGrade' == from.name && 'ExitPage' == to.name) || // 패밀리 등급 사용 불가능 할 때
    ('FamilyJoin' == from.name && 'ExitPage' == to.name) || // 패밀리 수락 불가능 시에
    // 신규 뒤로가기 체크 추가
    ('IntgMemshpGrade' == from.name && 'IntgFamilyName' == to.name) // 패밀리 생성 후 뒤로(멤버십/패밀리 등급) 으로 갈때
  ) {
    console.log('앱 닫고 마이페이지 새로고침 하는 케이스 1')
    if (isApp) {
      sessionStorage.clear()
      console.log('router-> case1')
      if ('3010' == chanlCode) {
        location.href = 'toapp://newpage?closeYn=Y&url=' + chanlMyDomain + '/mygsshop/myshopInfo.gs'
      } else if ('2010' == chanlCode) {
        location.href = 'toapp://close'
      } else if ('2020' == chanlCode) {
        location.href = chanlMyDomain + '/cu/my_page'
      }
    } else {
      console.log('router-> case2')
      if ('3010' == chanlCode) {
        location.href = chanlDomain + '/mygsshop/myshopInfo.gs'
      } else if ('2010' == chanlCode) {
        router.go(-1)
      } else if ('2020' == chanlCode) {
        location.href = chanlMyDomain + '/cu/my_page'
      }
    }
  }
  // 앱 닫기만 하는케이스
  else if (
    sessionStorage.getItem('inCheck1') &&
    from.name == null &&
    ('MonthGrade' == to.name || 'FamilyGrade' == to.name || 'FamilyJoin' == to.name || 'FamilyInfo' == to.name ||
      // 신규 뒤로가기 체크 추가
      'IntgMemshpGrade' == to.name || 'IntgFamilyJoin' == to.name || 'IntgFamilyInfo' == to.name || 'WhatIntgMemshp' == to.name)
  ) {
    if (isApp) {
      sessionStorage.clear()
      console.log('router-> case3')
      if ('2010' == chanlCode || '3010' == chanlCode) {
        location.href = 'toapp://close'
      } else if ('2020' == chanlCode) {
        location.href = 'toapp://back'
      }
    }
  } else {
    console.log('router-> case4')
    next()
    if (isApp) {
      sessionStorage.setItem('inCheck1', true)
    }
  }
})
new Vue({
  render: (h) => h(App),
  router,
  store
}).$mount('#app')