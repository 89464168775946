<template>
  <div class="membership-box">
    <div class="family-membership">
      <h3 class="fm-tit">패밀리 이름을<br />만들어주세요</h3>
      <!-- ipt-typing : 입력중 / ipt-end : 입력 완료 /  ipt-error : 에러 -->
      <div
        class="family-ipt"
        :class="{
          'ipt-typing': inputCheck == true,
          'ipt-end': inputEnd == true && isError == false,
          'ipt-error': isError == true
        }"
      >
        <label for="familyName">패밀리 명</label>
        <input
          :disabled="nameLoad"
          type="text"
          placeholder="패밀리명을 입력해주세요"
          ref="familyName"
          :value="familyNameValue"
          @input="typing"
          @focus="typingRe"
          @blur="typingEnd"
          v-on:input="typingCheck"
          :maxlength="maxlength"
        />
        <button type="button" class="family-ipt-remove" @click="reset" @mousedown="clear($event)">
          <span class="hidden">지우기</span>
        </button>
        <div class="ipt-note-box">
          <p class="ipt-note-txt">한글 6자 또는 영문/숫자 최대 12자</p>
        </div>
        <div class="ipt-error-box">
          <p class="ipt-error-txt">{{ validMessage }}</p>
          <button type="button" class="ipt-error-btn"><span class="hidden">오류</span></button>
        </div>
      </div>
      <div class="b-btn-wrap fixed">
        <div>
          <button
            v-if="!nameLoad && !(isError || familyNameValue.length == 0)"
            class="check"
            :class="{loading: nameLoad == true, disabled: familyNameValue.length == 0 || isError}"
            @click="onLoad"
          >
            <span>완료</span>
          </button>
          <button
            v-else-if="!nameLoad && (isError || familyNameValue.length == 0)"
            class="check"
            :class="{loading: nameLoad == true, disabled: familyNameValue.length == 0 || isError}"
          >
            <span>완료</span>
          </button>
          <button
            v-else
            class="check"
            :class="{loading: nameLoad == true, disabled: familyNameValue.length == 0 || isError}"
          >
            <span><span class="hidden">로딩</span></span>
          </button>
        </div>
      </div>
    </div>

    <!-- 공통 Alert 레이어 -->
    <ModalAlert v-if="isAlert" @onLayer="onLayer" :alert="alert" :type="alert.type" />
    <!-- SNS공유 component (카카오톡 공유) -->
    <SnsInvite v-if="isSns" :famyGrpId="famyGrpId" :encFamyGrpId="encFamyGrpId" ref="snsInvite" />
    <!--  UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14 -->
    <ModalFamilyInvite v-if="isFamyInvite" @famyInvite="famyInvite" />
    <!-- 앰플리튜드 기능 추가-->
    <TagManager v-if="true" ref="tagManager" />
  </div>
</template>

<script>
import SnsInvite from '@/views/comm/integrate/SnsInvite.vue'
import ModalAlert from '@/views/comm/integrate/ModalAlert.vue'
import ModalFamilyInvite from '@/views/comm/integrate/ModalFamilyInvite.vue'
import TagManager from '@/views/comm/TagManager.vue'

export default {
  name: 'IntgFamilyName',
  inject: ['sendTitle'],
  components: {
    ModalAlert,
    SnsInvite,
    // UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14
    ModalFamilyInvite,
    // 앰플리튜드 기능 추가
    TagManager
  },
  data() {
    return {
      title: '패밀리 만들기',
      familyNameValue: '',
      inputCheck: false, // 입력 중 체크
      inputEnd: false, // 입력 완료 체크
      nameLoad: false,
      // 추가 부분
      chanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
      isApp:
        /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) ||
        '2010' === this.$store.state.apiStore.bodyParam.chanlCd,
      isSns: true, //SNS 사용여부(하드코딩으로 사용유무 설정부분 추가)
      addInvitCnt: '1', //초대 횟수 추가(무조건 1)
      invitCnt: 0,
      slangWordList: this.$store.state.member.slangWordList,
      isError: false, //입력문자 유효성 검증
      validMessage: '',
      famyGrpId: '', //생성된 패밀리그룹ID
      // 패밀리그룹 초대/수락 이슈 : 24.06.11
      encFamyGrpId: '', //암호화 패밀리그룹ID
      maxlength: 12, //최대 입력 글자 수
      // 추가 부분

      // <!-- 알럿(예외처리) 함수 -->
      isAlert: false, //알럿(예외처리) 노출 여부
      // UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14
      isFamyInvite: false,
      // 알럿(예외처리) 정보
      alert: {
        type: 'error', // 유형
        code: '', // (노출)코드
        message: '' // (노출)메시지
      }, //알럿(예외처리) 코드/메세지 정보
      // <!-- //알럿(예외처리) 함수 -->
      isTest: false
    }
  },
  created() {
    console.log('')
    console.log('######## 패밀리 그룹 만들기(생성) UI ########')
    console.log('')
  },
  methods: {
    // 레이어 통합 관리
    onLayer(type, boolean) {
      console.log('>>> type : ' + type + ', boolean : ' + boolean)
      switch (type) {
        case 'alert': // 알럿(예외처리) 레이어 (비)노출 처리
          this.isAlert = boolean

          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
            console.log('알럿(예외처리) 닫은 후 채널 이동 처리')
            this.windowClose()
          }
          break
        default:
          break
      }
      return
    },
    // <!-- 알럿(예외처리) 함수 -->
    onAlert(code, message) {
      console.log('>>> onAlert(알럿/예외처리) 기능 code : ' + code + ', message : ' + message)
      // 4100 resMsg : 기가입 패밀리 그룹이 존재하여 패밀리 그룹 개설은(는) 불가능합니다.
      this.alert.code = code
      this.alert.message = message
      this.onLayer('alert', true)
    },
    // <!-- //알럿(예외처리) 함수 -->
    // 레이어 띄울때 body fixed
    bodyFixed() {
      document.querySelector('html').classList.add('scroll-rock')
      document.body.classList.add('scroll-rock')
      // this.nowOffset = window.scrollY
      // document.body.style.top = '-' + this.nowOffset + 'px'
    },
    // 레이어 띄울때 body fixed 제거
    bodyFixedNone() {
      document.body.classList.remove('scroll-rock')
      document.querySelector('html').classList.remove('scroll-rock')
      document.body.removeAttribute('class')
      document.body.removeAttribute('style')
      // window.scrollTo(0, this.nowOffset)
    },
    // app/web 체크하여 창닫기 함수.
    windowClose() {
      //GSSHOP 앱(웹뷰) 여부 체크
      console.log('#### back ####')
      // alert('패밀리생성 이동 임시 알럿:' + navigator.userAgent + ', isApp[' + this.isApp + ']')
      if (this.isApp) {
        if ('2010' === this.chanlCd || '3010' === this.chanlCd) {
          // alert('패밀리생성 임시 창닫기 : 앱 닫기 toapp://close 호출')
          location.href = 'toapp://close'
        } else {
          // alert('패밀리생성 임시 창닫기 : 앱 뒤로가기 toapp://back 호출')
          location.href = 'toapp://back'
        }
      } else {
        // alert('패밀리생성 임시 창닫기 : 웹 뒤로가기 go(-1) 호출')
        this.$router.go(-1)
      }
      console.log('#### 패밀리생성 windowClose app/web 창닫기 끝')
    },
    setFocus() {
      this.$refs.familyName.focus()
    },
    typingCheck(event) {
      const isHangle = /^[ㄱ-ㅎ|가-힣]{1,12}$/.test(event.target.value)
      console.log(
        '@@@@@@@@@@@@@@@@ ' + event.target.value + ': isHangle:' + isHangle + ', familyName : ' + this.familyNameValue
      )

      if (isHangle) {
        this.maxlength = 6
      } else {
        this.maxlength = 12
      }

      let byte = 0
      for (var i = 0; i < event.target.value.length; ++i) {
        if (event.target.value.charCodeAt(i) >= 12593 && event.target.value.charCodeAt(i) <= 12622) {
          byte += 1
        } else if (event.target.value.charCodeAt(i) >= 44032 && event.target.value.charCodeAt(i) <= 55203) {
          byte += 2
        } else {
          byte++
        }
        // event.target.value.charCodeAt(i) > 127 ? (byte += 2) : byte++
        // console.log('ㄱ'.charCodeAt() + ' ~ ' + 'ㅎ'.charCodeAt()) // 12593 ~ 12622
        // console.log('가'.charCodeAt() + ' ~ ' + '힣'.charCodeAt()) // 44032 ~ 55203
      }
      console.log('1 typingCheck : ' + event.target.value + ' byte : ' + byte)
      if (byte > 12) {
        console.log(' 12 byte over : ' + event.target.value + ', familyName : ' + this.familyNameValue)
        event.target.value = this.familyNameValue
      } else {
        this.familyNameValue = event.target.value
      }
      // 입력 값 유효성 체크
      this.inValid()
      this.inWordValid()
      console.log('@@@@@@@@@@@@@@@@ ' + event.target.value + ':' + this.familyNameValue)
    },
    typing() {
      this.inputEnd = false
      let familynameLength = this.familyNameValue.length
      if (familynameLength > 0 && familynameLength < this.maxlength) {
        this.inputCheck = true
      } else if (familynameLength == this.maxlength) {
        this.inputEnd = true
        this.inputCheck = false
      } else if (this.familyNameValue == '') {
        this.inputCheck = false
      }
    },
    typingRe() {
      if (this.familyNameValue != '') {
        this.inputEnd = false
        this.inputCheck = true
      }
    },
    typingEnd() {
      this.inValid()
      this.inWordValid()

      if (this.familyNameValue != '') {
        this.inputEnd = true
        this.inputCheck = false
      } else {
        this.inputEnd = false
      }
    },
    reset() {
      this.setFocus()
      this.familyNameValue = ''
      this.inputCheck = false
      this.inputEnd = false
    },
    clear(e) {
      e.preventDefault()
    },
    onLoad() {
      console.log(
        '######### 클릭 다음단계 this.isError[' +
          this.isError +
          '] this.inputEnd[' +
          this.inputEnd +
          ']  && this.nameLoad[' +
          this.nameLoad +
          ']'
      )
      // console.log('[' + this.familyNameValue + ']')
      if (this.isError === false && this.inputEnd == true && this.nameLoad === false) {
        this.nameLoad = true
        // 패밀리 생성 호출
        this.createFamilyGroup()
      }
    },
    moveFamilyGrade() {
      console.log('## 통합고객 패밀리 메인 강제 이동 처리')
      this.$router.push('/montblanc/intg/memshpGrade')
    },
    // 추가 부분
    inValid() {
      const exp = /^[가-힣A-Za-z0-9]{1,12}$/
      // console.log('[' + this.familyNameValue + ']')
      if (null != this.familyNameValue && '' != this.familyNameValue && exp.test(this.familyNameValue) === false) {
        // console.log('@@ 특수문자 입력 오류 메시지 노출 필요함. : isError[' + this.isError + '] ' + this.familyNameValue)
        this.isError = true
        this.validMessage = '한글,영문,숫자만 입력이 가능합니다. (특수문자,빈칸 불가)'
        // {{ validMessage }}"는 입력할 수 없는 단어입니다.
      } else {
        this.isError = false
        this.validMessage = ''
      }

      //입력 문구 최대 byte, 길이 체크
      let byte = 0
      for (var i = 0; i < this.familyNameValue.length; ++i) {
        this.familyNameValue.charCodeAt(i) > 127 ? (byte += 2) : byte++
      }

      if (!this.isError && byte > 12) {
        this.isError = true
        this.validMessage = '한글,영문,숫자(최대 12byte) 입력이 가능합니다. (특수문자,빈칸 불가)'
      }

      console.log('>>>>> is Error : ' + this.isError)
    },
    inWordValid() {
      var isWord = false
      var upperWord = this.familyNameValue.toUpperCase()
      this.slangWordList.forEach((e, i) => {
        if (!isWord) {
          // console.log(i + ' : 단어 : ' + e)
          isWord = upperWord.includes(e)
          if (isWord) {
            this.isError = true
            this.validMessage = '"' + e + '"는 입력할 수 없는 단어입니다.'
          }
        }
      })
      // console.log('@@@@ 비속어 체크 : ' + isWord + ', 단어 : ' + upperWord)
    },
    snsInvite() {
      console.log('## 통합고객 패밀리 서비스 생성 후 카카오톡 공유 호출')
      // 패밀리그룹 초대/수락 이슈 : 24.06.11
      if (!this.$isEmpty(this.famyGrpId) || !this.$isEmpty(this.encFamyGrpId)) {
        this.$refs.snsInvite.sendKakaoFeed()
        //생성 후 초대횟수 증가
        this.famyGrpInvitCnt('U')
        //강제 이동 처리
        console.log('## 통합고객 패밀리 서비스 생성 후 카카오톡 공유/횟수 증가 완료')
        this.moveFamilyGrade()
      } else {
        console.log('@@@ 패밀리그룹ID 설정되어 있지 않음...')
      }
    },
    createFamilyGroup() {
      console.log('######### 클릭 다음단계 재 검증 this.isError[' + this.isError + ']')
      this.inValid()
      this.inWordValid()
      console.log('######### 클릭 다음단계 재 검증 결과 this.isError[' + this.isError + ']')

      if (this.isError === false && this.inputEnd == true && this.nameLoad) {
        console.log('## 통합고객 패밀리 서비스 패밀리그룹오픈 요청 호출')
        this.addChFamyGrpOpenInfo()
      } else {
        this.nameLoad = false
      }
      console.log('## 패밀리 명 만들기 확인 버튼 종료')
    },
    //통합고객 패밀리 서비스 패밀리그룹오픈 요청  API
    async addChFamyGrpOpenInfo() {
      console.log('@@@@@ 통합고객 패밀리 서비스 패밀리그룹오픈 API 호출 시작')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'addChFamyGrpOpenInfo',
        famyGrpId: '', //		패밀리그룹ID  ID가 존재하는 경우는 수정, 패밀리그룹명만 가능하도록 처리
        famyGrpNm: this.familyNameValue, //		패밀리그룹명
        openChanlCd: this.$store.state.apiStore.bodyParam.chanlCd, //		오픈채널코드
        famyGrpTrmsAgreeYn: 'Y', //		패밀리그룹약관동의여부
        famyGrpTrmsAgreeDtm: this.$dayjs(new Date()).format('YYYYMMDDHHmmss') //		패밀리그룹약관동의일시
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/addChFamyGrpOpenInfo', bodyParam, {headers: headerParam})
        .then((res) => {
          var resData = res.data // {"trnscNo":"null","siteId":"null","resCd":"0000","resMsg":"정상","famyGrpId":"F000000020"}
          console.log('@@ resData : ' + resData + ', resData.resCd : ' + resData.resCd)
          if ('0000' === resData.resCd) {
            if (null != resData.famyGrpId && '' != resData.famyGrpId) {
              console.log('## 생성 famyGrpId : ' + resData.famyGrpId)
              this.famyGrpId = resData.famyGrpId
              //생성 후 초대횟수 체크 Callback
              //  패밀리그룹 초대/수락 이슈 : 24.06.11
              //       암호화값으로 변경되어 멤버십(패밀리)등급/실적에서 초대 가능함.
              // this.famyGrpInvitCnt('S')
              // 앰플리튜드 추가
              // this.tagging('CLICK-INTG-FMLY-CRE-INVITE')

              //  패밀리그룹 초대/수락 이슈 : 24.06.11
              this.retrieveChFamyGrpInfoDtl(this.famyGrpId)
            } else {
              console.log('## API 호출 resCd : ' + resData.resCd + ' resMsg : ' + resData.resMsg)
              // 통신 성공이지만 오류결과 코드에대한 alert 고지 및 페이지 이동/닫기 처리 필요함.
              this.onAlert(resData.resCd, '패밀리그룹 만들기 중 문제가 발생하였습니다.')
              // 통신 성공이지만 오류결과 코드에대한 alert 고지 및 페이지 이동/닫기 처리 필요함.
            }
          } else {
            this.nameLoad = false
            console.log('## API 호출 resCd : ' + resData.resCd + ' resMsg : ' + resData.resMsg)
            // 통신 성공이지만 오류결과 코드에대한 alert 고지 및 페이지 이동/닫기 처리 필요함.
            this.onAlert(resData.resCd, resData.resMsg)
            // 통신 성공이지만 오류결과 코드에대한 alert 고지 및 페이지 이동/닫기 처리 필요함.
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          this.apiResult = 'api 통신 실패 채널로 return'
          // 채널 별 통합멤버십 팝업 호출하게 하며 오류 발새 시 자동 close 처리
          this.nameLoad = false

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert('9999', e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      console.log('@@@@@ 통합고객 패밀리 서비스 패밀리그룹오픈 API 호출 종료')
    },
    // 카카오톡 초대횟수 조회/수정 콜백함수.
    famyGrpInvitCnt(reqGbnCd) {
      if ('U' === reqGbnCd || 'S' === reqGbnCd) {
        this.handleChFamyGrpInvitCnt(reqGbnCd)
      } else {
        console.log('@@@@ 요청구분코드 오류 : ' + reqGbnCd)
      }
    },
    //패밀리 그룹 초대수 조회 및 갱신 API
    async handleChFamyGrpInvitCnt(reqGbnCd) {
      console.log(
        '@@@@@ 패밀리 초대수 ' + ('U' === reqGbnCd ? '수정' : 'S' === reqGbnCd ? '조회' : '') + ' API 호출 시작'
      )
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'handleChFamyGrpInvitCnt',
        procGbnCd: reqGbnCd
      }

      if ('U' === reqGbnCd) {
        bodyParam.invitCnt = this.addInvitCnt
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
        bodyParam.famyGrpId = ''
      }

      await this.$axios
        .post('/handleChFamyGrpInvitCnt', bodyParam, {headers: headerParam})
        .then((res) => {
          var resData = res.data
          console.log(
            '@@ resData : ' + resData + ', resData.resCd : ' + resData.resCd + ', resData.resMsg : ' + resData.resMsg
          )
          if (null != res && null != resData && '0000' === resData.resCd) {
            if ('S' === reqGbnCd) {
              // console.log('@@ resData.invitCnt : ' + resData.invitCnt + ' 카카오톡 공유 이동 필요')
              if (null != resData.invitCnt && '' != resData.invitCnt) {
                this.invitCnt = Number(resData.invitCnt) //초대수(누적)
              }
              console.log(
                '@@ resData.invitCnt : ' +
                  resData.invitCnt +
                  ' 카카오톡 공유 이동 필요, this.invitCnt : ' +
                  this.invitCnt
              )

              if (this.invitCnt < 99) {
                this.snsInvite()
              } else {
                // 통신 성공이지만 오류결과 코드에대한 alert 고지 및 페이지 이동/닫기 처리 필요함.
                this.onAlert('9999', '패밀리 초대는 최대 99회만 가능합니다.(패밀리ID 기준)')
                // 통신 성공이지만 오류결과 코드에대한 alert 고지 및 페이지 이동/닫기 처리 필요함.
              }
            } else {
              console.log('@@ resData.invitCnt : ' + resData.invitCnt + ' 공유 횟수 증가 결과')
            }
          } else {
            console.log('## API 호출 resCd : ' + resData.resCd + ' resMsg : ' + resData.resMsg)
            // 횟수 조회/수정 시 통신 오류건은 무시한다. 다음 프로세스에 영향가지 않도록.
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          // 횟수 조회/수정 시 통신 오류건은 무시한다. 다음 프로세스에 영향가지 않도록.

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            // this.onAlert('9999', e.response.data.resMsg)
          } else {
            // this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      console.log(
        '@@@@@ 패밀리 초대수 ' + ('U' === reqGbnCd ? '수정' : 'S' === reqGbnCd ? '조회' : '') + ' API 호출 종료'
      )
    },
    //  패밀리그룹 초대/수락 이슈 : 24.06.11
    // F003 : retrieveChFamyGrpInfoDtl : 패밀리 그룹 정보 및 구성원 조회 API
    async retrieveChFamyGrpInfoDtl(famyGrpId) {
      console.log('')
      console.log('#### methods : 패밀리그룹 상세[F003 : retrieveChFamyGrpInfoDtl] str ####')

      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChFamyGrpInfoDtl',
        famyGrpId: famyGrpId // 탈퇴 하고 내 서비스 보기 클릭하여 왔으면 현 패밀리 번호로 조회
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/retrieveChFamyGrpInfoDtl', bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          if (null != res && null != resData) {
            console.log('## 패밀리그룹 상세 조회 결과')

            // 패밀리그룹 초대/수락 이슈 : 24.06.11
            this.encFamyGrpId = !this.$isEmpty(resData.famyInvitEncKey) ? resData.famyInvitEncKey : ''
            console.log('@@ this.encFamyGrpId : ' + this.encFamyGrpId)

            // UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14
            this.isFamyInvite = true
            this.bodyFixed()
            //생성 후 초대횟수 체크 Callback
            //  패밀리그룹 초대/수락 이슈 : 24.06.11
            //       암호화값으로 변경되어 멤버십(패밀리)등급/실적에서 초대 가능함.
            // this.famyGrpInvitCnt('S')
            // 앰플리튜드 추가
            // this.tagging('CLICK-INTG-FMLY-CRE-INVITE')
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert(e.response.data.resCd, e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })

      // console.log('#### methods : 패밀리그룹 상세[F003 : retrieveChFamyGrpInfoDtl]end ####')
    },
    // UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14
    famyInvite(boolean) {
      // true  : 패밀리 초대인 경우 초대 횟수 조회/증가 및 SNS초대 실행 후 멤버십(패미리) 등급/실적 화면 이동
      // false : 패밀리 초대 실행하지 않고 멤버십(패미리) 등급/실적 화면 이동
      this.isFamyInvite = false
      this.bodyFixedNone()
      if (boolean) {
        console.log('>>> 패밀리 멤버(카카오톡) 초대 실행')
        this.famyGrpInvitCnt('S')
        // 앰플리튜드 추가
        this.tagging('CLICK-INTG-FMLY-CRE-INVITE')
      } else {
        console.log('>>> 패밀리 멤버 초대 없이 화면이동')
        this.moveFamilyGrade()
      }
    },
    // 앰플리튜드 추가
    tagging(tagId) {
      if (!this.$isEmpty(tagId)) {
        console.log('##### tagging : ' + tagId)
        this.$refs.tagManager.tagging(tagId)
        console.log('>>>>> tagging call end')
      } else {
        console.log('>>>>> tagId is null/empty')
      }
    }
    // 추가 부분
  },
  mounted() {
    document.title = this.title
    this.sendTitle(this.title)
    this.setFocus()

    // 앰플리튜드 추가
    this.tagging('VIEW-INTG-FMLY-CRE')
  }
}
</script>
