<template><div></div></template>

<script>
export default {
  name: 'SnsInvite',
  props: {
    // chanlCd: String
    famyGrpId: String,
    // 패밀리그룹 초대/수락 이슈 : 24.06.11
    encFamyGrpId: String
  },
  data() {
    return {
      chanlCd: '',
      kakaoApi: '',
      kakaoInviteImg: this.$store.state.member.memberGradeInfo.kakaoInviteImg.icourl
    }
  },
  created() {
    console.log('')
    console.log('######## 패밀리 카카오 초대 기능 ########')
    console.log('')

    this.chanlCd = this.$store.state.apiStore.bodyParam.chanlCd
    this.kakaoApi = this.$store.state.apiStore.bodyParam.kakaoApi
    console.log('@@@@@ chanlCd : ' + this.chanlCd + ', kakaoApi : ' + this.kakaoApi + ', famyGrpId : ' + this.famyGrpId)
    if (!Kakao.isInitialized()) {
      Kakao.init(this.kakaoApi)
    }
    console.log('@@@@@  kakaoApi :' + Kakao.isInitialized())
    console.log(
      '@@@@@ protocol : ' +
        window.location.protocol +
        ', host : ' +
        window.location.host +
        ', origin : ' +
        window.location.origin
    )
  },
  methods: {
    sendKakaoFeed() {
      console.log('@@@@@@ sendKakaoFeed 시작 : ' + Kakao.isInitialized())
      console.log('this.famyGrpId : ' + this.famyGrpId + ', this.encFamyGrpId : ' + this.encFamyGrpId)
      if (!Kakao.isInitialized()) {
        Kakao.init(this.$store.state.apiStore.bodyParam.kakaoApi)
      }

      const origin = window.location.origin
      var imageUrl = origin + '/kakao_invite_banner_01.jpg'
      // [GRIT-141984] UT기반 패밀리서비스 UIUX 개선 요청 : 24.07.31
      var title = '패밀리 초대장이 도착했어요!\n패밀리가 되어 더 많은 혜택 받으세요!'
      var description = '초대 수락 시, 초대자의 휴대번호 뒤 4자리 입력이 필요해요.'
      var buttonTitle = '초대 수락하고 혜택 받기'

      // 패밀리그룹 초대/수락 이슈 : 24.06.11
      var mobileWebUrl = ''
      var webUrl = ''
      if (this.$isEmpty(this.encFamyGrpId)) {
        mobileWebUrl = origin + '/montblanc/intg/familyInvite/' + encodeURIComponent(this.famyGrpId)
        webUrl = origin + '/montblanc/intg/familyInvite/' + encodeURIComponent(this.famyGrpId)
        console.log('@@@ 평문 패밀리 그룹 초대 : ' + this.famyGrpId)
      } else {
        mobileWebUrl = origin + '/montblanc/intg/familyInvite?famyGrpId=' + encodeURIComponent(this.encFamyGrpId)
        webUrl = origin + '/montblanc/intg/familyInvite?famyGrpId=' + encodeURIComponent(this.encFamyGrpId)
        console.log('@@@ 암호화 패밀리 그룹 초대 : ' + this.encFamyGrpId)
      }

      console.log('@@@@@@@@@@@ 멤버십 카카오톡 공유 정보 @@@@@@@@@@@@')
      console.log('@@@ origin       : ' + origin)
      console.log('@@@ imageUrl     : ' + imageUrl)
      console.log('@@@ title        : ' + title)
      console.log('@@@ description  : ' + description)
      console.log('@@@ buttonTitle  : ' + buttonTitle)
      console.log('@@@ mobileWebUrl : ' + mobileWebUrl)
      console.log('@@@ webUrl       : ' + webUrl)
      console.log('@@@@@@@@@@@ 멤버십 카카오톡 공유 정보 @@@@@@@@@@@@')

      Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: title,
          description: description,
          imageUrl: imageUrl,
          link: {
            mobileWebUrl: mobileWebUrl,
            webUrl: webUrl
          }
        },
        installTalk: true,
        buttons: [
          {
            title: buttonTitle,
            link: {
              mobileWebUrl: mobileWebUrl,
              webUrl: webUrl
            }
          }
        ]
      })
      console.log('@@@@@@ sendKakaoFeed 종료 : ' + Kakao.isInitialized())
    }
  }
}
</script>
