import Vue from 'vue'
import Vuex from 'vuex'
import apiStore from '@/store/modules/api.js'
import member from '@/store/modules/member.js'
import message from '@/store/modules/message.js'
import integrate from '@/store/modules/integrate.js' //신규 통합멤버십/패밀리 UI 개편

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    apiStore,
    member,
    message,
    integrate
  }
})

export default store
