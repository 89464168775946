<template>
  <div class="membership-box">
    <div class="family-membership">
      <h3 class="fm-tit">토큰 발급</h3>
      <p class="fm-tit2">토큰 요청</p>
      <div class="b-btn-wrap">
        <div class="family-ipt">
          <input
            type="text"
            placeholder="통합회원번호를 입력해주세요"
            ref="intgmemno"
            v-model="tokenPaylord.intgMemNo"
          />
          <input type="text" placeholder="채널코드를 입력해주세요" ref="chnlCode" v-model="tokenPaylord.chanlCd" />
        </div>
        <div>
          <button type="button" class="check" @click="setToken"><span>setToken</span></button>
        </div>
      </div>
      <p class="fm-tit2">TokenPayload</p>
      <div class="family-my gray-box">
        <dl>
          <dt>aud :</dt>
          <dd>{{ this.$store.state.apiStore.bodyParam.aud }}</dd>
        </dl>
        <dl>
          <dt>통합회원번호 :</dt>
          <dd>{{ this.$store.state.apiStore.bodyParam.intgMemNo }}</dd>
        </dl>
        <dl>
          <dt>채널코드 :</dt>
          <dd>{{ this.$store.state.apiStore.bodyParam.chanlCd }}</dd>
        </dl>
        <dl>
          <dt>토큰 유효기간 :</dt>
          <dd>{{ tokenExp }}</dd>
        </dl>
      </div>
      <div class="b-btn-wrap">
        <div>
          <button type="button" class="check" @click="postApi"><span>Montblanc API Test</span></button>
        </div>
      </div>
      <div class="family-my gray-box">
        <dl>
          <dt>ApiResult :</dt>
          <dd>{{ apiResult }}</dd>
        </dl>
      </div>
      <button @click="setCookies">.</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'App',
  inject: ['sendTitle'],
  data() {
    return {
      title: '테스트화면',
      tokenPaylord: {
        aud: '',
        intgMemNo: '',
        chanlCd: '',
        exp: ''
      },
      tokenExp: '',
      apiResult: '',
      token: ''
    }
  },
  mounted() {
    this.sendTitle(this.title)
    if (this.$decodeToken()) {
      this.tokenPaylord.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      this.tokenPaylord.chanlCd = this.$store.state.apiStore.bodyParam.chanlCd
      this.tokenPaylord.exp = this.$store.state.apiStore.bodyParam.exp
      this.tokenExpChk()
    }
  },
  methods: {
    async setToken() {
      if (this.$isEmpty(this.tokenPaylord.intgMemNo) || this.$isEmpty(this.tokenPaylord.chanlCd)) {
        alert('값이 없습니다.')
        return false
      }
      const body = {
        intgMemNo: this.tokenPaylord.intgMemNo,
        chanlCd: this.tokenPaylord.chanlCd
      }

      const options = {
        //mode: "cors",
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(body)
      }
      console.log(options.body)

      await fetch(
        'http://10.53.43.32:9000/montblanc/getEncodeToken?intgMemNo=' +
          this.tokenPaylord.intgMemNo +
          '&&chanlCd=' +
          this.tokenPaylord.chanlCd,
        options
      )
        .then((response) => response.json())
        .then((data) => {
          this.token = data.token
          this.setCookies()
        })
    },
    setCookies() {
      var path = ''
      if (process.env.VUE_APP_API_URL == 'http://localhost:8080/montblanc') path = 'localhost'
      else path = 'gshs.co.kr'
      this.$cookies.set('sAs', this.token, '7d', '/montblanc', path, true, 'Strict')
      this.$decodeToken()
    },
    tokenExpChk() {
      const d = new Date(this.tokenPaylord.exp * 1000)
      this.tokenExp = this.$dayjs(d).format('YYYY-MM-DD HH:mm:ss')
    },

    async postApi() {
      var headerParam = this.$store.state.apiStore.headerParam
      var param = {
        apiId: 'retrieveChThemmGrdRlrslt',
        stdYm: '202211',
        memGbnCd: 'F',
        siteId: this.$store.state.apiStore.bodyParam.siteId,
        intgMemNo: this.$store.state.apiStore.bodyParam.intgMemNo,
        trnscNo: '0'
      }
      console.log(param)
      await this.$axios
        .post('/retrieveChThemmGrdRlrslt', param, {headers: headerParam})
        .then((res) => {
          console.log(res)
          this.apiResult = res.data
        })
        .catch((e) => {
          console.log(e)
          this.apiResult = 'api 통신 실패'
        })
    }
  }
}
</script>
