<template>
  <div id="modal">
    <div class="layerWrap">
      <div class="alert show">
        <div class="txt">
          <p v-html="alert.message"></p>
        </div>
        <div class="btn">
          <div v-if="'error' == type">
            <button class="check" @click="onLayer('alert')"><span>확인</span></button>
          </div>
          <div v-else>
            <button class="check" @click="onLayer('cancel')"><span>취소</span></button>
            <button class="check" @click="onLayer('confirm')"><span>확인</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalAlert',
  props: {
    type: String,
    alert: {
      type: Object,
      default() {
        return {code: '', message: ''}
      }
    }
  },
  mounted() {
    console.log('## ModalAlert Layer type : ' + this.type)
  },
  methods: {
    onLayer(type) {
      console.log('>> onLayer type : ' + type)
      this.$emit('onLayer', type, false)
    }
  }
}
</script>
