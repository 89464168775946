<template>
  <div id="modal">
    <div class="layerWrap">
      <div class="toast">
        <div class="inner">
          <div class="title-wrap"><p>이용 약관 동의</p></div>
          <div class="cont-wrap">
            <div>
              <h2>[필수] 패밀리 서비스 이용 약관</h2>
              <div class="detail">
                <ul>
                  <li>
                    <h3>제1조(목적)</h3>
                    <span>
                      본 약관은 주식회사 GS리테일(이하 회사)에서 제공하는 패밀리 서비스를 이용함에 있어 회원과 회사
                      사이의 권리, 의무 및 책임사항 등을 규정하는데 그 목적이 있습니다.
                    </span>
                  </li>
                  <li>
                    <h3>제2조(용어의 정의)</h3>
                    <span>본 약관에서 사용하는 용어의 정의는 다음과 같습니다. </span>
                    <ol>
                      <li>① '회원'이란 GS리테일 멤버십 약관에 동의하고 가입한 회원을 말합니다.</li>
                      <li>
                        ② '패밀리 서비스'란 이용자가 이 약관에 동의하여 패밀리 그룹을 만들거나 참여할 수 있는 서비스를
                        말합니다.
                      </li>
                      <li>③ '패밀리 서비스 회원'이란 GS리테일 회원 중 본 약관에 동의한 회원을 말합니다.</li>
                      <li>
                        ④ ‘GS리테일 통합 회원’이란 2021년 8월 2일부터 개정된 GS리테일 멤버십 회원 약관에 동의한 회원을
                        말합니다.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>제3조(약관의 효력 및 변경)</h3>
                    <ol>
                      <li>
                        ① 본 약관은 회사의 PC 또는 모바일 애플리케이션 내 게시하는 방법으로 회원에게 제시, 설명되고
                        회원이 본 약관에 동의함으로써 그 효력이 발생됩니다.
                      </li>
                      <li>
                        ② 회사는 법률에 위배되지 않는 범위 내에서 약관을 개정할 수 있으며 개정된 약관의 적용일자 및 개정
                        사유를 적용일 7일 전(회원에게 불리한 내용으로 변경하는 경우 30일 전)까지 서비스 화면을 통하여
                        공지합니다.
                      </li>
                      <li>③ 본 조의 규정에 의하여 개정된 약관 및 변경된 서비스는 효력 발생일로부터 적용됩니다.</li>
                      <li>
                        ④ 본 약관의 개정과 관련하여 이의가 있는 회원은 회원 탈퇴를 할 수 있습니다. 단, 이의가 있음에도
                        불구하고 본 조 제2항에서 정해진 바에 따라 GS리테일의 고지가 있은 후 30일 이내에 회원 탈퇴를 하지
                        않은 회원은 개정 약관에 동의한 것으로 봅니다.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h3>제4조(서비스 이용)</h3>
                    <ol>
                      <li>
                        ① GS리테일 통합회원에 한하여 회사의 서비스 화면을 통하여 이용할 수 있으며, 미통합 회원은
                        통합회원으로 전환 후에 이용 가능합니다. 다만, 일부 고객의 경우 패밀리 서비스 이용이 제한될 수
                        있습니다.
                      </li>
                      <li>
                        ② 패밀리 그룹은 패밀리 서비스 약관에 동의한 회원 누구나 생성 가능하며, 자유롭게 패밀리 그룹
                        초대/수락/나가기 가 가능합니다.
                      </li>
                      <li>③ GS리테일 멤버십 회원 탈퇴 시에는 패밀리 서비스 이용이 불가합니다.</li>
                      <li>
                        ④ 각 패밀리 그룹별 최대 이용 회원 수가 제한되어 있으며, 초과 시 다른 회원은 해당 그룹에 들어올
                        수 없습니다.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h3>제5조(서비스 혜택)</h3>
                    <ol>
                      <li>
                        ① 패밀리 서비스 회원에게는 매월 GS리테일 멤버십 등급 산정 시, 패밀리 구성원의 합산 구매실적을
                        기준으로 등급 산정하며, 모든 구성원이 동일한 등급을 부여받습니다. 단, 등급 산정 시점에 등록되어
                        있는 구성원을 기준으로 등급을 산정합니다.
                      </li>
                      <li>
                        ② 멤버십 등급 산정 이후, 다음월 등급 산정 이전까지 신규로 등록한 회원은 다음월 등급 산정 시점에
                        합산 구매실적을 기준으로 등급을 부여받습니다.
                      </li>
                      <li>
                        ③ 패밀리 최초 생성 후 그룹 구성원이 추가된 경우 또는 최초로 패밀리에 들어간 경우, 최초 1회에 한
                        해 포인트가 지급되며, 이후에는 패밀리 생성/수락 시에도 포인트는 지급되지 않습니다.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h3>제6조(회원의 의무)</h3>
                    <ol>
                      <li>
                        ① 패밀리 서비스 회원은 이 약관에 따른 권리·의무의 전부 또는 일부를 타인에게 대여, 양도, 위임할
                        수 없습니다.
                      </li>
                      <li>
                        ② 패밀리 서비스 회원은 패밀리 서비스 혜택을 악용(영리 목적 및 판매 목적 등) 하는 행위를 하여서는
                        안됩니다.
                      </li>
                      <li>
                        ③ 회원이 본 조 또는 본 약관을 위반하는 경우, 회사는 회원의 서비스 이용을 제한할 수 있습니다.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h3>제7조(서비스 종료)</h3>
                    <span>
                      패밀리 서비스를 종료하고자 할 경우, GS리테일은 서비스를 종료하고자 하는 날로부터 90 일 이전에 본
                      약관 제3조 2항에 규정된 통지 방법을 준용하여 회원에게 알려드립니다.
                    </span>
                  </li>

                  <li>
                    <h3>제8조(저작권의 귀속 및 이용 제한/광고의 게재)</h3>
                    <ol>
                      <li>① GS리테일은 서비스에 적절하다고 판단되거나 활용 가능성 있는 광고를 게재할 수 있습니다.</li>
                      <li>
                        ② 회원/비회원 대상으로 진행한 프로모션(응모 이벤트를 포함한 행사 프로모션)은 사전 동의를 구하고
                        진행하며, 동의를 한 내용에 의거해 권한은 GS리테일에 귀속되어 활용될 수 있습니다.
                      </li>
                      <li>③ GS리테일이 작성한 저작물에 대한 저작권 기타 지적재산권은 GS리테일에 귀속합니다.</li>
                      <li>
                        ④ 회원 및 사이트 이용자가 GS리테일을 이용함으로써 얻은 정보를 GS리테일의 사전 승낙 없이 복제,
                        송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자로 하여금 이용하게 해서는
                        안 됩니다.
                      </li>
                      <li>
                        ⑤ 회원 및 사이트 이용자가 게시한 게시물의 내용에 대한 권리는 게시자에게 있으며 게시자의 게시물이
                        타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 이용자가 부담하여야 합니다.
                      </li>
                      <li>
                        ⑥ GS리테일은 게시된 내용을 게시물의 동일성을 해하지 않는 범위 내에서 편집, 이동시킬 수 있는
                        권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수 있습니다.
                      </li>
                      <li>
                        1. 본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시 한 경우
                      </li>
                      <li>2. 다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우</li>
                      <li>3. 공공질서 및 미풍양속에 위반되는 내용인 경우</li>
                      <li>4. 범죄적 행위에 결부된다고 인정되는 내용일 경우</li>
                      <li>5. 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
                      <li>6. 탈퇴자가 게시한 게시물</li>
                      <li>7. 기타 관계 법령에 위배되는 경우</li>
                    </ol>
                  </li>

                  <li>
                    <h3>제9조(분쟁 해결)</h3>
                    <ol>
                      <li>
                        ① GS리테일은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여
                        피해 보상 처리기구를 설치 운영합니다.
                      </li>
                      <li>
                        ② GS리테일은 이용자가 제기하는 불만사항 및 의견을 신속 적정하게 처리합니다. 다만 신속한 처리가
                        곤란한 경우에는 이용자에게 그 사유와 처리 일정을 지체 없이 통보해 드립니다.
                      </li>
                      <li>
                        ③ GS리테일과 이용자 간에 분쟁 발생 시 전자거래기본법 제28조 및 동 시행령 제15조에 근거하여
                        설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h3>제10조(기타)</h3>
                    <span>
                      본 약관에 규정되지 않은 사항에 관하여는 회원이 이용하는 GS리테일 멤버십 회원 약관에 따릅니다. 단,
                      성격상 패밀리 서비스 회원에 대하여 적용될 수 없는 내용은 제외됩니다.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="b-btn-wrap">
            <div>
              <button class="check" @click="getJoin"><span>동의하기</span></button>
            </div>
          </div>
        </div>
        <div class="close">
          <button aria-label="닫기" @click="onEmitCencel"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalToastTerms',
  props: {
    join: Boolean,
    isEncFamyGrpId: Boolean
  },
  mounted() {
    console.log('')
    console.log('####### 공통 약관 레이어 ######')
    console.log('')
    console.log('>> join : ' + this.join + ', isEncFamyGrpId : ' + this.isEncFamyGrpId)
    console.log('')
  },
  methods: {
    onEmitCencel() {
      this.$emit('onLayer', 'terms', false)
    },
    getJoin() {
      // this.join : true  > 패밀리 그룹 초대 수락
      // this.join : false > 패밀리 그룹 소계
      if (this.join == true) {
        // 앰플리튜드 추가
        // 패밀리그룹 초대/수락 이슈 : 24.06.11
        if (this.isEncFamyGrpId) {
          this.$emit('onLayer', 'cert', true)
        } else {
          this.$emit('onFmlyJoin', true)
        }
        this.$emit('tagging', 'CLICK-INTG-FMLY-INV-AGREE')
      } else {
        // 앰플리튜드 추가
        this.$emit('tagging', 'CLICK-INTG-FMLY-INF-AGREE')
        this.$router.push('/montblanc/intg/familyName').catch(() => {})
      }
      this.$emit('onLayer', 'terms', false)
    }
  }
}
</script>
