<template>
  <div class="membership-box integrate">
    <!-- 이번달 최고 등급 브랜드 -->
    <div class="member-sect best-brand">
      <!-- 타이틀 : 상단 간격을 독립적으로 띄울 경우 클래스 divide -->
      <header class="sect-head divide">
        <div class="head-colum">
          <!-- <h2 class="ttl">이번 달 최고 등급 브랜드</h2> -->
          <sub>가장 높은 등급으로 전체 브랜드 등급이 적용됩니다. </sub>
        </div>
      </header>
      <!-- //타이틀 -->

      <!-- DEFAULT : SKELETON 기능 -->
      <div class="my-member-state" :style="{display: topStyle.default}">
        <div v-if="isFamily" class="family-head">
          <header class="sect-head divide">
            <div class="head">
              <span class="badge">패밀리</span>
              <h2 class="ttl">{{ isNewFamilyGrp ? familyInfo.famyGrpNm : familyInfo.exposFamyGrpNm }}</h2>
            </div>
            <aside>
              <button type="button" class="gui-btn icon"><i class="ico-more"></i>메뉴</button>
            </aside>
          </header>
        </div>
        <div class="state-info">
          <div class="skeleton">
            <div class="skeleton-item">
              <div class="flex-wrap">
                <div class="skeleton-text w-40"></div>
                <div class="skeleton-text w-80"></div>
                <div class="skeleton-text w-60"></div>
              </div>
              <div class="skeleton-text sm w-40"></div>
            </div>
            <div class="member-progress">
              <div class="progress-bar">
                <div class="skeleton-text bar"></div>
              </div>
              <ul class="datalist" :class="isGshExpos ? 'extension' : ''">
                <li>
                  <span class="grade">VVIP</span>
                  <span class="price skeleton-text"></span>
                </li>
                <li>
                  <span class="grade">VIP</span>
                  <span class="price skeleton-text"></span>
                </li>
                <!-- // 24.08.01 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급] -->
                <li v-if="isGshExpos">
                  <span class="grade">DIAMOND</span>
                  <span class="price skeleton-text"></span>
                </li>
                <li>
                  <span class="grade">GOLD</span>
                  <span class="price skeleton-text"></span>
                </li>
                <li>
                  <span class="grade">WELCOME</span>
                  <span class="price skeleton-text"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- DEFAULT : SKELETON 기능 -->

      <!-- CASE 1 : 등급,실적 노출이 아닌 경우 -->
      <div class="my-member-state" :style="{display: topStyle.nonGrade}">
        <!-- 조건 1 : 패밀리인 가입 경우 > 패밀리 그룹(명) 정보 -->
        <div v-if="isFamily" class="family-head">
          <header class="sect-head divide">
            <div class="head">
              <span class="badge">패밀리</span>
              <h2 class="ttl">{{ isNewFamilyGrp ? familyInfo.famyGrpNm : familyInfo.exposFamyGrpNm }}</h2>
            </div>
            <aside>
              <button @click="onLayer('menu', true)" class="gui-btn icon"><i class="ico-more"></i>메뉴</button>
            </aside>
          </header>
        </div>
        <!-- 조건 1 : 패밀리인 가입 경우 > 패밀리 그룹(명) 정보 -->

        <!-- 케이스 1-1 : 패밀리/멤버 > 다음달 VVIP 등급 축하 -->
        <!-- // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급] -->
        <div v-if="custGradeInfo.nxtGrdIdx > vipIndex" class="celebrate">
          <img src="@/assets/images/member/freebie.png" alt="선물아이콘" />
          <h2 class="tit">
            축하합니다! <br />다음 달 <span class="point">{{ custGradeInfo.nxtGrdNm }}</span> 등급 입니다!
          </h2>
          <p>브랜드별 다양한 혜택을 받으세요.</p>
        </div>

        <div v-if="custGradeInfo.nxtGrdIdx > vipIndex && isNxtMm" class="detail-infobox">
          <div class="text">
            <span v-if="isFamily" class="desc"
              >그룹 멤버 전체 구매 내역에 따라 {{ custGradeInfo.grdApplyMm }}월부터 통합 등급이 적용됩니다.</span
            >
            <span v-else-if="!isFamily && 'exit' == custGradeInfo.fmlyJoinType" class="desc"
              >탈퇴한 패밀리 그룹의 통합 등급은 {{ custGradeInfo.grdApplyMm }}월까지 유지됩니다.</span
            >
            <button type="button" @click="onLayer('next', true)" class="gui-btn icon">
              <i class="ico-info_circle"></i>등급적용일기준
            </button>
          </div>
        </div>
        <!-- 케이스 1-1 : 패밀리/멤버 > 다음달 VVIP 등급 축하 -->

        <!-- 혜택 content -->
        <div v-if="custGradeInfo.nxtGrdIdx < vvipIndex" class="state-info">
          <!-- 케이스 1-2 : 패밀리 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
          <h2
            v-if="
              isFamily &&
              !custGradeInfo.isCurEntprGrdExpos &&
              !custGradeInfo.isNxtPurchase &&
              'exit' != custGradeInfo.fmlyJoinType
            "
            class="tit md"
          >
            그룹 멤버 전체 구매 내역에 따라 {{ custGradeInfo.grdApplyMm }}월부터 통합 등급이 적용됩니다.
            <button @click="onLayer('next', true)" class="gui-btn icon">
              <i class="ico-info_circle"></i>등급적용일기준
            </button>
          </h2>
          <!-- 케이스 1-2 : 패밀리 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
          <!-- 케이스 1-3 : 패밀리 고객 > 이번달 노출, 다음달 등급, 실적이 0인 경우 -->
          <h2
            v-else-if="
              isFamily &&
              custGradeInfo.isCurEntprGrdExpos &&
              !custGradeInfo.isNxtPurchase &&
              'exit' != custGradeInfo.fmlyJoinType
            "
            class="tit md"
          >
            그룹 멤버 전체 구매 내역에 따라 통합 등급이 적용됩니다.
            <button @click="onLayer('next', true)" class="gui-btn icon">
              <i class="ico-info_circle"></i>등급적용일기준
            </button>
          </h2>
          <!-- 케이스 1-3 : 패밀리 고객 > 이번달 노출, 다음달 등급, 실적이 0인 경우 -->
          <!-- 케이스 1-4 : 멤버십 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
          <h2
            v-else-if="
              !isFamily &&
              !custGradeInfo.isCurEntprGrdExpos &&
              !custGradeInfo.isNxtPurchase &&
              'exit' != custGradeInfo.fmlyJoinType
            "
            class="tit md"
          >
            {{ custGradeInfo.custNm }}님의 구매 내역에 따라 {{ custGradeInfo.grdApplyMm }}월 1일에 통합 등급이
            적용됩니다.
            <button @click="onLayer('next', true)" class="gui-btn icon">
              <i class="ico-info_circle"></i>등급적용일기준
            </button>
          </h2>
          <!-- 케이스 1-4 : 멤버십 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
          <!-- 케이스 1-5 : 멤버십 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
          <h2
            v-else-if="
              !isFamily &&
              custGradeInfo.isCurEntprGrdExpos &&
              !custGradeInfo.isNxtPurchase &&
              'exit' != custGradeInfo.fmlyJoinType
            "
            class="tit md"
          >
            {{ custGradeInfo.custNm }}님의 구매 내역에 따라 통합 등급이 적용됩니다.
            <button @click="onLayer('next', true)" class="gui-btn icon">
              <i class="ico-info_circle"></i>등급적용일기준
            </button>
          </h2>
          <!-- 케이스 1-5 : 멤버십 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
          <!-- 케이스 1-6 : 패밀리 탈퇴 후 미가입 > 이번달 패밀리적용, 미가입 -->
          <div v-if="isFamily && 'new' != custGradeInfo.fmlyJoinType && !isNewFamilyGrp" class="detail-infobox">
            <div class="text">
              <span class="desc">
                탈퇴한 패밀리 그룹입니다.<br />
                해당 그룹의 통합 등급은 {{ custGradeInfo.grdApplyMm }}월까지 유지됩니다.
              </span>
              <button @click="onLayer('next', true)" class="gui-btn icon">
                <i class="ico-info_circle"></i>등급적용일기준
              </button>
            </div>
          </div>
          <!-- 패밀리 탈퇴 후 미가입 상태 -->
          <div v-if="isFamily && 'exit' == custGradeInfo.fmlyJoinType" class="b-btn-wrap">
            <div>
              <button @click="goPage('family')" class="check"><span>새로운 패밀리 그룹 만들기</span></button>
            </div>
          </div>
          <!-- 패밀리 탈퇴 후 재가입 상태 -->
          <div v-if="isFamily && !isNewFamilyGrp && 'rejoin' == custGradeInfo.fmlyJoinType" class="b-btn-wrap">
            <div>
              <button @click="goPage('newFamily')" class="check"><span>새로운 패밀리 그룹 가기</span></button>
            </div>
          </div>
          <!-- 케이스 1-6 : 패밀리 탈퇴 후 미가입 > 이번달 패밀리적용, 미가입 -->
        </div>
        <!-- //혜택 content -->
      </div>
      <!-- CASE 1 : 등급,실적 노출이 아닌 경우 -->

      <!-- 
        // CASE 2 : 등급,실적 노출인(swiper 영역) 경우
        //    2-1 : 상단 노출 채널 1개 인 경우 > 일반
        //    2-2 : 상단 노출 채널 2개 인 경우 > swiper
      -->
      <!-- CASE 2-1 : 상단 노출 채널 1개 인 경우 > 일반 -->
      <div v-if="2 > topChanlGradeInfos.length" :style="{display: topStyle.grade}">
        <div v-for="(items, idx) in topChanlGradeInfos" :key="idx" class="my-member-state">
          <!-- 조건 1 : 패밀리인 가입 경우 > 패밀리 그룹(명) 정보 -->
          <div v-if="isFamily" class="family-head">
            <header class="sect-head divide">
              <div class="head">
                <span class="badge">패밀리</span>
                <h2 class="ttl">{{ isNewFamilyGrp ? familyInfo.famyGrpNm : familyInfo.exposFamyGrpNm }}</h2>
              </div>
              <aside>
                <button @click="onLayer('menu', true)" class="gui-btn icon"><i class="ico-more"></i>메뉴</button>
              </aside>
            </header>
          </div>
          <!-- 조건 1 : 패밀리인 가입 경우 > 패밀리 그룹(명) 정보 -->

          <!-- 혜택 content -->
          <!-- 기본 1 : 패밀리/멤버 > 다음달, 등급을 올리려면 등급, 실적 존재 -->
          <!--      패밀리/멤버 등급을 올리려면 안내 문구 -->
          <div class="state-info">
            <!-- 케이스 1 : 패밀리/멤버 > 다음달, 등급을 올리려면 등급, 실적 존재 -->
            <h2 class="tit">
              <img v-if="items.chanlGbnCd != 'A'" class="logo" :src="items.chanlLogo" :alt="items.chanlNm" />
              <span v-else>자주 이용하시는 브랜드</span>에서<br />
              <!-- // 24.08.01 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급] -->
              <span v-if="items.addNeedOrdCnt != '0'" class="point">{{ items.addNeedOrdCnt }}{{ items.dayOrNum }}</span>
              {{ items.addNeedOrdCnt != '0' && items.addNeedOrdAmt != '0' ? items.andOr : '' }}
              <span v-if="items.addNeedOrdAmt != '0'" class="point">{{ items.addNeedOrdAmt }}원</span>
              더 구매하고<br />
              <!-- span class="point">{{ items.addNeedOrdCnt }}{{ items.dayOrNum }}</span> {{ items.andOr }}
              <span class="point">{{ items.addNeedOrdAmt }}원</span> 더 구매하고 <br /-->
              <!-- 등급을 올리려면 등급명 -->
              <span class="highlight red"
                >{{ custGradeInfo.grdApplyFlwMm }}월에
                <em v-if="undefined != items.updGrdNm && '' != items.updGrdNm" class="point">{{ items.updGrdNm }}</em>
                <em v-else class="point">등급별</em></span
              >
              혜택을 받으세요!
            </h2>
            <!-- 케이스 1-1 : 패밀리 고객 : 패밀리 그룹인 경우 -->
            <p v-if="isFamily" class="total">패밀리 그룹 멤버 전체 합산 기준</p>
            <!-- 케이스 1-1 : 패밀리 고객 : 패밀리 그룹인 경우 -->
            <p class="date">
              이용기간 <em>{{ items.period }}</em>
            </p>
            <!-- 케이스 1-2 : 패밀리 고객 : 패밀리 등급 적용 전인경우. 1~25일(다음달), 26~30일(다다음달) -->
            <div
              v-if="
                !custGradeInfo.isCurEntprGrdExpos ||
                (isFamily && 'N' == familyInfo.famyGrdApplyYn) ||
                (!isFamily && isNxtMm && 'exit' == custGradeInfo.fmlyJoinType)
              "
              class="detail-infobox"
            >
              <div class="text">
                <span v-if="isFamily" class="desc">
                  그룹 멤버 전체 구매 내역에 따라 {{ custGradeInfo.grdApplyMm }}월부터 통합 등급이 적용됩니다.
                </span>
                <span v-else-if="!isFamily && isNxtMm && 'exit' == custGradeInfo.fmlyJoinType" class="desc">
                  탈퇴한 패밀리 그룹의 통합 등급은 {{ custGradeInfo.grdApplyMm }}월까지 유지됩니다.
                </span>
                <span v-else class="desc">
                  {{ custGradeInfo.custNm }}님의 구매 내역에 따라 {{ custGradeInfo.grdApplyMm }}월 1일에 통합 등급이
                  적용됩니다.
                </span>
                <button @click="onLayer('next', true)" class="gui-btn icon">
                  <i class="ico-info_circle"></i>등급적용일기준
                </button>
              </div>
            </div>
            <button
              v-if="'A' != items.chanlGbnCd && isLink"
              @click="goDeepLink(items.code, false)"
              class="gui-btn icon"
            >
              <i class="ico-chevron_right"></i>이동하기
            </button>
            <!-- 케이스 1-2 : 패밀리 고객 : 패밀리 등급 적용 전인경우. 1~25일(다음달), 26~30일(다다음달) -->
          </div>
          <!--      패밀리/멤버 등급을 올리려면 안내 문구 -->
          <!--      패밀리/멤버 다음달 등급 progress bar -->
          <div class="member-progress">
            <div class="progress-bar" ref="barW">
              <p
                class="bar"
                :id="'topBar'"
                :class="{start: items.percent == 0, end: items.percent == 100}"
                :style="{width: items.barStyle}"
              ></p>
              <div class="bar-tooltip" :id="'tooltip-' + items.chanlGbnCd">
                <span>{{ items.ordAmt }}</span
                >원 • <span>{{ items.ordCnt }}</span
                >{{ items.dayOrNum }}
                <i class="arrow" :id="'arrow-' + items.chanlGbnCd"></i>
              </div>
            </div>
            <ul class="datalist" :class="isGshExpos ? 'extension' : ''" :id="'datalist-' + items.chanlGbnCd">
              <li
                v-for="(item, idx1) in items.gradeStandard.slice().reverse()"
                :key="idx1"
                :class="{on: item.gradeIdx <= items.gradeIndex}"
              >
                <span class="grade">{{ item.grade }}</span
                ><span v-if="null != item.standard && '' != item.standard" class="price" v-html="item.standard"></span>
              </li>
            </ul>
          </div>
          <!--      패밀리/멤버 다음달 등급 progress bar -->
          <!-- 기본 1 : 패밀리/멤버 > 다음달, 등급을 올리려면 등급, 실적 존재 -->
          <!-- //혜택 content -->
        </div>
      </div>
      <!-- //CASE 2-2 : 상단 노출 채널 2개 인 경우 > swiper -->
      <div v-else :style="{display: topStyle.grade}">
        <swiper
          ref="swiper"
          :slides-per-view="1"
          :space-between="30"
          :loop="false"
          :observer="true"
          :observeParents="true"
          :pagination="{clickable: true}"
          :clickable="true"
        >
          <swiper-slide v-for="(items, idx) in topChanlGradeInfos" :key="idx">
            <div class="my-member-state">
              <!-- 조건 1 : 패밀리인 가입 경우 > 패밀리 그룹(명) 정보 -->
              <div v-if="isFamily" class="family-head">
                <header class="sect-head divide">
                  <div class="head">
                    <span class="badge">패밀리</span>
                    <h2 class="ttl">{{ isNewFamilyGrp ? familyInfo.famyGrpNm : familyInfo.exposFamyGrpNm }}</h2>
                  </div>
                  <aside>
                    <button @click="onLayer('menu', true)" class="gui-btn icon"><i class="ico-more"></i>메뉴</button>
                  </aside>
                </header>
              </div>
              <!-- 조건 1 : 패밀리인 가입 경우 > 패밀리 그룹(명) 정보 -->

              <!-- 혜택 content -->
              <!-- 기본 1 : 패밀리/멤버 > 다음달, 등급을 올리려면 등급, 실적 존재 -->
              <!--      패밀리/멤버 등급을 올리려면 안내 문구 -->
              <div class="state-info">
                <!-- 케이스 1 : 패밀리/멤버 > 다음달, 등급을 올리려면 등급, 실적 존재 -->
                <h2 class="tit">
                  <img v-if="items.chanlGbnCd != 'A'" class="logo" :src="items.chanlLogo" :alt="items.chanlNm" />
                  <span v-else>자주 이용하시는 브랜드</span>에서<br />
                  <!-- // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급] -->
                  <span v-if="items.addNeedOrdCnt != '0'" class="point"
                    >{{ items.addNeedOrdCnt }}{{ items.dayOrNum }}</span
                  >
                  {{ items.addNeedOrdCnt != '0' && items.addNeedOrdAmt != '0' ? items.andOr : '' }}
                  <span v-if="items.addNeedOrdAmt != '0'" class="point">{{ items.addNeedOrdAmt }}원</span> 더 구매하고
                  <br />
                  <!-- span class="point">{{ items.addNeedOrdCnt }}{{ items.dayOrNum }}</span> {{ items.andOr }}
                  <span class="point">{{ items.addNeedOrdAmt }}원</span> 더 구매하고 <br /-->
                  <!-- 등급을 올리려면 등급명 -->
                  <span class="highlight red"
                    >{{ custGradeInfo.grdApplyFlwMm }}월에
                    <em v-if="undefined != items.updGrdNm && '' != items.updGrdNm" class="point">{{
                      items.updGrdNm
                    }}</em>
                    <em v-else class="point">등급별</em></span
                  >
                  혜택을 받으세요!
                </h2>
                <!-- 케이스 1-1 : 패밀리 고객 : 패밀리 그룹인 경우 -->
                <p v-if="isFamily" class="total">패밀리 그룹 멤버 전체 합산 기준</p>
                <!-- 케이스 1-1 : 패밀리 고객 : 패밀리 그룹인 경우 -->
                <p class="date">
                  이용기간 <em>{{ items.period }}</em>
                </p>
                <!-- 케이스 1-2 : 패밀리 고객 : 패밀리 등급 적용 전인경우. 1~25일(다음달), 26~30일(다다음달) -->
                <div
                  v-if="
                    !custGradeInfo.isCurEntprGrdExpos ||
                    (isFamily && 'N' == familyInfo.famyGrdApplyYn) ||
                    (!isFamily && isNxtMm && 'exit' == custGradeInfo.fmlyJoinType)
                  "
                  class="detail-infobox"
                >
                  <div class="text">
                    <span v-if="isFamily" class="desc">
                      그룹 멤버 전체 구매 내역에 따라 {{ custGradeInfo.grdApplyMm }}월부터 통합 등급이 적용됩니다.
                    </span>
                    <span v-else-if="!isFamily && isNxtMm && 'exit' == custGradeInfo.fmlyJoinType" class="desc">
                      탈퇴한 패밀리 그룹의 통합 등급은 {{ custGradeInfo.grdApplyMm }}월까지 유지됩니다.
                    </span>
                    <span v-else class="desc">
                      {{ custGradeInfo.custNm }}님의 구매 내역에 따라 {{ custGradeInfo.grdApplyMm }}월 1일에 통합 등급이
                      적용됩니다.
                    </span>
                    <button @click="onLayer('next', true)" class="gui-btn icon">
                      <i class="ico-info_circle"></i>등급적용일기준
                    </button>
                  </div>
                </div>
                <button
                  v-if="'A' != items.chanlGbnCd && isLink"
                  @click="goDeepLink(items.code, false)"
                  class="gui-btn icon"
                >
                  <i class="ico-chevron_right"></i>이동하기
                </button>
                <!-- 케이스 1-2 : 패밀리 고객 : 패밀리 등급 적용 전인경우. 1~25일(다음달), 26~30일(다다음달) -->
              </div>
              <!--      패밀리/멤버 등급을 올리려면 안내 문구 -->
              <!--      패밀리/멤버 다음달 등급 progress bar -->
              <div class="member-progress">
                <div class="progress-bar" ref="barW">
                  <p
                    class="bar"
                    :id="'topBar'"
                    :class="{start: items.percent == 0, end: items.percent == 100}"
                    :style="{width: items.barStyle}"
                  ></p>
                  <div class="bar-tooltip" :id="'tooltip-' + items.chanlGbnCd">
                    <span>{{ items.ordAmt }}</span
                    >원 • <span>{{ items.ordCnt }}</span
                    >{{ items.dayOrNum }}
                    <i class="arrow" :id="'arrow-' + items.chanlGbnCd"></i>
                  </div>
                </div>
                <ul class="datalist" :class="isGshExpos ? 'extension' : ''" :id="'datalist-' + items.chanlGbnCd">
                  <li
                    v-for="(item, idx1) in items.gradeStandard.slice().reverse()"
                    :key="idx1"
                    :class="{on: item.gradeIdx <= items.gradeIndex}"
                  >
                    <span class="grade">{{ item.grade }}</span
                    ><span
                      v-if="null != item.standard && '' != item.standard"
                      class="price"
                      v-html="item.standard"
                    ></span>
                  </li>
                </ul>
              </div>
              <!--      패밀리/멤버 다음달 등급 progress bar -->
              <!-- 기본 1 : 패밀리/멤버 > 다음달, 등급을 올리려면 등급, 실적 존재 -->
              <!-- //혜택 content -->
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <!-- //CASE 2-2 : 상단 노출 채널 2개 인 경우 > swiper -->
    </div>
    <!-- //이번달 최고 등급 브랜드 -->

    <!-- GS프래시몰 등급조정대상 안내 메세지 -->
    <div class="detail-infobox black" :style="{display: topStyle.grdModTgt}">
      <i class="ico-heart_circle"></i>
      <p class="text">
        GS프레시몰 12월 VVIP/VIP 등급 고객님들께 감사의<br />
        마음으로 2024년 3월까지 등급을 유지해 드립니다!<br />
        패밀리 그룹에서 전체 멤버님들과 함께 혜택을 누리세요!
      </p>
    </div>
    <!-- //GS프래시몰 등급조정대상 안내 메세지 -->

    <!-- 혜택 한눈에 보기 -->
    <!-- 패밀리/멤버 : 기본 -->
    <div class="member-sect">
      <header @click="goPage('standard')" class="sect-head divide">
        <div class="head">
          <img src="@/assets/images/member/freebie.png" alt="선물아이콘" />
          <h2 class="ttl">
            <span>{{ custGradeInfo.custNm }}</span
            >님의 혜택 한눈에 보기
          </h2>
        </div>
        <aside>
          <button class="gui-btn icon"><i class="ico-chevron_right"></i>이동하기</button>
        </aside>
      </header>
    </div>
    <!-- //패밀리/멤버 : 기본 -->
    <!-- //혜택 한눈에 보기 -->

    <!-- 등급 업하기 -->
    <!-- 멤버십 고객 : 기본 > 멤버십인 경우 -->
    <!-- [GRIT-141984] UT기반 패밀리서비스 UIUX 개선 요청 : 24.07.31 -->
    <div v-if="!isFamily" class="member-sect">
      <div class="family-invite">
        <div class="tit">
          <h1><span class="color-blue">패밀리</span>로 모여서, <span class="color-blue">VVIP 혜택</span> 함께 받기!</h1>
          <p v-if="custGradeInfo.nxtGrdIdx > 2">
            다음 달 VVIP등급 이시네요, 등급업이 필요한 친구를 초대해 혜택을 함께 누리세요!
          </p>
          <p v-else>멤버들과 함께 구매 실적을 모아 빠르게 등급 UP!</p>
        </div>
        <div class="b-btn-wrap">
          <div>
            <button type="button" @click="goPage('family')" class="check md"><span>패밀리 시작하기</span></button>
          </div>
        </div>
      </div>
    </div>
    <!-- //[GRIT-141984] UT기반 패밀리서비스 UIUX 개선 요청 : 24.07.31 -->
    <!-- //멤버십 고객 : 기본 > 멤버십인 경우 -->
    <!-- //등급 업하기 -->

    <!-- 패밀리 멤버 -->
    <!-- 패밀리 고객 : 기본 > 패밀리 멤버인 경우 -->
    <!-- <div v-if="isFamily && !custGradeInfo.isExitFmly" class="member-sect"> -->
    <div
      v-if="
        isFamily && ('new' == custGradeInfo.fmlyJoinType || (isNewFamilyGrp && 'rejoin' == custGradeInfo.fmlyJoinType))
      "
      class="member-sect"
    >
      <header class="sect-head divide">
        <div class="head-colum">
          <h2 class="ttl">패밀리 멤버</h2>
          <p>멤버 전체 구매실적으로 합산되어 등급이 적용돼요.</p>
        </div>
        <aside>
          <!-- [GRIT-141984] UT기반 패밀리서비스 UIUX 개선 요청 : 24.07.31 -->
          <div class="family-idx">
            <span>현재 {{ familyInfo.famyGrpMberCnt }}명</span> / 최대 4명
          </div>
        </aside>
      </header>
      <!-- 구성원 정의
        본인 : 본인이름 + 본인 badge
        구성원 : 본인을 제외한 구성원 이름은 모두 마킹(*) 필요( 한글 : 두번째, 영문 : 처음과 마지막 알파벳 제외 모두 )
      -->
      <div class="family-box">
        <ul class="family-lst">
          <li v-for="(member, idx2) in familyInfo.familyMems" :key="idx2">
            <span v-if="idx2 == 0" class="badge">본인</span
            ><span v-if="idx2 == 0">{{ null != member.custNm ? member.custNm : '' }}</span
            ><span v-else>{{ null != member.custNm ? member.custNm : '멤버' }}</span>
          </li>
        </ul>
        <div v-if="familyInfo.famyGrpMberCnt < 4" class="b-btn-wrap">
          <div>
            <button @click="famyGrpInvitCnt('S')" class="white-btn">
              <i class="ico-user_follow"></i><span>패밀리 멤버 추가하기</span>
            </button>
          </div>
          <!-- [GRIT-141984] UT기반 패밀리서비스 UIUX 개선 요청 : 24.07.31 -->
          <div v-if="isFamyTooltip" class="family-tooltip">
            <p>멤버를 초대하고 멤버십 혜택을 함께 누리세요!</p>
          </div>
        </div>
      </div>
    </div>
    <!-- //패밀리 고객 : 기본 > 패밀리 멤버인 경우 -->

    <!-- 패밀리 고객 : 기본 > 패밀리 멤버인 경우 Tab-->
    <div v-if="isFamily" class="tabs">
      <button @click="tabToggle('mem')" class="tab" :class="[tabActive ? 'active' : '']">
        {{ custGradeInfo.custNm }}님의 실적
      </button>
      <button @click="tabToggle('fmly')" class="tab" :class="[!tabActive ? 'active' : '']">
        <span class="ico-family_logo">패밀리</span> 실적
      </button>
    </div>
    <!-- //패밀리 고객 : 기본 > 패밀리 멤버인 경우 Tab-->
    <!-- //패밀리 멤버 -->

    <!-- 채널 별 : 패밀리/멤버 이번달 등급 -->
    <!-- 패밀리/멤버 : 패밀리/멤버십 채널 스켈레톤 -->
    <div v-if="isChanlSkeleton" class="member-sect">
      <div v-for="(itm, i) in [1, 2, 3, 4]" :key="i" class="shop-lst skeleton">
        <header class="sect-head divide">
          <div class="head">
            <div class="skeleton-text w-60"></div>
          </div>
          <aside>
            <button class="gui-btn icon"><i class="ico-chevron_right"></i>이동하기</button>
          </aside>
        </header>
        <div class="shop-detail">
          <div class="member-progress sm">
            <div class="progress-bar"><p class="bar"></p></div>
            <ul class="datalist" :class="isGshExpos ? 'extension' : ''">
              <li>
                <span class="grade">VVIP</span>
                <span class="price skeleton-text"></span>
              </li>
              <li>
                <span class="grade">VIP</span>
                <span class="price skeleton-text"></span>
              </li>
              <!-- // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급] -->
              <li v-if="isGshExpos">
                <span class="grade">DIAMOND</span>
                <span class="price skeleton-text"></span>
              </li>
              <li>
                <span class="grade">GOLD</span>
                <span class="price skeleton-text"></span>
              </li>
              <li>
                <span class="grade">WELCOME</span>
                <span class="price skeleton-text"></span>
              </li>
            </ul>
          </div>
          <div class="detail-infobox"></div>
        </div>
      </div>
    </div>
    <!-- //패밀리/멤버 : 패밀리/멤버십 채널 스켈레톤 -->
    <!-- 패밀리/멤버 : 기본 > 패밀리/멤버십 채널 이번달 등급, 실적 정보 -->
    <div v-if="!tabActive" class="member-sect">
      <!-- 채널 별 실적 -->
      <div v-for="(items, idx3) in curChanlGradeInfos" :key="idx3" class="shop-lst">
        <header class="sect-head divide">
          <div class="head">
            <h2 v-if="items.chanlGbnCd != 'A'" class="ttl">
              <img class="logo" :src="items.chanlLogo" :alt="items.chanlNm" />
            </h2>
            <h2 v-else class="ttl">
              <h2 class="ttl">{{ items.chanlNm }}</h2>
            </h2>
          </div>
          <aside v-if="!('A' == items.chanlGbnCd || 'E' == items.chanlGbnCd) && isLink">
            <button class="gui-btn icon" @click="goDeepLink(items.code, true)">
              <i class="ico-chevron_right"></i>이동하기
            </button>
          </aside>
        </header>
        <div class="shop-detail">
          <!-- progress -->
          <!-- 작은 사이즈 .sm 추가 -->
          <div class="member-progress sm">
            <div class="progress-bar" ref="barWidth">
              <p
                class="bar"
                :class="{start: items.percent == 0, end: items.percent == 100}"
                :style="{width: items.barStyle}"
              ></p>
            </div>
            <ul class="datalist" :class="isGshExpos ? 'extension' : ''">
              <li
                v-for="(item, idx4) in items.gradeStandard.slice().reverse()"
                :key="idx4"
                :class="{on: item.gradeIdx <= items.gradeIndex}"
              >
                <span class="grade">{{ item.grade }}</span>
                <span v-if="null != item.standard && '' != item.standard" class="price" v-html="item.standard"></span>
              </li>
            </ul>
          </div>
          <!-- //progress -->
          <!-- 구매 정보 -->
          <div class="detail-infobox">
            <div class="text">
              <span class="desc">
                <b>{{ items.month }}</b
                >개월({{ items.period }}) 동안<br />
                <b>{{ items.ordCnt }}</b
                >{{ items.dayOrNum }} <b> {{ items.ordAmt }}</b
                >원 구매했어요
              </span>
              <button @click="onLayer('detail', true, items)" class="gui-btn icon">
                <i class="ico-info_circle"></i>등급적용일기준 구매실적
              </button>
            </div>
          </div>
          <!-- //구매 정보 -->
        </div>
      </div>
      <!-- //채널 별 실적 -->
    </div>
    <!-- //패밀리/멤버 : 기본 > 패밀리/멤버십 채널 이번달 등급, 실적 정보 -->
    <!-- //패밀리 고객 : 고객 실적 > 멤버십 채널 이번달 등급, 실적 정보 -->
    <div v-if="isFamily && tabActive" class="member-sect">
      <!-- 채널 별 실적 -->
      <div v-for="(items, idx5) in curMemChanlGradeInfos" :key="idx5" class="shop-lst">
        <header class="sect-head divide">
          <div class="head">
            <h2 v-if="items.chanlGbnCd != 'A'" class="ttl">
              <img class="logo" :src="items.chanlLogo" :alt="items.chanlNm" />
            </h2>
            <h2 v-else class="ttl">
              <h2 class="ttl">{{ items.chanlNm }}</h2>
            </h2>
          </div>
          <aside v-if="!('A' == items.chanlGbnCd || 'E' == items.chanlGbnCd) && isLink">
            <button class="gui-btn icon" @click="goDeepLink(items.code, true)">
              <i class="ico-chevron_right"></i>이동하기
            </button>
          </aside>
        </header>
        <div class="shop-detail">
          <!-- progress -->
          <!-- 작은 사이즈 .sm 추가 -->
          <div class="member-progress sm">
            <div class="progress-bar" ref="barWidth">
              <p
                class="bar"
                :class="{start: items.percent == 0, end: items.percent == 100}"
                :style="{width: items.barStyle}"
              ></p>
            </div>
            <ul class="datalist" :class="isGshExpos ? 'extension' : ''">
              <li
                v-for="(item, idx6) in items.gradeStandard.slice().reverse()"
                :key="idx6"
                :class="{on: item.gradeIdx <= items.gradeIndex}"
              >
                <span class="grade">{{ item.grade }}</span>
                <span v-if="null != item.standard && '' != item.standard" class="price" v-html="item.standard"></span>
              </li>
            </ul>
          </div>
          <!-- //progress -->
          <!-- 구매 정보 -->
          <div class="detail-infobox">
            <div class="text">
              <span class="desc">
                <b>{{ items.month }}</b
                >개월({{ items.period }}) 동안<br /><b>{{ items.ordCnt }}</b
                >{{ items.dayOrNum }}<b> {{ items.ordAmt }}</b
                >원 구매했어요
              </span>
              <button @click="onLayer('detail', true, items)" class="gui-btn icon">
                <i class="ico-info_circle"></i>등급적용일기준 구매실적
              </button>
            </div>
          </div>
          <!-- //구매 정보 -->
        </div>
      </div>
      <!-- //채널 별 실적 -->
    </div>
    <!-- //패밀리 고객 : 고객 실적 > 멤버십 채널 이번달 등급, 실적 정보 -->
    <!-- //채널 별 : 패밀리/멤버 이번달 등급 -->

    <!-- 패밀리/멤버 : 기본 > 리테일 통합 멤버십이란? -->
    <div class="member-sect">
      <header @click="goPage('what')" class="sect-head divide">
        <div class="head">
          <img src="@/assets/images/member/gs_group.png" alt="선물아이콘" />
          <h2 class="ttl">GS리테일 통합 멤버십이란?</h2>
        </div>
        <aside>
          <button class="gui-btn icon"><i class="ico-chevron_right"></i>이동하기</button>
        </aside>
      </header>
    </div>
    <!-- //패밀리/멤버 : 기본 > 리테일 통합 멤버십이란? -->

    <!-- 버튼 기본 -->
    <div class="member-btm">
      <!-- 패밀리/멤버 : 기본 > 서비스 유의사항 -->
      <div class="grade-notice drop-box" :class="svc ? 'drop-open' : ''">
        <div class="drop-tit">
          <button @click="onLayer('service')" class="noti-tit">서비스 유의사항</button>
        </div>
        <IntgGradeNotice :integrated="integrated" />
      </div>
      <!-- //패밀리/멤버 : 기본 > 서비스 유의사항 -->
    </div>
    <!-- //버튼 기본 -->

    <!-- 채널 실적, 등급 상세 레이어 -->
    <ModalChanlDetail v-if="isChanlDetail" @onLayer="onLayer" :chanlGradeInfo="chanlGradeInfo" />
    <!-- 다음달 실적 기간 안내 레이어 -->
    <ModalFamilyComplete v-if="isNxtGrdGuide" @onLayer="onLayer" :layerType="'next'" />
    <!-- 패밀리 고객 > 패밀리 그룹 하단 메뉴 레이어 -->
    <ModalFamilyMenu
      v-if="isFmlyMenu"
      @onLayer="onLayer"
      :fmlyJoinType="custGradeInfo.fmlyJoinType"
      :isNewFamilyGrp="isNewFamilyGrp"
    />
    <!-- 패밀리 정보 보기 레이어 -->
    <!-- famyGrpId: String, famyGrpNm: String, openDt: String -->
    <ModalFamilyInfo v-if="isFmlyInfo" @onLayer="onLayer" :familyInfo="familyInfo" />
    <!-- 패밀리 명 변경 레이어 -->
    <ModalFamilyRename v-if="isFmlyRename" @onLayer="onLayer" />
    <!-- 공통 Alert 레이어 -->
    <ModalAlert v-if="isAlert" @onLayer="onLayer" :alert="alert" :type="alert.type" />
    <!-- SNS 공유 컴포넌트 -->
    <!--     // 패밀리그룹 초대/수락 이슈 : 24.06.11 -->
    <SnsInvite v-if="isSns" :famyGrpId="familyInfo.famyGrpId" :encFamyGrpId="familyInfo.encFamyGrpId" ref="snsInvite" />
    <!-- SNS공유 component (카카오톡 공유) -->
    <!-- 앰플리튜드 기능 추가-->
    <TagManager v-if="true" ref="tagManager" />
  </div>
</template>

<script>
import IntgGradeNotice from '@/views/integrate/IntgGradeNotice.vue'
import ModalChanlDetail from '@/views/comm/integrate/ModalChanlDetail.vue'
import ModalFamilyComplete from '@/views/comm/integrate/ModalFamilyComplete.vue'
import ModalFamilyMenu from '@/views/comm/integrate/ModalFamilyMenu.vue'
import ModalFamilyInfo from '@/views/comm/integrate/ModalFamilyInfo.vue'
import ModalFamilyRename from '@/views/comm/integrate/ModalFamilyRename.vue'
import ModalAlert from '@/views/comm/integrate/ModalAlert.vue'
import SnsInvite from '@/views/comm/integrate/SnsInvite.vue'
import TagManager from '@/views/comm/TagManager.vue'
import {Navigation, Pagination} from 'swiper'
import {SwiperCore, Swiper, SwiperSlide} from 'swiper-vue2'
SwiperCore.use([Navigation, Pagination])

export default {
  name: 'IntgMemshpGrade',
  inject: ['sendTitle'],
  components: {
    IntgGradeNotice,
    ModalChanlDetail,
    ModalFamilyComplete,
    ModalFamilyMenu,
    ModalFamilyInfo,
    ModalFamilyRename,
    ModalAlert,
    SnsInvite,
    Swiper,
    SwiperSlide,
    // 앰플리튜드 기능 추가
    TagManager
  },
  data() {
    return {
      title: 'GS리테일 통합멤버십',
      // <!-- 기본 상수 부분 -->
      chanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
      isApp:
        /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) ||
        '2010' === this.$store.state.apiStore.bodyParam.chanlCd,
      gradeArray: this.$store.state.integrate.gradeArray, // 채널별 등급 정보
      chanlGradeArry: this.$store.state.integrate.chanlGradeArry, // 등급별 혜택(채널) 정보
      // <!-- //기본 상수 부분 -->
      // 스타일 정보
      topStyle: {
        // 기본 skeleton 설정
        default: 'block',
        // 상단 스타일 : 등급 미노출 케이스 스타일 영역
        //  <!-- 케이스 1-1 : 패밀리/멤버 > 다음달 VVIP 등급 축하 -->
        //  <!-- 케이스 1-2 : 패밀리 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
        //  <!-- 케이스 1-3 : 패밀리 고객 > 이번달 노출, 다음달 등급, 실적이 0인 경우 -->
        //  <!-- 케이스 1-4 : 멤버십 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
        //  <!-- 케이스 1-5 : 패밀리 탈퇴 후 미가입 > 이번달 패밀리적용, 미가입 -->
        nonGrade: 'none',
        // 상단 스타일 : 등급 노출 케이스 스타일 영역
        grade: 'none',
        // 등급조정안내문구
        grdModTgt: 'none'
      },
      // <!-- 상수 부분 -->

      // 정리 버전
      //  레이어
      svc: false, // 서비스 유의사항 기본 펼침
      isChanlDetail: false, //채널 등급,실적 상세 레이어 노출여부
      isNxtGrdGuide: false, //다음달 등급 안내 레이어 노출여부
      isFmlyMenu: false, //패밀리 고객, 패밀리 그룹 하단 메뉴 레이어 노출여부
      isFmlyRename: false, //패밀리 그룹 명 변경 레이어 노출여부
      isFmlyInfo: false, //패밀리 그룹 정보 보기 레이어 노출여부
      isSns: true, //SNS 사용여부(하드코딩으로 사용유무 설정부분 추가)
      isFamyTooltip: true, //[GRIT-141984] UT기반 패밀리서비스 UIUX 개선 요청 : 24.07.31
      //  레이어
      isFamily: false, //패밀리 그룹여부
      isNewFamilyGrp: false, //신규 패밀리그룹 조회 여부
      isNxtMm: false, //패밀리 탈퇴 후 익월 조회 여부
      chanlGradeInfo: {}, //채널 등급,실적 상세 레이어 전달 Object
      // 고객등급정보
      custGradeInfo: {
        intgMemNo: '', //고객번호
        custNm: '고객', //고객명
        famyGrpAdmYn: '', //패밀리그룹가입여부(등급적용 패밀리)
        curGrdNm: 'WELCOME', //이번달 고객 등급명
        curGrdIdx: 0, //이번달 고객 등급 index
        nxtGrdNm: 'WELCOME', //다음달 고객 등급명
        nxtGrdIdx: 0, //다음달 고객 등급 index
        // updGrdNm: 'WELCOME', //등급을 올리려면 고객 등급명
        // updGrdIdx: 0 //등급을 올리려면 고객 등급 index
        isCurEntprGrdExpos: false, //이번달 등급 노출여부(등급/실적 존재 유무)
        isNxtPurchase: true, //다음달 채널 전체 실적 존재 여부
        isNxtEntprGrdExpos: true, //다음번달 등급 노출여부(등급/실적 존재 유무)
        isGrdModTgt: false, //GS프래시몰 등급조정대상여부
        grdApplyMm: '', //고객 등급적용 월 (패밀리 그룹, 멤버십 등급 적용 월)
        grdApplyFlwMm: '', //26일 이후 패밀리 탈퇴 고객 다음달/등급업 적용 월 (패밀리 그룹, 멤버십 등급 적용 월)
        fmlyJoinType: 'new' //패밀리 가입 유형 ['new': (신규/기존)패밀리그룹, 'exit' : 탈퇴후 미가입, 'rejoin' : 탈퇴후 (신규/재)가입]
      },
      familyInfo: {
        // isFamily: false, //패밀리 그룹여부
        exposFamyGrpId: '', //이전 패밀리그룹ID
        exposFamyGrpNm: '패밀리그룹', //이전 노출패밀리그룹명
        famyGrpWthdrwDtm: '', //이전패밀리 그룹 탈퇴 일시
        famyGrpId: '', //현재 패밀리그룹ID
        // 패밀리그룹 초대/수락 이슈 : 24.06.11
        encFamyGrpId: '', //암호화 패밀리그룹ID
        famyGrpNm: '패밀리그룹', //현재 패밀리그룹명
        famyGrdApplyYn: '', //패밀리그룹 등급 적용여부
        famyGrpMberCnt: 0, //패밀리그룹멤버수
        invitCnt: 0, //패밀리멤버초대회수
        openDt: '', //패밀리그룹오픈일시
        familyMems: [], //패밀리멤버리스트
        rename: '' //패밀리 명 변경
      },
      // 상단/이번달/등급을올리리면 채널등급 정보
      topChanlGradeInfos: [], //상단 채널등급(다음달,등급을 올리려면) 정보
      updChanlGradeInfos: [], //상단 설정(등급을 올리려면) 정보
      curChanlGradeInfos: [], //하단 채널등급(이번달) 정보
      curMemChanlGradeInfos: [], //하단 멤버십 채널등급(이번달) 정보 : 패밀리그룹 Tab 고객 실적
      // bar 등급별 기준 퍼센트
      goldPecent: 33.3,
      // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
      diamondPercent: 0.0,
      vipPercent: 66.6,
      vvipPercent: 100,
      // 정리 버전
      tabActive: false,
      integrated: true, // 통합 회원 여부

      // <!-- 딥링크 부분 -->
      isLink: true, //우동G 채널 : 앱 버전 체크 후 링크 유무 설정
      gshDeeplink: this.$store.state.apiStore.bodyParam.gshBnftLink,
      wdgDeeplink: this.$store.state.apiStore.bodyParam.wdgBnftLink,
      gsrDeeplink: this.$store.state.apiStore.bodyParam.gsrBnftLink,
      // <!-- //딥링크 부분 -->

      // <!-- 앰플리튜드 부분 -->
      isDelay: true, //딥링크 호출 1.5초 딜레이 설정 (앰플리튜드 수집 후 화면 이동 처리)
      // <!-- //앰플리튜드 부분 -->

      // <!-- 알럿(예외처리) 함수 -->
      isAlert: false, //알럿(예외처리) 노출 여부
      // 알럿(예외처리) 정보
      alert: {
        type: 'error', // 유형
        code: '', // (노출)코드
        message: '' // (노출)메시지
      }, //알럿(예외처리) 코드/메세지 정보
      // <!-- //알럿(예외처리) 함수 -->

      //GS프래시몰 등급조정대상안내 문구 노출 기준일
      isGsrGrdModTgtNtc: this.$dayjs().format('YYYYMMDD') > '20240331',
      // - 채널실적 스켈레톤 적용
      isChanlSkeleton: true,
      isPercent: false, // 툴팁,에로우 퍼센트/픽셀여부 설정
      // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
      isGshExpos: this.$dayjs().format('YYYYMMDD') >= this.$store.state.integrate.gshGrdExposDt,
      vvipIndex: 3,
      vipIndex: 2
      // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
    }
  },
  updated() {
    console.log('####### updated 멤버십/패밀리 TOP 툴팁 적용 #######')
    this.topTooltipRe(false)
  },
  created() {
    console.log('')
    console.log('######## 멤버십/패밀리 등급 서비스 UI ########')
    console.log('')

    // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
    if (this.isGshExpos) {
      this.gradeArray = this.$store.state.integrate.newGradeArray
      this.chanlGradeArry = this.$store.state.integrate.newChanlGradeArry
      // this.gradeBnftArray = this.$store.state.integrate.newGradeBnftArray
      this.vvipIndex = 4
      this.vipIndex = 3
      this.goldPecent = 25.0
      this.diamondPercent = 50.0
      this.vipPercent = 75.0
      // this.tabList.grades = [{name: 'VVIP'}, {name: 'VIP'}, {name: 'Diamond'}, {name: 'GOLD'}]
    }
    // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]

    // 우동G 채널 경우 앱 버전 체크하여 링크 유무 설정
    console.log('@@@@@ IntgMemshpGrade created str : isLink : ' + this.isLink)
    if (this.isLink && '2010' === this.$store.state.apiStore.bodyParam.chanlCd) {
      if (navigator.userAgent.indexOf('woodongs_') > -1) {
        var agentArr = navigator.userAgent.substring(navigator.userAgent.indexOf('woodongs_')).split('_')
        if (null != agentArr && agentArr.length >= 3 && validate(agentArr[2])) {
          if (compare('5.1.9', agentArr[2], '>')) {
            console.log('@@ woodongs_ agent 버전 확인 this.isLink = false')
            this.isLink = false
          }
        } else {
          console.log('@@ woodongs_ agent 버전 형식, 길이 오류 : ' + agentArr)
        }
      } else {
        console.log('@@ woodongs_ agent 문자열 없음 this.isLink = false')
        this.isLink = false
      }
    }
    console.log('@@@@@ IntgMemshpGrade created end : isLink : ' + this.isLink)
  },
  // 다음달등급/등급업(TOP) 영역 툴팁 위치 재조정
  beforeDestroy() {
    console.log('###### beforeDestroy removeEventListener resize')
    window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    console.log('####### mounted str #######')
    document.title = this.title
    this.sendTitle(this.title)
    window.scrollTo(0, 0)

    // 다음달등급/등급업(TOP) 영역 툴팁 위치 재조정
    window.addEventListener('resize', this.handleResize)

    this.retrieveChFamyGrpAdmYn()
  },
  methods: {
    // 다음달등급/등급업(TOP) 영역 툴팁 위치 재조정
    handleResize(event) {
      console.log('####### handleResize resize event #######')
      if (null != this.topChanlGradeInfos && this.topChanlGradeInfos.length == 1) {
        this.topTooltipRe(true)
      }
    },
    // 다음달등급/등급업(TOP) 영역 툴팁 위치 재조정
    topTooltipRe(isInit) {
      console.log('#####################################################')
      console.log((isInit ? 'Resize ' : '') + '@@@@@@ 다음달 실적 설정 후 툴팁 위치 계산 진행??')
      console.log('#####################################################')

      this.topChanlGradeInfos.forEach((e, i) => {
        if (isInit) {
          // 초기화
          console.log('Resize ' + e.chanlGbnCd + '> ' + (this.isPercent ? '퍼센트 ' : '') + '초기화')
          if (this.isPercent) {
            document.querySelector('#tooltip-' + e.chanlGbnCd).style.left = '0%'
          } else {
            document.querySelector('#tooltip-' + e.chanlGbnCd).style.left = '0px'
          }
          document.querySelector('#arrow-' + e.chanlGbnCd).style.removeProperty('left')
        }

        // 등급바 전체 width px
        var totWidth = Number(document.querySelector('#datalist-' + e.chanlGbnCd).offsetWidth)
        // 툴팁바 전체 width px
        var tooltipWidth = Number(document.querySelector('#tooltip-' + e.chanlGbnCd).offsetWidth) / 2
        // 퍼센트 width px
        var percentWidth = Number(((Number(totWidth) * Number(e.percent)) / 100).toFixed(1))
        // bar background-size 추가
        document.querySelector('.bar').style.backgroundSize = totWidth + 'px 100%'
        // console.log('   ##################################################')

        var tooltip = 0 //퍼센트툴팁
        var arrow = 0 //퍼센트에로우
        if (percentWidth <= tooltipWidth) {
          console.log(e.chanlGbnCd + '> @@ ' + (this.isPercent ? '퍼센트 ' : '') + '결과 : 왼쪽 설정')

          document.querySelector('#tooltip-' + e.chanlGbnCd).style.left = tooltip + (this.isPercent ? '%' : 'px')
          console.log(e.chanlGbnCd + '> ' + (this.isPercent ? '퍼센트 ' : '') + 'tooltip : ' + tooltip)

          if (e.percent < 5) {
            document.querySelector('#arrow-' + e.chanlGbnCd).classList.add('left')
            console.log(e.chanlGbnCd + '> ' + (this.isPercent ? '퍼센트' : '') + 'arrow   : 왼쪽 설정')
          } else {
            if (percentWidth > 0) {
              if (this.isPercent) {
                arrow = Number(((Number(percentWidth) - 9) / (tooltipWidth * 2)) * 100).toFixed(1)
              } else {
                arrow = Number(Number(percentWidth) - 9).toFixed(1)
              }
              document.querySelector('#arrow-' + e.chanlGbnCd).style.left = arrow + (this.isPercent ? '%' : 'px')
              console.log(e.chanlGbnCd + '> ' + (this.isPercent ? '퍼센트 ' : '') + 'arrow   : ' + arrow)
            }
          }
        } else if (percentWidth >= totWidth - tooltipWidth) {
          console.log(e.chanlGbnCd + '> @@ ' + (this.isPercent ? '퍼센트 ' : '') + '결과 : 오른쪽 설정')
          if (this.isPercent) {
            tooltip = (((Number(totWidth) - Number(tooltipWidth * 2 - 1) - Number(5)) / totWidth) * 100).toFixed(1)
          } else {
            tooltip = (Number(totWidth) - Number(tooltipWidth * 2 - 1) - Number(5)).toFixed(1)
          }
          document.querySelector('#tooltip-' + e.chanlGbnCd).style.left = tooltip + (this.isPercent ? '%' : 'px')
          console.log(e.chanlGbnCd + '> ' + (this.isPercent ? '퍼센트 ' : '') + 'tooltip : ' + tooltip)

          if (e.percent >= 95) {
            document.querySelector('#arrow-' + e.chanlGbnCd).classList.add('right')
            console.log(e.chanlGbnCd + '> ' + (this.isPercent ? '퍼센트' : '') + 'arrow   : 오른쪽 설정')
          } else {
            if (this.isPercent) {
              arrow = (
                ((Number(percentWidth) - Number(totWidth - tooltipWidth * 2 - 1) - Number(5)) / (tooltipWidth * 2)) *
                100
              ).toFixed(1)
            } else {
              arrow = (Number(percentWidth) - Number(totWidth - tooltipWidth * 2 - 1) - Number(5)).toFixed(1)
            }
            document.querySelector('#arrow-' + e.chanlGbnCd).style.left = arrow + (this.isPercent ? '%' : 'px')
            console.log(e.chanlGbnCd + '> ' + (this.isPercent ? '퍼센트 ' : '') + 'arrow   : ' + arrow)
          }
        } else {
          console.log(e.chanlGbnCd + '> @@ ' + (this.isPercent ? '퍼센트 ' : '') + '결과 : 센터 설정')
          if (this.isPercent) {
            tooltip = ((Number(percentWidth - tooltipWidth) / totWidth) * 100).toFixed(1)
          } else {
            tooltip = Number(percentWidth - tooltipWidth).toFixed(1)
          }
          document.querySelector('#tooltip-' + e.chanlGbnCd).style.left = tooltip + (this.isPercent ? '%' : 'px')
          console.log(e.chanlGbnCd + '> ' + (this.isPercent ? '퍼센트 ' : '') + 'tooltip : ' + tooltip)

          document.querySelector('#arrow-' + e.chanlGbnCd).classList.add('center')
          console.log(e.chanlGbnCd + '> ' + (this.isPercent ? '퍼센트' : '') + 'arrow   : 센터 설정')
        }
        // console.log(document.querySelector('#datalist-' + e.chanlGbnCd).style)
        // console.log(document.querySelector('#tooltip-' + e.chanlGbnCd).style)
        console.log('#####################################################')
      })
    },
    // 상수 값 추출 함수
    getGradeArr(grdCd) {
      return this.gradeArray.find((el) => el.grdCd == grdCd)
    },
    getChanlArr(chanlGbnCd) {
      return this.chanlGradeArry.find((el) => el.chanlGbnCd == chanlGbnCd)
    },
    // 레이어 통합 관리
    onLayer(type, boolean, inData) {
      console.log('>>> type : ' + type + ', boolean : ' + boolean + ', inData : ' + inData)
      switch (type) {
        case 'next': // 다음달 등급 적용 레이어 (비)노출 처리
          this.isNxtGrdGuide = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
          }
          break
        case 'service': // 서비스 유의사항 레이어 (비)노출 처리
          if (this.svc) this.svc = false
          else this.svc = true
          break
        case 'detail': // 채널 상세 레이어 (비)노출 처리
          console.log(inData)
          this.chanlGradeInfo = inData
          this.isChanlDetail = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
          }
          break
        case 'menu': // 패밀리 고객 패밀리 그룹 하단 메뉴
          this.isFmlyMenu = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
          }
          break
        case 'exit': // 패밀리 고객 패밀리 그룹 하단 메뉴
          this.isFmlyMenu = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            // 앰플리튜드 추가
            this.tagging('CLICK-INTG-FMLY-GRP-EXIT')
            this.bodyFixedNone()
          }
          this.goPage('exit')
          break
        case 'family': // 패밀리 정보 보기
          this.isFmlyMenu = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            // this.bodyFixedNone()
            console.log('>> 패밀리 정보 보기 레이어 노출 시작')
            // 앰플리튜드 추가
            this.tagging('CLICK-INTG-FMLY-GRP-MORE')
            this.isFmlyInfo = true
          }
          break
        case 'fmlyClose': // 패밀리 정보 보기
          this.isFmlyInfo = boolean
          this.bodyFixedNone()
          break
        case 'rename': // 패밀리 명 변경
          this.isFmlyMenu = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            // this.bodyFixedNone()
            console.log('>> 패밀리 명 변경하기 레이어 노출 시작')
            // 앰플리튜드 추가
            this.tagging('CLICK-INTG-FMLY-GRP-UPDATE')
            this.isFmlyRename = true
          }
          break
        case 'renameClose': // 패밀리 명 변경 레이어 닫기
          this.isFmlyRename = false
          this.bodyFixedNone()
          break
        case 'cancel': // 패밀리 명 변경 처리, 레이어 닫기
          this.isAlert = false
          // 취소 시 알럿(예외처리) 초기화
          this.alert.type = 'error'
          this.alert.code = ''
          this.alert.message = ''
          this.bodyFixedNone()
          break
        case 'renameUpdate': // 패밀리 명 변경 레이어 닫기
          console.log('>>> 패밀리 명 변경 전 확인 레이어 노출 inData : ' + inData)
          this.isFmlyRename = false
          // 패밀리 명 변경 정보
          if (!this.$isEmpty(inData)) {
            this.familyInfo.rename = inData
            this.alert.type = 'confirm'
            this.alert.code = ''
            this.alert.message = '수정하시겠습니까?'

            // 알럿(예외처리) 레이어 노출
            this.onLayer('alert', true)
          }
          break
        case 'confirm': // 패밀리 명 변경 처리, 레이어 닫기
          this.isAlert = false
          // 변경 요청 시 알럿(예외처리) 초기화
          this.alert.type = 'error'
          this.alert.code = ''
          this.alert.message = ''
          this.bodyFixedNone()
          console.log('>> 패밀리 명 변경하기 : rename : ' + this.familyInfo.rename)
          if (!this.$isEmpty(this.familyInfo.rename)) {
            this.addChFamyGrpOpenInfo(this.familyInfo.rename)
          }
          break
        case 'alert': // 패밀리 명 변경
          this.isAlert = boolean

          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()

            if (!this.$isEmpty(this.alert.code) && '0000' != this.alert.code) {
              // 50% resMsg : 미인증 또는 인증이 만료되었습니다. 새로고침해 주세요.
              if (this.alert.code.startsWith('50') || '9999' == this.alert.code || '4000' == this.alert.code) {
                console.log('>> 알럿(예외처리) 노출 후 마이쇼핑 이동(웹뷰 닫기) 케이스 : ' + this.alert.code)
                this.windowClose()
              } else {
                console.log('>> 그외 레이어만 닫을 것인가?')
              }
            } else {
              console.log('>> 정보성 알럿 완료 후 닫기만 한다.')
            }
          }
          break
        default:
          break
      }
      return
    },
    goPage(type) {
      // console.log('>>> 링크 화면이동 처리 type : ' + type)
      switch (type) {
        case 'standard': //GS통합멤버십 등급 소개 이동
          console.log('>>> GS통합멤버십 등급 소개 이동....')
          // 앰플리튜드 추가
          this.isFamily ? this.tagging('CLICK-INTG-FMLY-GRD-BNFT') : this.tagging('CLICK-INTG-MEM-GRD-BNFT')
          this.$router.push({
            name: 'IntgGradeStandard',
            params: {
              custNm: this.custGradeInfo.custNm,
              curGrdIdx: this.custGradeInfo.curGrdIdx,
              curGrdNm: this.custGradeInfo.curGrdNm
            }
          })
          break
        case 'family': //패밀리 그룹 소개/만들기 이동
          // console.log('>>> GS통합멤버십 패밀리 그룹이란 이동....')
          // 앰플리튜드 추가
          this.tagging('CLICK-INTG-MEM-GRD-FMLY')
          this.$router.push('/montblanc/intg/familyInfo')
          break
        case 'newFamily': //새로운 패밀리 그룹 가기
          // console.log('>>> 새로운 패밀리 가기 이동 처리....')
          this.goFamilyGradeInfo()
          break
        case 'what': //GS리테일 통합멤버십 소개 이동
          // console.log('>>> GS리테일 통합멤버십 소개로 이동....')
          // 앰플리튜드 추가
          this.isFamily ? this.tagging('CLICK-INTG-FMLY-GRD-WHAT') : this.tagging('CLICK-INTG-MEM-GRD-WHAT')
          this.$router.push('/montblanc/intg/whatMemshp')
          break
        case 'exit': //패밀리 그룹 탈퇴 이동
          // console.log('>>> 패밀리 그룹 탈퇴 이동....')
          this.$router.push({
            name: 'IntgFamilyExit',
            params: {famyGrpId: this.familyInfo.famyGrpId}
          })
          break
        default:
          break
      }
      return
    },
    // <!-- 알럿(예외처리) 함수 -->
    onAlert(code, message) {
      console.log('>>> onAlert(알럿/예외처리) 기능 code : ' + code + ', message : ' + message)
      // 4144 resMsg : 통합고객번호 없는 경우 callback 필요함.
      this.alert.code = code
      this.alert.message = message

      this.onLayer('alert', true)
    },
    // <!-- //알럿(예외처리) 함수 -->
    // 레이어 띄울때 body fixed
    bodyFixed() {
      document.querySelector('html').classList.add('scroll-rock')
      document.body.classList.add('scroll-rock')
      // this.nowOffset = window.scrollY
      // document.body.style.top = '-' + this.nowOffset + 'px'
    },
    // 레이어 띄울때 body fixed 제거
    bodyFixedNone() {
      document.body.classList.remove('scroll-rock')
      document.querySelector('html').classList.remove('scroll-rock')
      document.body.removeAttribute('class')
      document.body.removeAttribute('style')
      // window.scrollTo(0, this.nowOffset)
    },
    // 패밀리 고객 > 멤버십, 패밀리 이번달 채널 등급,실적 탭 이동
    tabToggle(type) {
      if ('mem' == type) {
        console.log('####### 패밀리 멤버 : 멤버십 이번달 등급 조회 고고~~~')
        // 앰플리튜드 추가
        this.tagging('CLICK-INTG-FMLY-PUR-MEM')
        this.tabActive = true
        if (this.curMemChanlGradeInfos.length == 0) {
          // 채널실적 스켈레톤 적용
          this.isChanlSkeleton = true
          this.retrieveGrdRlrslt(1, true)
        }
      } else {
        console.log('####### 패밀리 멤버 : 멤버십 이번달 등급 tabToggle 처리')
        // 앰플리튜드 추가
        this.tagging('CLICK-INTG-FMLY-PUR-FMLY')
        if (this.isNewFamilyGrp && !this.isNxtMm) {
          console.log('####### 신규 패밀리 그룹에서 패밀리 실적은 보여주지 않는다.')
          // this.tabActive = true
          var message =
            '그룹 멤버 전체 구매 내역에 따라 ' + this.custGradeInfo.grdApplyMm + '월부터 통합 등급이 적용됩니다.'
          this.onAlert('0000', message)
        } else {
          this.tabActive = false
        }
      }
    },
    // app/web 체크하여 창닫기 함수.
    windowClose() {
      console.log('#### windowClose back ####')
      sessionStorage.removeItem('inCheck1')
      //GSSHOP 앱(웹뷰) 여부 체크
      // alert('패밀리서비스 이동 임시 알럿:' + navigator.userAgent + ', isApp[' + this.isApp + ']')
      if (this.isApp) {
        if ('2010' === this.chanlCd || '3010' === this.chanlCd) {
          // alert('패밀리서비스 임시 창닫기 : 앱 닫기 toapp://close 호출')
          location.href = 'toapp://close'
        } else {
          // alert('패밀리서비스 임시 창닫기 : 앱 뒤로가기 toapp://back 호출')
          location.href = 'toapp://back'
        }
      } else {
        // alert('패밀리서비스 임시 창닫기 : 웹 뒤로가기 go(-1) 호출')
        this.$router.go(-1)
      }
      console.log('#### 패밀리서비스 windowClose app/web 창닫기 끝')
    },

    setTopGradeStyle() {
      var styleCase = 0
      // console.log('>> 등급정보 조회 완료 후 노출 처리 style case ' + styleCase)
      // 상단 스타일 : 등급 미노출 케이스 스타일 영역
      //  <!-- 케이스 1-1 : 패밀리/멤버 > 다음달 VVIP 등급 축하 -->
      //  <!-- 케이스 1-2 : 패밀리 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
      //  <!-- 케이스 1-3 : 패밀리 고객 > 이번달 노출, 다음달 등급, 실적이 0인 경우 -->
      //  <!-- 케이스 1-4 : 멤버십 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
      //  <!-- 케이스 1-5 : 멤버십 고객 > 이번달 미노출, 다음달 등급, 실적이 0인 경우 -->
      //  <!-- 케이스 1-6 : 패밀리 탈퇴 후 미가입 > 이번달 패밀리적용, 미가입
      //                 : 패밀리 탈퇴 후 재가입 > 탈퇴 월 조회 시            -->
      if (
        this.custGradeInfo.nxtGrdIdx > this.vipIndex || //케이스 1-1
        (this.isFamily && !this.custGradeInfo.isCurEntprGrdExpos && !this.custGradeInfo.isNxtPurchase) || //케이스 1-2
        (this.isFamily && this.custGradeInfo.isCurEntprGrdExpos && !this.custGradeInfo.isNxtPurchase) || //케이스 1-3
        (!this.isFamily && !this.custGradeInfo.isCurEntprGrdExpos && !this.custGradeInfo.isNxtPurchase) || //케이스 1-4
        (!this.isFamily && this.custGradeInfo.isCurEntprGrdExpos && !this.custGradeInfo.isNxtPurchase) || //케이스 1-5
        // (this.isFamily && this.custGradeInfo.isExitFmly) //케이스 1-5
        (this.isFamily && !this.isNxtMm && 'new' != this.custGradeInfo.fmlyJoinType) //케이스 1-6
      ) {
        styleCase = 1
      } else {
        styleCase = 2
      }
      // 상단 스타일 : 등급 노출 케이스 스타일 영역
      // grade: 'none'
      switch (styleCase) {
        case 1: // 이번달 등급
          this.topStyle.default = 'none'
          this.topStyle.nonGrade = 'block'
          this.topStyle.grade = 'none'
          break
        case 2: // 다음달 등급
          this.topStyle.default = 'none'
          this.topStyle.nonGrade = 'none'
          this.topStyle.grade = 'block'
          //앰플리튜드 호출 필요함.
          console.log('## 앰플리튜드 호출 : 상단 채널 등급/업 노출 시')
          //progress bar animation 효과
          var percent = [] //this.topChanlGradeInfos[0].percent
          var progress = []
          this.topChanlGradeInfos.forEach((e) => {
            percent.push(e.percent)
            progress.push(0)
          })

          percent.forEach((e, i) => {
            this.progressTick(i, percent, progress)
          })
          break
        default:
          this.topStyle.default = 'block'
          this.topStyle.nonGrade = 'none'
          this.topStyle.grade = 'none'
          break
      }

      // GS프래시몰 등급조정고객 안내 문구
      if (this.custGradeInfo.isGrdModTgt && !this.isGsrGrdModTgtNtc) {
        this.topStyle.grdModTgt = 'block'
      } else {
        this.topStyle.grdModTgt = 'none'
      }

      //하단 채널 실적 영역
      // 채널실적 스켈레톤 적용
      this.isChanlSkeleton = false

      // 앰플리튜드 추가
      this.isFamily ? this.tagging('VIEW-INTG-FMLY-GRD') : this.tagging('VIEW-INTG-MEM-GRD')
      if (1 === styleCase) {
        this.custGradeInfo.nxtGrdIdx > this.vipIndex
          ? this.tagging('VIEW-INTG-GRD-VVIP')
          : this.tagging('VIEW-INTG-GRD-EMPTY')
      } else if (2 == styleCase) {
        !this.$isEmpty(this.topChanlGradeInfos)
          ? 'C' === this.topChanlGradeInfos[0].chanlGbnCd
            ? this.tagging('VIEW-INTG-GRD-C')
            : 'S' === this.topChanlGradeInfos[0].chanlGbnCd
            ? this.tagging('VIEW-INTG-GRD-S')
            : 'H' === this.topChanlGradeInfos[0].chanlGbnCd
            ? this.tagging('VIEW-INTG-GRD-H')
            : 'A' === this.topChanlGradeInfos[0].chanlGbnCd
            ? this.tagging('VIEW-INTG-GRD-A')
            : ''
          : ''
      }

      console.log('>> 등급정보 조회 완료 후 노출 처리 style case ' + styleCase)
      console.log('')

      console.log('################## 결과 데이터 정보')
      console.log('### 고객,패밀리 데이터 정보')
      console.log({
        isFamily: this.isFamily,
        isNewFamilyGrp: this.isNewFamilyGrp,
        isNxtMm: this.isNxtMm,
        custGradeInfo: this.custGradeInfo,
        familyInfo: this.familyInfo,
        percentInfo: {
          goldPecent: this.goldPecent,
          diamondPercent: this.diamondPercent,
          vipPercent: this.vipPercent,
          vvipPercent: this.vvipPercent
        }
      })
      console.log('### 채널 별 등급,실적 데이터 정보')
      console.log({
        curChanlGradeInfos: this.curChanlGradeInfos,
        updChanlGradeInfos: this.updChanlGradeInfos,
        topChanlGradeInfos: this.topChanlGradeInfos,
        curMemChanlGradeInfos: this.curMemChanlGradeInfos
      })
      console.log('################## 결과 데이터 정보')

      return
    },
    // progress bar > 3주차 진행
    //      > Gold 이상 고객 경우 : 80%일때 +=2 이후 +0.2 , Gold 고객은 +=2
    progressTick(i, percent, progress) {
      // console.log('## index[' + i + '], percent[' + percent[i] + '], progress[' + progress[i] + '] 시작.....')
      var time = 10
      var topBar = document.querySelectorAll('#topBar')
      if (!this.$isEmpty(topBar)) {
        setTimeout(function tick() {
          topBar.forEach((e) => {
            topBar[i].style.width = progress[i] + '%'
          })
          if (percent[i] <= progress[i]) {
            clearTimeout(time)
          } else {
            if (percent[i] <= 33.3) {
              progress[i] += 2
            } else {
              if (progress[i] < (Number(percent[i]) * 0.8).toFixed(1)) {
                progress[i] += 2
              } else {
                progress[i] += 0.2
              }
            }
            setTimeout(tick, time)
          }
        }, 500)
      }
      // console.log('## index[' + i + '], percent[' + percent[i] + '], progress[' + progress[i] + '] 종료.....')
    },
    //------------------ 기능 호출

    // 패밀리 재가입 고객 신규 패밀리 가기 이벤트 처리
    // 화면은 유지하고 데이터 조회를 다시 한다.
    goFamilyGradeInfo() {
      console.log('## : 신규 패밀리 그룹 조회 설정 ' + this.isNewFamilyGrp)
      this.isNewFamilyGrp = true
      // - 탈퇴 월에 : 신규 패밀리 가기 시 아래 세팅을 통해 초기화 부분이 필요함.
      // - 탈퇴 익월 : 신규 패밀리 가기 시 아래 세팅을 하지 않고 실적 유무 판단을 해야함.
      // console.log('>> 신규패밀리 그룹 가기 조회 시 기존 정보 초기화')
      if (this.isNewFamilyGrp) {
        this.custGradeInfo.isCurEntprGrdExpos = false
        this.custGradeInfo.isNxtPurchase = false
      }

      console.log('## : 가입 유무부터 다시 조회')
      this.retrieveChFamyGrpAdmYn()
    },
    // 멤버십/패밀리 그룹 다음달/등급업 조회
    getCustGradeInfo() {
      console.log('## : 패밀리 그룹여부 결과/분기 : ' + this.isFamily)

      if (this.isFamily) {
        // 패밀리 가입/탈퇴 후 미가입 또는 패밀리 가입/탈퇴 후 재가입 또는 패밀리 가입 (1~25/26~30)
        //  AS-IS : 패밀리 등급 유지 또는 등급 ?월 적용 안내 문구 노출
        //  TO-BE : 등급 적용 기준이 아닌 고객 상태(패밀리/멤버십) 기준으로 노출한다. 탈퇴월 비교 하지 않음. (23.09.27 협의)
        //    CASE1 : 패밀리(이전 패밀리 등급 적용) 탈퇴 (미가입) 상태          > 탈퇴 후 바로 멤버십 노출
        //    CASE2 : 패밀리(이전 패밀리 등급 적용) 탈퇴 (다른 패밀리 가입) 상태 > 신규 패밀리 멤버십 노출
        console.log('## : 탈퇴 후 익월 조회 여부 체크 및 강제 설정')
        if ('new' != this.custGradeInfo.fmlyJoinType && this.familyInfo.exposFamyGrpId != this.familyInfo.famyGrpId) {
          // var drwYm = this.$dayjs(this.familyInfo.famyGrpWthdrwDtm).add(1, 'month').format('YYYYMM')
          // var curYm = this.$dayjs().format('YYYYMM')
          // // 탈퇴 월 + 1 == 조회 기준 월인 경우
          // if (drwYm == curYm) {
          this.isNxtMm = true
          if (this.$isEmpty(this.familyInfo.famyGrpId)) {
            this.isFamily = false
          } else {
            this.isNewFamilyGrp = true
          }
          // }
        }
      }

      if (this.isFamily) {
        if (!this.isNewFamilyGrp) {
          console.log('## : 패밀리 그룹 : 패밀리 그룹 탈퇴(재가입) 고객 패밀리 탈퇴 당월 이전 패밀리 멤버 조회')
          this.retrieveChFamyGrpInfoDtl(this.familyInfo.exposFamyGrpId)
        } else {
          console.log('## : 패밀리 그룹 : (신규/재)패밀리 가입 고객/재가입 고객 익월 경우 현재 패밀리 멤버 조회')
          this.retrieveChFamyGrpInfoDtl(this.familyInfo.famyGrpId)
        }
      } else {
        //멤버십/패밀리 그룹 이번달/다음달/등급을 올리려면 조회
        console.log('## : 멤버십/패밀리 서비스 조회/설정')
        this.retrieveGrdRlrslt(2, false)
      }
    },
    // 채널별 등급,실적 데이터 설정
    setChanlGradeInfo(data, index) {
      // console.log('###### setChanlGradeInfo(' + data.length + ',' + index + ')')
      var chanlGradeInfos = []
      data.forEach((el) => {
        if ('E' != el.chanlGbnCd) {
          var chanlGrade = {}
          var chanlArr = {}
          chanlGrade.chanlGbnCd = el.chanlGbnCd //채널구분코드
          // chanlGrade.ordAmt3Mth = el.ordAmt3Mth //3개월 주문금액
          // chanlGrade.ordAmt6Mth = el.ordAmt6Mth //6개월 주문금액
          chanlGrade.ordAmt = this.$toCurrency('H' === el.chanlGbnCd ? el.ordAmt6Mth : el.ordAmt3Mth, '0') //채널별 노출 주문금액
          chanlGrade.ordCnt = el.ordCnt //방문,주문건수

          // 채널별 공통 정보 설정
          chanlArr = this.getChanlArr(el.chanlGbnCd)
          // console.log(chanlArr)
          chanlGrade.code = chanlArr.code
          if (!this.isGshExpos) {
            chanlGrade.chanlLogo = chanlArr.img
            console.log('#### 이전 로고 설정 : ' + chanlGrade.chanlLogo)
          } else {
            chanlGrade.chanlLogo = chanlArr.img2
            console.log('#### 신규 로고 설정 : ' + chanlGrade.chanlLogo)
          }
          chanlGrade.chanlNm = chanlArr.chanlNm
          chanlGrade.sort = chanlArr.sort
          chanlGrade.dayOrNum = chanlArr.dayOrNum
          chanlGrade.andOr = chanlArr.andOr
          chanlGrade.month = chanlArr.month
          if (1 === index) {
            chanlGrade.period = chanlArr.period
          } else {
            chanlGrade.period = chanlArr.nxtPeriod
          }
          chanlGrade.gradeStandard = chanlArr.gradeStandard
          // 채널별 공통 정보 설정
          // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
          if (!this.isGshExpos) {
            if (1 === index) {
              if ('06' === el.custGrdCd || 'DA' === el.custGrdCd) {
                console.log('@@@@@ ' + index + ' 이번달 고객 등급(custGrdCd)     : ' + el.custGrdCd)
                if ('06' === el.custGrdCd) el.custGrdCd = '03'
                if ('DA' === el.custGrdCd) el.custGrdCd = 'DD'
                console.log('@@@@@ ' + index + ' 이번달 고객 등급(custGrdCd)     : ' + el.custGrdCd)
              }
            } else if (2 === index) {
              if ('06' === el.expctCustGrdCd || 'DA' === el.expctCustGrdCd) {
                console.log('@@@@@ ' + index + ' 다음달 고객 등급(expctCustGrdCd)     : ' + el.expctCustGrdCd)
                if ('06' === el.expctCustGrdCd) el.expctCustGrdCd = '03'
                if ('DA' === el.expctCustGrdCd) el.expctCustGrdCd = 'DD'
                console.log('@@@@@ ' + index + ' 다음달 고객 등급(expctCustGrdCd) : ' + el.expctCustGrdCd)
              }
            } else if (3 === index) {
              if ('06' === el.upCustGrdCd || 'DA' === el.upCustGrdCd) {
                console.log('@@@@@ ' + index + ' 등급업 고객 등급(upCustGrdCd) : ' + el.upCustGrdCd)
                if ('06' === el.upCustGrdCd) el.upCustGrdCd = '03'
                if ('DA' === el.upCustGrdCd) el.upCustGrdCd = 'DD'
                console.log('@@@@@ ' + index + ' 등급업 고객 등급(upCustGrdCd) : ' + el.upCustGrdCd)
              }
            }
          }
          // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]

          // 이번달, 다음달, 등급을 올리려면 등급 기준 설정
          switch (index) {
            case 1: // 이번달 등급
              // chanlGrade.custGrdCd = el.custGrdCd //이번달 고객등급코드
              chanlGrade.gradeIndex = this.getGradeArr(el.custGrdCd).grdIdx
              // chanlGrade.curGrdIdx = chanlGrade.gradeIndex
              chanlGrade.curGrdNm = this.getGradeArr(el.custGrdCd).grdNm //이번달 등급명

              // this.topStyle.display = 'block'
              break
            case 2: // 다음달 등급
              // chanlGrade.expctCustGrdCd = el.expctCustGrdCd //다음달 예상고객등급코드
              chanlGrade.gradeIndex = this.getGradeArr(el.expctCustGrdCd).grdIdx
              // chanlGrade.nxtGrdIdx = chanlGrade.gradeIndex
              chanlGrade.nxtGrdNm = this.getGradeArr(el.expctCustGrdCd).grdNm //다음달 등급명
              break
            case 3: // 등급을 올리려면
              // chanlGrade.expctCustGrdCd = el.expctCustGrdCd //다음달 예상고객등급코드
              // chanlGrade.upCustGrdCd = el.upCustGrdCd //등급을 올리려면 고객등급코드
              chanlGrade.gradeIndex = this.getGradeArr(el.upCustGrdCd).grdIdx
              // chanlGrade.updGrdIdx = chanlGrade.gradeIndex
              chanlGrade.updGrdNm = this.getGradeArr(el.upCustGrdCd).grdNm //등급을 올리려면 등급명
              chanlGrade.addNeedOrdAmt = el.addNeedOrdAmt //등급업 추가필요 주문금액
              chanlGrade.addNeedOrdCnt = el.addNeedOrdCnt //등급업 추가필요 방문,주문건수
              break
            default:
              break
          }

          // 접속 채널 기준 실적 정렬 변경 (접속 채널 상단)
          if (index < 3 && '2020' == this.chanlCd && chanlGrade.chanlGbnCd == 'E') {
            // console.log('## GS프래시몰 정렬 상위 설정')
            chanlGrade.sort = 0
          } else if (index < 3 && '3010' == this.chanlCd && chanlGrade.chanlGbnCd == 'H') {
            // console.log('## GS샵 정렬 상위 설정')
            chanlGrade.sort = 0
          } else if (index < 3 && '2010' == this.chanlCd) {
            // console.log('## 우동G 정렬 상위 설정')
          }

          // 채널별 실적(purchase) 그래프바 퍼세트 설정
          chanlGrade.percent = this.setPercent(chanlGrade.gradeIndex, el)

          // 채널별 실적(bar) 스타일 설정
          if (index == 2) chanlGrade.barStyle = '0%' //애니메이션 효과를 위해 0으로 설정
          else chanlGrade.barStyle = chanlGrade.percent + '%'
          // 합산 실적이 VIP 이하인 경우 상단에 노출되지 않도록 제거한다.
          // 모든 채널 WELCOME 시 합산 실적이 가장 높게 나오는 경우가 대다수임.
          if ('A' == chanlGrade.chanlGbnCd && 2 == index && chanlGrade.gradeIndex < 1) {
            console.log('@@@@@@ 합산 채널 제거 대상임. ')
          } else {
            if (1 === index && 'E' == chanlGrade.chanlGbnCd) {
              console.log('@@@@@@@ GS더프래시몰 이번달 실적/등급 제외 처리')
            } else if (2 === index && 'E' == chanlGrade.chanlGbnCd) {
              console.log('@@@@@@@ GS더프래시몰 다음달/등급업 제외 처리')
            } else {
              chanlGradeInfos.push(chanlGrade)
            }
          }
        } else {
          console.log('>>> GS프래시몰 제거')
        }
      })

      // 기본 정렬 적용
      chanlGradeInfos.sort((a, b) => a.sort - b.sort)

      return chanlGradeInfos
    },
    // 채널별 실적 그래프바 퍼세트 설정
    setPercent(index, el) {
      var percent = 0
      var amtPercent = 0
      var cntPercent = 0
      if (index > 3) {
        // console.log('채널 등급 VVIP : ' + chanlGrade.gradeIndex + ',' + el.ordAmt3Mth + ',' + el.ordCnt)
        percent = 100
      } else {
        if ('C' === el.chanlGbnCd) {
          // el.ordAmt3Mth = '5000'
          // el.ordCnt = '1'
          // console.log(index + ',' + el.ordAmt3Mth + ',' + el.ordCnt)
          amtPercent =
            Number(el.ordAmt3Mth) <= 300000
              ? ((Number(el.ordAmt3Mth) / 300000) * this.vipPercent).toFixed(1)
              : Number(el.ordAmt3Mth) < 600000
              ? (this.vipPercent + ((Number(el.ordAmt3Mth) - 300000) / 300000) * (100 - this.vipPercent)).toFixed(1)
              : this.vvipPercent
          cntPercent =
            Number(el.ordCnt) <= 30
              ? ((Number(el.ordCnt) / 30) * this.vipPercent).toFixed(1)
              : Number(el.ordCnt) < 60
              ? (this.vipPercent + ((Number(el.ordCnt) - 30) / 30) * (100 - this.vipPercent)).toFixed(1)
              : this.vvipPercent
          percent = amtPercent >= cntPercent ? amtPercent : cntPercent
          // percent = Math.trunc((amtPercent + cntPercent) / 2)
          // console.log('GS25 실적 퍼센트 계산 : 60일 또는 60만원, 퍼센트 : ' + percent)
          // console.log('GS25 실적 퍼센트 계산 : 30일 또는 30만원, 퍼센트 : ' + percent)
        } else if ('S' === el.chanlGbnCd) {
          // el.ordAmt3Mth = '300000'
          // el.ordCnt = '10'
          // console.log(index + ',' + el.ordAmt3Mth + ',' + el.ordCnt)
          amtPercent =
            el.ordAmt3Mth <= 600000
              ? ((el.ordAmt3Mth / 600000) * this.vipPercent).toFixed(1)
              : el.ordAmt3Mth < 900000
              ? (this.vipPercent + ((Number(el.ordAmt3Mth) - 600000) / 300000) * (100 - this.vipPercent)).toFixed(1)
              : this.vvipPercent
          cntPercent =
            el.ordCnt <= 18
              ? ((el.ordCnt / 18) * this.vipPercent).toFixed(1)
              : el.ordCnt < 25
              ? (this.vipPercent + ((Number(el.ordCnt) - 18) / 7) * (100 - this.vipPercent)).toFixed(1)
              : this.vvipPercent
          percent = amtPercent >= cntPercent ? amtPercent : cntPercent
          // percent = Math.trunc((amtPercent + cntPercent) / 2)
          // console.log('GS더프래시 실적 퍼센트 계산 : 25일 또는 90만원, 퍼센트 : ' + percent)
          // console.log('GS더프래시 실적 퍼센트 계산 : 18일 또는 60만원, 퍼센트 : ' + percent)
        } else if ('E' === el.chanlGbnCd) {
          // el.ordAmt3Mth = '200000'
          // el.ordCnt = '5'
          // console.log(index + ',' + el.ordAmt3Mth + ',' + el.ordCnt)
          amtPercent =
            el.ordCnt < 5 || el.ordAmt3Mth <= 200000
              ? (((el.ordAmt3Mth > 200000 ? 200000 : el.ordAmt3Mth) / 200000) * this.vipPercent).toFixed(1)
              : el.ordCnt < 10 || el.ordAmt3Mth < 400000
              ? (
                  this.vipPercent +
                  (((el.ordAmt3Mth > 400000 ? 400000 : el.ordAmt3Mth) - 200000) / 200000) * (100 - this.vipPercent)
                ).toFixed(1)
              : this.vvipPercent
          cntPercent =
            el.ordCnt <= 5 || el.ordAmt3Mth < 200000
              ? (((el.ordCnt > 5 ? 5 : el.ordCnt) / 5) * this.vipPercent).toFixed(1)
              : el.ordCnt < 10 || el.ordAmt3Mth < 400000
              ? (this.vipPercent + (((el.ordCnt > 10 ? 10 : el.ordCnt) - 5) / 5) * (100 - this.vipPercent)).toFixed(1)
              : this.vvipPercent
          // percent = amtPercent >= cntPercent ? amtPercent : cntPercent
          percent = ((parseFloat(amtPercent) + parseFloat(cntPercent)) / 2).toFixed(1)
          // console.log('GS프래시몰 실적 퍼센트 계산 : 10회 && 40만원, 퍼센트 : ' + percent)
          // console.log('GS프래시몰 실적 퍼센트 계산 : 5회 && 20만원, 퍼센트 : ' + percent)
        } else if ('H' === el.chanlGbnCd) {
          // el.ordAmt6Mth = '150000'
          // el.ordCnt = '10'
          // console.log(index + ',' + el.ordAmt6Mth + ',' + el.ordCnt)

          // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
          if (this.isGshExpos) {
            amtPercent =
              el.ordCnt < 1 || el.ordAmt6Mth <= 50000
                ? (((el.ordAmt6Mth > 50000 ? 50000 : el.ordAmt6Mth) / 50000) * this.goldPecent).toFixed(1)
                : el.ordCnt < 3 || el.ordAmt6Mth <= 100000
                ? (
                    this.goldPecent +
                    (((el.ordAmt6Mth > 100000 ? 100000 : el.ordAmt6Mth) - 50000) / 50000) *
                      (this.diamondPercent - this.goldPecent)
                  ).toFixed(1)
                : el.ordCnt < 5 || el.ordAmt6Mth <= 150000
                ? (
                    this.diamondPercent +
                    (((el.ordAmt6Mth > 150000 ? 150000 : el.ordAmt6Mth) - 100000) / 50000) *
                      (this.vipPercent - this.diamondPercent)
                  ).toFixed(1)
                : el.ordCnt < 10 || el.ordAmt6Mth < 200000
                ? (
                    this.vipPercent +
                    (((el.ordAmt6Mth > 200000 ? 200000 : el.ordAmt6Mth) - 150000) / 50000) * (100 - this.vipPercent)
                  ).toFixed(1)
                : this.vvipPercent
            cntPercent =
              el.ordCnt <= 1 || el.ordAmt6Mth < 50000
                ? (((el.ordCnt > 1 ? 1 : el.ordCnt) / 1) * this.goldPecent).toFixed(1)
                : el.ordCnt <= 3 || el.ordAmt6Mth < 100000
                ? (
                    this.goldPecent +
                    (((el.ordCnt > 3 ? 3 : el.ordCnt) - 1) / 2) * (this.diamondPercent - this.goldPecent)
                  ).toFixed(1)
                : el.ordCnt <= 5 || el.ordAmt6Mth < 150000
                ? (
                    this.diamondPercent +
                    (((el.ordCnt > 5 ? 5 : el.ordCnt) - 1) / 4) * (this.vipPercent - this.diamondPercent)
                  ).toFixed(1)
                : el.ordCnt < 10 || el.ordAmt6Mth < 200000
                ? (this.vipPercent + (((el.ordCnt > 10 ? 10 : el.ordCnt) - 5) / 5) * (100 - this.vipPercent)).toFixed(1)
                : this.vvipPercent
            // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
          } else {
            amtPercent =
              el.ordCnt < 1 || el.ordAmt6Mth <= 50000
                ? (((el.ordAmt6Mth > 50000 ? 50000 : el.ordAmt6Mth) / 50000) * this.goldPecent).toFixed(1)
                : el.ordCnt < 5 || el.ordAmt6Mth <= 150000
                ? (
                    this.goldPecent +
                    (((el.ordAmt6Mth > 150000 ? 150000 : el.ordAmt6Mth) - 50000) / 100000) *
                      (this.vipPercent - this.goldPecent)
                  ).toFixed(1)
                : el.ordCnt < 10 || el.ordAmt6Mth < 200000
                ? (
                    this.vipPercent +
                    (((el.ordAmt6Mth > 200000 ? 200000 : el.ordAmt6Mth) - 150000) / 50000) * (100 - this.vipPercent)
                  ).toFixed(1)
                : this.vvipPercent
            cntPercent =
              el.ordCnt <= 1 || el.ordAmt6Mth < 50000
                ? (((el.ordCnt > 1 ? 1 : el.ordCnt) / 1) * this.goldPecent).toFixed(1)
                : el.ordCnt <= 5 || el.ordAmt6Mth < 150000
                ? (
                    this.goldPecent +
                    (((el.ordCnt > 5 ? 5 : el.ordCnt) - 1) / 4) * (this.vipPercent - this.goldPecent)
                  ).toFixed(1)
                : el.ordCnt < 10 || el.ordAmt6Mth < 200000
                ? (this.vipPercent + (((el.ordCnt > 10 ? 10 : el.ordCnt) - 5) / 5) * (100 - this.vipPercent)).toFixed(1)
                : this.vvipPercent
          }
          // percent = amtPercent >= cntPercent ? amtPercent : cntPercent
          percent = ((parseFloat(amtPercent) + parseFloat(cntPercent)) / 2).toFixed(1)
          // console.log('GS샵 실적 퍼센트 계산 : 10회 && 20만원, 퍼센트 : ' + percent)
          // console.log('GS샵 실적 퍼센트 계산 : 5회 && 15만원, 퍼센트 : ' + percent)
          // console.log('GS샵 실적 퍼센트 계산 : 3회 && 10만원, 퍼센트 : ' + percent)
          // console.log('GS샵 실적 퍼센트 계산 : 1회 && 5만원, 퍼센트 : ' + percent)
        } else if ('A' === el.chanlGbnCd) {
          // el.ordAmt3Mth = '600000'
          // el.ordCnt = '4'
          // console.log(index + ',' + el.ordAmt3Mth + ',' + el.ordCnt)
          amtPercent =
            el.ordCnt < 2 || el.ordAmt3Mth <= 600000
              ? (((el.ordAmt3Mth > 600000 ? 600000 : el.ordAmt3Mth) / 600000) * this.vipPercent).toFixed(1)
              : el.ordCnt < 2 || el.ordAmt3Mth < 900000
              ? (
                  this.vipPercent +
                  (((el.ordAmt3Mth > 900000 ? 900000 : el.ordAmt3Mth) - 600000) / 300000) * (100 - this.vipPercent)
                ).toFixed(1)
              : this.vvipPercent
          cntPercent =
            el.ordCnt <= 2 || el.ordAmt3Mth < 600000
              ? (((el.ordCnt > 2 ? 2 : el.ordCnt) / 2) * this.vipPercent).toFixed(1)
              : el.ordCnt < 2 || el.ordAmt3Mth < 900000
              ? (this.vipPercent + (((el.ordCnt > 4 ? 4 : el.ordCnt) - 2) / 2) * (100 - this.vipPercent)).toFixed(1)
              : this.vvipPercent
          // percent = amtPercent >= cntPercent ? amtPercent : cntPercent
          percent = ((parseFloat(amtPercent) + parseFloat(cntPercent)) / 2).toFixed(1)
          // console.log('합산실적 퍼센트 계산 : 2채널 && 90만원, 퍼센트 : ' + percent)
          // console.log('합산실적 퍼센트 계산 : 2채널 && 60만원, 퍼센트 : ' + percent)
        }
        // console.log('>>>> ' + this.getChanlArr(el.chanlGbnCd).chanlNm + '[' + el.chanlGbnCd + '] 금액비율 : ' + amtPercent + ', 횟수비율 : ' + cntPercent + ' = 결과 : ' + percent)
      }
      return percent
    },

    //------------------ API 호출
    // F014 : retrieveChFamyGrpAdmYn : 패밀리 그룹 가입 여부 조회 API
    async retrieveChFamyGrpAdmYn() {
      console.log('')
      console.log('#### methods : 패밀리 그룹여부[F014 : retrieveChFamyGrpAdmYn] str ####')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChFamyGrpAdmYn'
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/retrieveChFamyGrpAdmYn', bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          if (!this.$isEmpty(resData)) {
            console.log('## 패밀리 그룹 가입 여부 : ' + resData.famyGrpAdmYn)

            this.isFamily = resData.famyGrpAdmYn == 'Y' ? true : false
            this.custGradeInfo.intgMemNo = resData.intgMemNo //고객번호
            this.custGradeInfo.famyGrpAdmYn = resData.famyGrpAdmYn //패밀리그룹가입여부(등급적용 패밀리)
            // - GS프래시몰 등급조정(고객)대상 여부
            //   등급조정대상인 경우 : 상단 안내 툴팁 노출 처리
            console.log('## 등급조정대상 여부 : ' + resData.grdModTgtYn)
            this.custGradeInfo.isGrdModTgt =
              !this.$isEmpty(resData.grdModTgtYn) && 'Y' == resData.grdModTgtYn ? true : false //GS프래시몰 등급조정대상(고객)여부
            // - 다음달/등급업 적용월 설정
            this.custGradeInfo.grdApplyFlwMm = this.$dayjs().add(1, 'month').format('M') //다음달/등급업(기본 당월 + 1) 적용월 설정
            this.familyInfo.exposFamyGrpId = resData.exposFamyGrpId //이전 패밀리그룹ID
            this.familyInfo.exposFamyGrpNm = resData.exposFamyGrpNm //이전 노출패밀리그룹명
            this.familyInfo.famyGrpId = resData.famyGrpId //현재 패밀리그룹ID
            this.familyInfo.famyGrpNm = resData.famyGrpNm //현재 패밀리그룹명
            this.familyInfo.famyGrpMberCnt = resData.famyGrpMberCnt //패밀리그룹멤버수
            this.familyInfo.famyGrpWthdrwDtm = resData.famyGrpWthdrwDtm //이전패밀릭룹탈퇴일시

            // 패밀리 탈퇴/재가입 여부 설정
            if (this.isFamily) {
              // 패밀리 탈퇴 : 패밀리가입여부('Y'), 노출패밀리그룹ID(존재), 패밀리그룹ID(없음)
              if (this.$isEmpty(resData.famyGrpId)) {
                console.log('>>> 패밀리 그룹 탈퇴 상태')
                this.custGradeInfo.fmlyJoinType = 'exit' //패밀리 탈퇴 후 미가입 고객
              } else if (resData.exposFamyGrpId != resData.famyGrpId) {
                console.log('>>> 패밀리 그룹 재가입 상태')
                this.custGradeInfo.fmlyJoinType = 'rejoin'
              } else {
                console.log('>>> 현 (신규)가입 패밀리/멤버십 등급 노출/조회 설정')
                this.custGradeInfo.fmlyJoinType = 'new' //(신규/기존)패밀리 고객
              }

              // 패밀리 탈퇴/재가입 경우 이전 패밀리 등급 적용월을 설정한다.
              if ('new' != this.custGradeInfo.fmlyJoinType) {
                // 패밀리 탈퇴 후 재가입 고객 노출 설정 : 퇄퇴 일자, 조회 일자 비교
                // 탈퇴월 == 조회월 경우 '새로운 패밀리 가기' 노출
                console.log(
                  '## 이전 패밀리 탈퇴(재가입)시 이전 패밀리 등급 적용월 계산.... fmlyJoinType : ' +
                    this.custGradeInfo.fmlyJoinType +
                    ', famyGrpWthdrwDtm : ' +
                    this.familyInfo.famyGrpWthdrwDtm
                )
                // <!-- API 다음달, 등급업 정보 검토 결과에 따라 수정 필요 -->
                //     접속 고객번호 기준 탈퇴일 기준 패밀리 등급 적용 달 설정 필요.
                //     가입일 26일 이전인지 이후인지에 따라 다음달, 다다음달 패밀리 등급 적용 설정
                if (!this.$isEmpty(this.familyInfo.famyGrpWthdrwDtm)) {
                  console.log('## 이전 패밀리 탈퇴 일시가 존재하는 경우 이전패밀리 등급 적용월 계산..')
                  var drwM = this.$dayjs(resData.famyGrpWthdrwDtm).format('YYYYMMDD')
                  var stdM = this.$dayjs().format('YYYYMM') + '26'
                  if (drwM < stdM) {
                    console.log('>> 이전 패밀리 탈퇴일이 26일 이전 이면 적용월은 이번달까지..')
                    this.custGradeInfo.grdApplyMm = this.$dayjs().format('M')
                  } else {
                    console.log('>> 이전 패밀리 탈퇴일이 26일 이후 이면 적용월은 다음달까지..')
                    this.custGradeInfo.grdApplyMm = this.$dayjs().add(1, 'month').format('M')
                    // - 다음달/등급업 적용월 설정
                    // 탈퇴일이 26일 이후면 다음달/등급업 적용 월은 당월 + 2
                    this.custGradeInfo.grdApplyFlwMm = this.$dayjs().add(2, 'month').format('M')
                  }
                } else {
                  console.log('## 이전 패밀리 탈퇴 일시가 없는 경우 이전패밀리 등급 적용월 패쑤..')
                }
                // <!-- //API 다음달, 등급업 정보 검토 결과에 따라 수정 필요 -->
              }
            }
          }

          //패밀리 그룹 여부 조회 후 결과를 이용하여 멤버십/패밀리 서비스 조회 한다.
          this.getCustGradeInfo()
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert(e.response.data.resCd, e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      // console.log('#### methods : 패밀리 그룹여부[F014 : retrieveChFamyGrpAdmYn] end ####')
    },
    // F003 : retrieveChFamyGrpInfoDtl : 패밀리 그룹 정보 및 구성원 조회 API
    async retrieveChFamyGrpInfoDtl(famyGrpId) {
      console.log('')
      console.log('#### methods : 패밀리그룹 상세[F003 : retrieveChFamyGrpInfoDtl] str ####')

      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChFamyGrpInfoDtl',
        famyGrpId: famyGrpId // 탈퇴 하고 내 서비스 보기 클릭하여 왔으면 현 패밀리 번호로 조회
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/retrieveChFamyGrpInfoDtl', bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          if (null != res && null != resData) {
            console.log('## 패밀리그룹 상세 조회 결과')

            this.familyInfo.famyGrpId = resData.famyGrpId
            // 패밀리그룹 초대/수락 이슈 : 24.06.11
            this.familyInfo.encFamyGrpId = !this.$isEmpty(resData.famyInvitEncKey) ? resData.famyInvitEncKey : ''
            console.log('@@ this.familyInfo.encFamyGrpId : ' + this.familyInfo.encFamyGrpId)

            this.familyInfo.famyGrpNm = resData.famyGrpNm
            // 패밀리 생성 후 실적 미적용 여부는 아래 정보로?
            this.familyInfo.famyGrdApplyYn = resData.famyGrdApplyYn //패밀리 등급 적용 여부
            this.familyInfo.invitCnt = resData.invitCnt
            this.familyInfo.openDt = resData.openDt
            // - GS프래시몰 등급조정(고객)대상 여부
            //   등급조정대상인 경우 : 상단 안내 툴팁 노출 처리
            console.log('## 패밀리그룹 멤버 등급조정대상 여부 : ' + resData.grdModTgtYn)
            if (!this.custGradeInfo.isGrdModTgt) {
              this.custGradeInfo.isGrdModTgt =
                !this.$isEmpty(resData.grdModTgtYn) && 'Y' == resData.grdModTgtYn ? true : false //GS프래시몰 등급조정대상(고객)여부
            }

            if (!this.isNewFamilyGrp) {
              console.log('>>>>>>>>>>>>>>>>>> 이전/유지 패밀리 정보를 보러옴.')
              if (!this.isNxtMm && 'N' == resData.famyGrdApplyYn) {
                this.isNxtMm = true
              }
            } else {
              console.log('>>>>>>>>>>>>>>>>>> 신규 가입 패밀리 정보를 보러옴.')
            }
            // 새로운 패밀리 그룹 보기인 경우 기존 패밀리 멤버 리스트 삭제
            // 새로운 패밀리 명으로 노출 처리
            if (this.isNewFamilyGrp) {
              this.familyInfo.familyMems.length = 0
            }

            // 패밀리 그룹 멤버 정보 설정 및 가입일시에 따른 노출 문구(일자) 설정
            if (!this.$isEmpty(resData.familyMemberList)) {
              console.log('## 패밀리 그룹 멤버 설정(활성멤버)')
              resData.familyMemberList.forEach((el) => {
                // 활성화 멤버 추출
                if (el.famyGrpMemStCd == 'Y') {
                  this.familyInfo.familyMems.push({
                    intgMemNo: el.intgMemNo,
                    custNm: el.custNm,
                    // lastCustGrdCd: el.lastCustGrdCd
                    admDtm: el.admDtm,
                    // admBnftGivYn: el.admBnftGivYn
                    // currFamyGrpId: el.currFamyGrpId
                    sort: el.intgMemNo == this.custGradeInfo.intgMemNo ? 0 : 1
                  })
                }
                this.familyInfo.familyMems.sort((a, b) => a.sort - b.sort)

                // 패밀리그룹 등급 적용이 안되는 고객인 경우
                //   가입일시 26일 이후인 경우 다다음달 등급 적용
                //   가입일시 25일 이전인 경우 다음달 등급 적용
                if ('N' === resData.famyGrdApplyYn && this.custGradeInfo.intgMemNo == el.intgMemNo) {
                  console.log(
                    '>>> 접속 고객 가입일시 비교 >> 가입일시 : ' +
                      el.admDtm +
                      ', 가입일 변환 : ' +
                      this.$dayjs(el.admDtm).format('YYYYMMDD') +
                      ', 가입일 비교기준 : ' +
                      this.$dayjs().format('YYYYMM') +
                      '26'
                  )
                  console.log('## 신규 패밀리 가입(생성) 고객 등급 적용 월 설정')
                  if (this.$dayjs(el.admDtm).format('YYYYMMDD') >= this.$dayjs().format('YYYYMM') + '26') {
                    // console.log('>>>>>>>> 다다음달 등급 적용 안내 설정')
                    this.custGradeInfo.grdApplyMm = this.$dayjs().add(2, 'month').format('M')
                    // - 다음달/등급업 적용월 설정
                    this.custGradeInfo.grdApplyFlwMm = this.$dayjs().add(2, 'month').format('M')
                  } else {
                    // console.log('>>>>>>>> 다음달 등급 적용 안내 설정')
                    this.custGradeInfo.grdApplyMm = this.$dayjs().add(1, 'month').format('M')
                  }
                } else {
                  console.log('>>>> 패밀리 그룹 등급 적용 고객....')
                }
              })
              console.log('## 패밀리 그룹 멤버 설정(활성멤버)')
            }

            if (this.isNewFamilyGrp && !this.isNxtMm) {
              console.log('### 새로운 패밀리 그룹 보기 경우 멤버십 개인 등급/실적 활성화, 신규 그룹 멤버로 변경')
              this.tabToggle('mem')
            } else if (this.isNewFamilyGrp && this.isNxtMm) {
              // - 26일 탈퇴 후 익월 조회 시 신규 그룹, 개인 멤버십 조회 처리
              // CASE1 : 패밀리 탈퇴 > 재가입 경우 > 신규 그룹 정보 조회
              // CASE2 : 패밀리 탈퇴 > 미가입 경우 > 개인 멤버십 정보 조회 (강제 설정 필요)
              console.log('### 탈퇴(재가입) 후 익월 조회 케이스 재설정 필요 ')
              this.retrieveGrdRlrslt(2, false)
            } else if (!this.isNewFamilyGrp && this.isNxtMm) {
              console.log('XXXXXXXXXXX 오류 당...~~~~~~~~~~~~~~~~')
              console.log(
                '### 패밀리 신규 가입 후 패밀리 등급 미적용인 경우 신규 패밀리 그룹 실적/등급 조회하도록 처리해야 한다.'
              )
              this.retrieveGrdRlrslt(2, false)
            } else if ('new' == this.custGradeInfo.fmlyJoinType) {
              // 패밀리 신규가입/유지 고객 이번달/다음달/등급업 조회
              console.log('### 패밀리 유지(신규가입) 고객 이번달/다음달/등급업 조회')
              this.retrieveGrdRlrslt(2, false)
            } else {
              // 패밀리 탈퇴(재가입) 고객 이전 패밀리 등급 경우 이번달 조회
              console.log('### 패밀리 탈퇴(재가입) 고객 이전 패밀리 등급 경우 이번달 조회')
              this.retrieveGrdRlrslt(1, false)
            }
            // this.familyInfo.familyMems = resData.console.log(resData)
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert(e.response.data.resCd, e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })

      // console.log('#### methods : 패밀리그룹 상세[F003 : retrieveChFamyGrpInfoDtl]end ####')
    },
    // G001 : retrieveChThemmGrdRlrslt : 이번달 고객 등급 실적 조회 API
    // G002 : retrieveChNxtmmGrdRlrslt : 다음달 고객 등급 실적 조회 API
    // G003 : retrieveChNxtmmGrdUpNeedRlrslt : 등급을 올리려면 고객 등급 실적 조회 API
    async retrieveGrdRlrslt(index, isTab) {
      console.log('')
      console.log(
        '#### methods : ' +
          (this.isNxtMm ? '익월 ' : '') +
          (this.isFamily ? '패밀리 ' : '멤버십 ') +
          (1 === index ? '이번달 등급 ' : 2 === index ? '다음달 등급 ' : '등급을 올리려면? ') +
          '서비스[' +
          (1 === index
            ? 'G001 : retrieveChThemmGrdRlrslt'
            : 2 === index
            ? 'G002 : retrieveChNxtmmGrdRlrslt'
            : 'G003 : retrieveChNxtmmGrdUpNeedRlrslt') +
          '] str #### index : ' +
          index
      )
      let selApiId = 'retrieveChThemmGrdRlrslt' //이번달 멤버십 등급
      if (index == 2) {
        selApiId = 'retrieveChNxtmmGrdRlrslt' //다음달 멤버십 등급
      } else if (index == 3) {
        selApiId = 'retrieveChNxtmmGrdUpNeedRlrslt' //등급을 올리려면 멤버십 등급
      }
      let callApiUrl = '/' + selApiId
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: selApiId,
        stdYm: this.$dayjs().format('YYYYMM'), //기준 년월
        memGbnCd: isTab ? 'I' : this.isFamily ? 'F' : 'I' //통합(개인)
      }

      // 패밀리 이번달 등급 API 신규그룹 조회 기능 수정
      // if (this.isFamily && this.isNxtMm && 1 != index) {
      if (this.isFamily && 'F' == bodyParam.memGbnCd && this.isNxtMm) {
        bodyParam.famyGrpGbnCd = 'A' // 패밀리 신규그룹생성(재가입)이고 익월 1일 이후 신규 패밀리 그룹 볼때
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post(callApiUrl, bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          // console.log(resData)
          if (null != res && null != resData) {
            // console.log(resData)
            if (!this.$isEmpty(resData.chanlGradeInfoList)) {
              // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
              if (!this.isGshExpos) {
                if (1 === index) {
                  if ('06' === resData.lastCustGrdCd || 'DA' === resData.lastCustGrdCd) {
                    console.log('@@@@@ ' + index + ' 이번달 고객 등급(lastCustGrdCd) : ' + resData.lastCustGrdCd)
                    if ('06' === resData.lastCustGrdCd) resData.lastCustGrdCd = '03'
                    if ('DA' === resData.lastCustGrdCd) resData.lastCustGrdCd = 'DD'
                    console.log('@@@@@ ' + index + ' 이번달 고객 등급(lastCustGrdCd) : ' + resData.lastCustGrdCd)
                  }
                } else if (2 === index) {
                  if ('06' === resData.expctLastCustGrdCd || 'DA' === resData.expctLastCustGrdCd) {
                    console.log(
                      '@@@@@ ' + index + ' 다음달 고객 등급(expctLastCustGrdCd) : ' + resData.expctLastCustGrdCd
                    )
                    if ('06' === resData.expctLastCustGrdCd) resData.expctLastCustGrdCd = '03'
                    if ('DA' === resData.expctLastCustGrdCd) resData.expctLastCustGrdCd = 'DD'
                    console.log(
                      '@@@@@ ' + index + ' 다음달 고객 등급(expctLastCustGrdCd) : ' + resData.expctLastCustGrdCd
                    )
                  }
                } else if (3 === index) {
                  // console.log('@@@@@ ' + index + ' 등급업 고객 등급(upCustGrdCd) : ' + resData.upCustGrdCd)
                }
              }
              // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]

              switch (index) {
                case 1:
                  if (!isTab) {
                    console.log('## 이번달 패밀리/멤버십 고객등급 정보 설정')
                    this.custGradeInfo.custNm = null != resData.custNm && '' != resData.custNm ? resData.custNm : '고객'
                    this.custGradeInfo.isCurEntprGrdExpos = 'Y' === resData.entprGrdExposYn ? true : false
                    this.custGradeInfo.curGrdIdx = this.getGradeArr(resData.lastCustGrdCd).grdIdx //이번달 고객 등급 index
                    this.custGradeInfo.curGrdNm = this.getGradeArr(resData.lastCustGrdCd).grdNm //이번달 고객 등급명

                    console.log(
                      '@@@@@@ 이번달 패밀리/멤버십 등급 노출여부 resData.entprGrdExposYn[' +
                        resData.entprGrdExposYn +
                        '], this.custGradeInfo.isCurEntprGrdExpos[' +
                        this.custGradeInfo.isCurEntprGrdExpos +
                        ']'
                    )

                    // 멤버십 고객 등급 미산정인 경우 적용 월 설정
                    if (!this.isFamily && !this.custGradeInfo.isCurEntprGrdExpos) {
                      console.log('>> 패밀리/멤버십 고객 등급 미산정인 경우 적용 월 설정')
                      this.custGradeInfo.grdApplyMm = this.$dayjs().add(1, 'month').format('M')
                    }

                    console.log('## 이번달 패밀리/멤버십 등급 채널별 실적, 등급')
                    this.curChanlGradeInfos = this.setChanlGradeInfo(resData.chanlGradeInfoList, index)
                  } else {
                    console.log('## 패밀리 그룹 : Tab 멤버십 이번달 등급 채널별 실적, 등급')
                    this.custGradeInfo.custNm = null != resData.custNm && '' != resData.custNm ? resData.custNm : '고객'
                    this.curMemChanlGradeInfos = this.setChanlGradeInfo(resData.chanlGradeInfoList, index)
                  }
                  // console.log('## 이번달 등급 채널별 실적, 등급')
                  // console.log(this.curChanlGradeInfos)
                  break
                case 2:
                  // 등급 정보 설정
                  this.custGradeInfo.isNxtEntprGrdExpos = 'Y' === resData.entprGrdExposYn ? true : false //등급노출여부(무조건Y같은디....)
                  console.log(
                    '>> 다음달 패밀리/멤버십 등급 노출여부 isNxtEntprGrdExpos : ' +
                      this.custGradeInfo.isNxtEntprGrdExpos
                  )
                  // this.nextExpctCustGrdCd = resData.expctCustGrdCd
                  // this.nextGradeIndex = this.getGradeArr(resData.expctLastCustGrdCd).grdIdx //다음달 고객등급 Index
                  // this.nextGradeNm = this.getGradeArr(resData.expctLastCustGrdCd).grdNm //다음달 고객등급명
                  // 다음달 채널 실적,등급 정보 설정
                  console.log('## 다음달 패밀리/멤버십 등급 정보 설정')
                  this.custGradeInfo.nxtGrdIdx = this.getGradeArr(resData.expctLastCustGrdCd).grdIdx //다음달 고객등급 Index
                  this.custGradeInfo.nxtGrdNm = this.getGradeArr(resData.expctLastCustGrdCd).grdNm //다음달 고객등급명

                  console.log('## 다음달 패밀리/멤버십 등급 채널별 실적, 등급')
                  this.topChanlGradeInfos = this.setChanlGradeInfo(resData.chanlGradeInfoList, index)
                  this.topChanlGradeInfos.sort((a, b) => b.percent - a.percent)
                  // console.log('## 상단 최고등급 채널별 실적, 등급')

                  if (
                    Number(this.topChanlGradeInfos[0].percent) > 0 &&
                    this.topChanlGradeInfos[0].percent == this.topChanlGradeInfos[1].percent
                  ) {
                    this.topChanlGradeInfos.length = 2
                  } else {
                    if (Number(this.topChanlGradeInfos[0].percent) == 0) {
                      // this.custGradeInfo.isNxtPurchase = false //다음달 실적 없음
                    }
                    this.topChanlGradeInfos.length = 1
                  }

                  console.log(
                    '@@@@@@ 다음달 실적 체크 및 등급 노출여부 resData.entprGrdExposYn[' +
                      resData.entprGrdExposYn +
                      '], this.custGradeInfo.isNxtPurchase[' +
                      this.custGradeInfo.isNxtPurchase +
                      ']'
                  )
                  break
                case 3:
                  console.log('## 등급을 올리려면 패밀리/멤버십 등급 정보 설정')
                  console.log('## 등급을 올리려면 패밀리/멤버십 등급 채널별 실적, 등급')
                  this.updChanlGradeInfos = this.setChanlGradeInfo(resData.chanlGradeInfoList, index)
                  // console.log('## 등급을 올리려면 등급 채널별 실적, 등급')

                  this.topChanlGradeInfos.forEach((e, i) => {
                    let updChanlGrdeInfo = this.updChanlGradeInfos.find((el) => el.chanlGbnCd == e.chanlGbnCd)
                    e.addNeedOrdAmt = this.$toCurrency(updChanlGrdeInfo.addNeedOrdAmt, '0')
                    e.addNeedOrdCnt = this.$toCurrency(updChanlGrdeInfo.addNeedOrdCnt, '0')
                    // e.upGradeCd = updChanlGrdeInfo.upCustGrdCd
                    // e.upGradeIndex = this.getGradeArr(updChanlGrdeInfo.upCustGrdCd).grdIdx
                    e.updGrdNm = updChanlGrdeInfo.updGrdNm
                  })
                  // console.log(JSON.stringify(this.topChanlGradeInfos))
                  break
                default:
                  console.log('default')
                  break
              }
              console.log('')
            }

            if (index == 1 && !isTab) {
              // 패밀리/멤버십 이번달 API 조회
              console.log('>> 패밀리/멤버십 이번달 등급 조회(완료 : 화면 노출 설정)')
              this.setTopGradeStyle()

              return
            } else if (index == 1 && isTab) {
              // (패밀리멤버 Tab)멤버십 이번달 API 조회
              console.log('>> 패밀리/멤버십 Tab 멤버십 이번달 등급 조회(완료 : 화면 노출 설정)')
              // 채널실적 스켈레톤 적용
              this.isChanlSkeleton = false
              // 신규 패밀리 그룹에 가입한 고객인 경우(그룹 등급 미적용 고객)
              // 노출 처리를 한다.
              if (this.isNewFamilyGrp) {
                this.setTopGradeStyle()
              }
              return
            } else if (index == 2) {
              // 패밀리/멤버십 다음달 API 조회
              console.log('>> 다음달 조회 후 등급을 올리려면 조회.')
              if (this.custGradeInfo.nxtGrdIdx > this.vipIndex) {
                console.log('>> 다음달 예상등급이 VVIP인 경우 업하려면 조회를 패스하고 이번달 조회를 한다.')
                this.retrieveGrdRlrslt(1, false)
              } else {
                this.retrieveGrdRlrslt(3, false)
              }
            } else if (index == 3) {
              // 패밀리/멤버십 등급을 올리려면 API 조회
              console.log('>> 등급을 올리려면 조회 후 이번달 조회.')
              this.retrieveGrdRlrslt(1, false)
              return
            }
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert(e.response.data.resCd, e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      // console.log( '#### methods : ' + (this.isNxtMm ? ' 익월 ' : '') + (this.isFamily ? '패밀리' : '멤버십') + ' 서비스[G001 : retrieveGrdRlrslt] end #### index : ' + index)
    },
    // F002 : addChFamyGrpOpenInfo : 패밀리 그룹 오픈 정보 등록 API
    async addChFamyGrpOpenInfo(rename) {
      console.log('')
      console.log('#### methods : 패밀리 그룹 오픈 정보 등록[F002 : addChFamyGrpOpenInfo] str ####')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'addChFamyGrpOpenInfo',
        famyGrpId: this.familyInfo.famyGrpId, // 패밀리그룹ID  ID가 존재하는 경우는 수정, 패밀리그룹명만 가능하도록 처리
        famyGrpNm: rename // 패밀리그룹명
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/addChFamyGrpOpenInfo', bodyParam, {headers: headerParam})
        .then((res) => {
          // console.log(this.familyInfo)
          const resData = res.data
          if (null != res && null != resData && '0000' == resData.resCd) {
            // this.familyInfo.famyGrpNm = rename
            this.familyInfo.famyGrpNm = rename
            if (!this.isNewFamilyGrp) {
              this.familyInfo.exposFamyGrpNm = rename
            }
            //패밀리 명 변경 완료 알럿(예외철) 노출
            this.onAlert('0000', '패밀리 그룹 명 변경이 완료 되었습니다.')
          } else {
            console.log('## 패밀리 그룹 명 변경 처리 중 오류 발생')
            this.onAlert(resData.resCd, resData.resMsg)
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert(e.response.data.resCd, e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      // console.log('#### methods : 패밀리 그룹 오픈 정보 등록[F002 : addChFamyGrpOpenInfo] end ####')
    },

    // <!-- SNS 초대 기능 -->
    // 카카오톡 초대횟수 조회/수정 콜백함수.
    famyGrpInvitCnt(reqGbnCd) {
      if ('U' === reqGbnCd || 'S' === reqGbnCd) {
        // 앰플리튜드 추가
        if ('S' === reqGbnCd) this.tagging('CLICK-INTG-FMLY-GRD-INVITE')
        this.handleChFamyGrpInvitCnt(reqGbnCd)
      } else {
        console.log('@@@@ 요청구분코드 오류 : ' + reqGbnCd)
      }
    },
    // 카카오 초대 요청 및 횟수 증가 처리
    snsInvite() {
      console.log('## 카카오톡 공유 호출')
      console.log('## famyGrpId : ' + this.familyInfo.famyGrpId + ', encFamyGrpId : ' + this.familyInfo.encFamyGrpId)
      // 패밀리그룹 초대/수락 이슈 : 24.06.11
      if (!this.$isEmpty(this.familyInfo.famyGrpId) || !this.$isEmpty(this.familyInfo.encFamyGrpId)) {
        this.$refs.snsInvite.sendKakaoFeed()
        //생성 후 초대횟수 증가
        this.famyGrpInvitCnt('U')
      } else {
        console.log('@@@ 패밀리그룹ID 설정되어 있지 않음...')
      }
    },
    //패밀리 그룹 초대수 조회 및 갱신 API
    async handleChFamyGrpInvitCnt(reqGbnCd) {
      console.log('')
      console.log(
        '@@@@@ 패밀리 초대수 ' + ('U' === reqGbnCd ? '수정' : 'S' === reqGbnCd ? '조회' : '') + ' API 호출 시작'
      )
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'handleChFamyGrpInvitCnt',
        procGbnCd: reqGbnCd
      }

      if ('U' === reqGbnCd) {
        bodyParam.invitCnt = '1'
      } else {
        // 패밀리그룹 초대/수락 이슈 : 24.06.11
        bodyParam.famyInvitIntgMemNo = this.custGradeInfo.intgMemNo
        bodyParam.famyInvitEncKey = this.familyInfo.encFamyGrpId
        console.log('')
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        // bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
        bodyParam.famyGrpId = this.familyInfo.famyGrpId
      }

      await this.$axios
        .post('/handleChFamyGrpInvitCnt', bodyParam, {headers: headerParam})
        .then((res) => {
          var resData = res.data
          var invitCnt = 0
          console.log(
            '@@ resData : ' + resData + ', resData.resCd : ' + resData.resCd + ', resData.resMsg : ' + resData.resMsg
          )
          if (null != res && null != resData && '0000' === resData.resCd) {
            if ('S' === reqGbnCd) {
              // console.log('@@ resData.invitCnt : ' + resData.invitCnt + ' 카카오톡 공유 이동 필요')
              if (null != resData.invitCnt && '' != resData.invitCnt) {
                invitCnt = Number(resData.invitCnt) //초대수(누적)
              }
              console.log(
                '@@ resData.invitCnt : ' + resData.invitCnt + ' 카카오톡 공유 이동 필요, invitCnt : ' + invitCnt
              )

              if (invitCnt < 99) {
                this.snsInvite()
              } else {
                // 통신 성공이지만 오류결과 코드에대한 alert 고지 및 페이지 이동/닫기 처리 필요함.
                this.onAlert('0000', '패밀리 초대는 최대 99회만 가능합니다.(패밀리ID 기준)')
                // 통신 성공이지만 오류결과 코드에대한 alert 고지 및 페이지 이동/닫기 처리 필요함.
              }
            } else {
              console.log('@@ resData.invitCnt : ' + resData.invitCnt + ' 공유 횟수 증가 결과')
            }
          } else {
            console.log('## API 호출 resCd : ' + resData.resCd + ' resMsg : ' + resData.resMsg)
            // 횟수 조회/수정 시 통신 오류건은 무시한다. 다음 프로세스에 영향가지 않도록.
            //  > resCd : 4320 resMsg : 패밀리그룹ID이(가) 존재하지 않습니다.
            if ('4320' === resData.resCd) {
              this.onAlert(resData.resCd, resData.resMsg)
            }
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          // 횟수 조회/수정 시 통신 오류건은 무시한다. 다음 프로세스에 영향가지 않도록.

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            // this.onAlert('9999', e.response.data.resMsg)
          } else {
            // this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      // console.log('@@@@@ 패밀리 초대수 ' + ('U' === reqGbnCd ? '수정' : 'S' === reqGbnCd ? '조회' : '') + ' API 호출 종료')
    },
    // <!-- SNS 초대 기능 -->

    // 딥링크 채널 이동 처리
    goDeepLink(code, isPurchase) {
      console.log('>>> 딥링크 이동 처리 code : ' + code)
      var deeplink = ''
      var isClear = false
      this.isDelay = true
      //우동G, 더프래쉬
      if ('2010' == this.chanlCd) {
        if ('wdg' == code || 'gsf' == code) {
          deeplink = 'toapp://close'
          isClear = true
          this.isDelay = false
        } else if ('gsr' == code) {
          // 프래쉬 딥링크
          // app 딥링크 처리
          deeplink = 'toapp://browser?' + this.gsrDeeplink
        } else if ('gsh' == code) {
          // GS샵 딥링크
          // app 딥링크 처리
          deeplink = 'toapp://browser?' + this.gshDeeplink
        } else if ('gsh-vip' == code) {
          // GS샵 VIP딥링크
          // app 딥링크 처리
          deeplink = 'toapp://browser?' + this.gshVipDeeplink
        }
        //GS프래쉬몰
      } else if ('2020' == this.chanlCd) {
        var isFreshApp = !this.$isEmpty(window.flutter_inappwebview)
        if ('wdg' == code || 'gsf' == code) {
          // 우동G 딥링크
          deeplink = this.wdgDeeplink
          if (this.isApp || isFreshApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        } else if ('gsr' == code) {
          if (this.isApp || isFreshApp) {
            // deeplink = 'toapp://back'
            isClear = true
          }
          // else {
          deeplink = this.$store.state.apiStore.bodyParam.chanlMyDomain + process.env.VUE_APP_GSR_COUPON_URL
          // }
        } else if ('gsh' == code) {
          // GS샵 딥링크
          deeplink = this.gshDeeplink
          if (this.isApp || isFreshApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        } else if ('gsh-vip' == code) {
          // GS샵 VIP 딥링크
          deeplink = this.gshVipDeeplink
          if (this.isApp || isFreshApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        }
        //GS샵
      } else if ('3010' == this.chanlCd) {
        if ('wdg' == code || 'gsf' == code) {
          // 우동G 딥링크
          deeplink = this.wdgDeeplink
          if (this.isApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        } else if ('gsr' == code) {
          // 프래쉬 딥링크
          deeplink = this.gsrDeeplink
          if (this.isApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        } else if ('gsh' == code) {
          deeplink = this.$store.state.apiStore.bodyParam.chanlMyDomain + process.env.VUE_APP_GSH_MYSHOP_URL
          if (this.isApp) {
            deeplink = 'toapp://newpage?closeYn=Y&url=' + deeplink
            isClear = true
          }
        } else if ('gsh-vip' == code) {
          // GS샵 VIP 딥링크
          deeplink = this.$store.state.apiStore.bodyParam.chanlMyDomain + process.env.VUE_APP_GSH_VIP_URL
          if (this.isApp) {
            // app 딥링크 처리
            deeplink = 'toapp://newpage?closeYn=Y&url=' + deeplink
            isClear = true
          }
        }
      }

      if (this.isApp && this.isLink && isClear) {
        console.log('>>> chanlCd : ' + this.chanlCd + ', isApp[' + this.isApp + '] ==> sessionStorage.clear?')
      }
      console.log('>>> chanlCd : ' + this.chanlCd + ', isApp[' + this.isApp + '] ==>> ' + 'location.href = ' + deeplink)

      if (isClear) {
        sessionStorage.clear()
      }
      var tagId = ''
      if ('wdg' == code) {
        tagId = 'CLICK-INTG-' + (this.isFamily ? 'FMLY' : 'MEM') + '-' + (isPurchase ? 'PUR' : 'GRD') + '-C'
      } else if ('gsf' == code) {
        tagId = 'CLICK-INTG-' + (this.isFamily ? 'FMLY' : 'MEM') + '-' + (isPurchase ? 'PUR' : 'GRD') + '-S'
      } else if ('gsr' == code) {
        // tagId = 'CLICK-INTG-MEM-GRD-E'
      } else if ('gsh' == code) {
        tagId = 'CLICK-INTG-' + (this.isFamily ? 'FMLY' : 'MEM') + '-' + (isPurchase ? 'PUR' : 'GRD') + '-H'
      }
      console.log('>>> 이동 전 앰플리튜드 수집 호출 : ' + tagId + ', isDelay : ' + this.isDelay)
      // 앰플리튜드 추가
      this.tagging(tagId)
      if (this.isDelay) {
        console.log('>>> 채널 앱/웹 딥링크 이동 실행 : ' + tagId)
        location.href = deeplink
      } else {
        setTimeout(() => {
          console.log('>>> 우동G, 프래시몰 딥링크 지연 이동 실행 : ' + tagId)
          location.href = deeplink
        }, 1500)
      }
      console.log('##############################')

      return
    },
    // 앰플리튜드 추가
    tagging(tagId) {
      if (!this.$isEmpty(tagId)) {
        console.log('##### tagging : ' + tagId)
        this.$refs.tagManager.tagging(tagId)
        console.log('>>>>> tagging call end')
      } else {
        console.log('>>>>> tagId is null/empty')
      }
    }
  }
}
</script>
