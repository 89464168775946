import FamilyInviteWep from '@/views/family/FamilyInviteWep.vue'

const familyShipRouter = [
  {
    path: '/montblanc/fmly/familyAlready',
    redirect: '/montblanc/intg/familyAlready',
    props: true
  },
  {
    path: '/montblanc/fmly/familyExit',
    redirect: '/montblanc/intg/familyExit',
    props: true
  },
  {
    path: '/montblanc/fmly/familyGrade',
    redirect: '/montblanc/intg/memshpGrade'
  },
  {
    path: '/montblanc/fmly/familyGradeWithDraw',
    redirect: '/montblanc/intg/memshpGrade',
    props: true
  },
  {
    path: '/montblanc/fmly/familyInfo',
    redirect: '/montblanc/intg/familyInfo'
  },
  {
    path: '/montblanc/fmly/familyJoin',
    redirect: '/montblanc/intg/familyJoin'
  },
  {
    path: '/montblanc/fmly/familyName',
    redirect: '/montblanc/intg/familyName'
  },
  {
    path: '/montblanc/fmly/familyAcceptInvite/:famyGrpId',
    redirect: '/montblanc/intg/familyInvite/:famyGrpId'
  }
]

// - 개발모드 URL 설정
if ('development' === process.env.NODE_ENV) {
  familyShipRouter.push(
    {
      path: '/montblanc/fmly/familyInviteWep',
      name: 'FamilyInviteWep',
      component: FamilyInviteWep
    }
  )
}

export default familyShipRouter
