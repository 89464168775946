<template>
  <div class="membership-box">
    <div class="family-membership">
      <h3 class="fm-tit">패밀리 멤버를<br />초대해주세요</h3>
      <p class="fm-sub">혜택 공유할 멤버를 초대하세요(최대3명)</p>
      <div class="family-invite web-invite">
        <ul class="invite-ipt-box">
          <li class="invite-ipt-list" v-for="(memberList, index) in memberList" :key="index" :data-index="index">
            <p class="invite-ipt-tit fm-sub">패밀리 멤버</p>
            <div class="invite-ipt-cont">
              <input type="text" placeholder="이름" class="invite-ipt-name" v-model="memberList.name" />
              <input
                type="tel"
                placeholder="휴대폰번호(-없이)"
                class="invite-ipt-phone"
                maxlength="11"
                v-model="memberList.tel"
              />
              <button type="button" class="invite-ipt-delete" @click="onConfirm(index)">삭제</button>
            </div>
          </li>
        </ul>
        <div class="invite-web-btn">
          <button type="button" @click="memberAdd" v-if="memberList.length < 3">
            <span>연락처에서 멤버 초대하기</span>
          </button>
        </div>
      </div>
      <div class="b-btn-wrap fixed">
        <div>
          <button
            class="check"
            @click="onResult"
            :class="{loading: inviteLoad == true, disabled: memberList.length == 0}"
          >
            <span v-if="inviteLoad == false">메세지 보내기</span>
            <span v-if="inviteLoad"><span class="hidden">로딩</span></span>
          </button>
        </div>
      </div>
    </div>
    <Confirm
      msg="초대멤버를 삭제하시겠습니까?"
      :show="showConfirm"
      @confirmOk="confirmOk"
      @confirmCencel="confirmCencel"
    />
    <Alert :msg="alertMsg" :show="showAlert" @alertOk="alertOk" />
  </div>
</template>
<script>
import Alert from '@/components/Alert.vue'
import Confirm from '@/components/Confirm.vue'
export default {
  name: 'FamilyInviteWep',
  inject: ['sendTitle'],
  components: {Alert, Confirm},
  data() {
    return {
      title: '패밀리 서비스',
      showAlert: false,
      showConfirm: false,
      selectedIndex: null, // 삭제 버튼이 클릭된 인덱스 추가
      memberList: [],
      alertMsg: '',
      resultType: null,
      inviteLoad: false
    }
  },
  methods: {
    memberAdd() {
      if (this.memberList.length < 3) {
        this.memberList.push({name: '', tel: ''})
      }
    },
    onConfirm(index) {
      this.showConfirm = true
      this.selectedIndex = index
    },
    confirmCencel(boolean) {
      this.showConfirm = boolean

      this.selectedIndex = null
    },
    confirmOk(boolean) {
      this.showConfirm = boolean

      this.memberList.splice(this.selectedIndex, 1)
      this.selectedIndex = null
    },
    alertOk(boolean) {
      this.showAlert = boolean
      this.inviteLoad = boolean

      // Alert에 확인 버튼 클릭시 리턴 이벤트 설정
      switch (this.resultType) {
        case 1:
          break
        case 2:
          break
      }
    },
    onResult() {
      // 결과에 따른 메세지 및 리턴값 타입 설정
      this.showAlert = true
      if (this.memberList.length < 1) {
        this.alertMsg = '1개 이상의 연락처를 입력하세요.'
        this.resultType = 1
      } else {
        this.alertMsg = '초대 메세지가 발송되었습니다.'
        this.resultType = 2
        this.inviteLoad = true
      }
    }
  },
  mounted() {
    this.sendTitle(this.title)
  }
}
</script>
