<template><div v-show="false"></div></template>
<script>
export default {
  name: 'TagManager',
  data() {
    return {
      isApp:
        /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) ||
        '2010' === this.$store.state.apiStore.bodyParam.chanlCd,
      chanlId: this.$store.state.apiStore.bodyParam.chanlCd,
      ampkey: this.$store.state.apiStore.bodyParam.ampkey
    }
  },
  created() {
    // amplitude Script 수집
    // console.log('########### TagManager created s ###########')
    // console.log(this.$amplitude)
    // console.log('########### TagManager created e ###########')
  },
  methods: {
    tagging(tagId) {
      console.log('@@@@@ TagManager tagging str @@@@@')
      try {
        if ('3010' != this.chanlId && '2010' != this.chanlId && '2020' != this.chanlId) {
          return false
        }
        var eventId = ''
        var eventDtl = ''
        var action = ''
        switch (tagId) {
          // 통합멤버십 서비스
          case 'VIEW-MEM-GRD':
            eventId = 'VIEW-GS멤버십-통합회원등급페이지'
            eventDtl = '진입'
            action = ''
            break
          case 'CLICK-MEM-GRD-BNFT':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = 'GS멤버십_등급혜택_보기'
            action = 'GS멤버십 등급혜택 보기'
            break
          case 'CLICK-MEM-GRD-CURR':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = '이번_달_등급'
            action = '이번 달 등급'
            break
          case 'CLICK-MEM-GRD-NEXT':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = '다음_달_예상_등급'
            action = '다음 달 예상 등급'
            break
          case 'CLICK-MEM-GRD-UP':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = '등급을_올리려면'
            action = '등급을 올리려면'
            break
          case 'CLICK-MEM-GRD-C':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = 'GS25_실적'
            action = 'GS25 실적 상세'
            break
          case 'CLICK-MEM-GRD-S':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = 'GS더프레시_실적'
            action = 'GS더프레시 실적 상세'
            break
          case 'CLICK-MEM-GRD-E':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = 'GS프레시몰_실적'
            action = 'GS프레시몰 실적 상세'
            break
          case 'CLICK-MEM-GRD-H':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = 'GSSHOP_실적'
            action = 'GSSHOP 실적 상세'
            break
          case 'CLICK-MEM-GRD-A':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = '합산_실적'
            action = '합산 실적 상세'
            break
          // 통합멤버십 서비스
          // 패밀리서비스 생성
          case 'VIEW-FMLY-CRE':
            eventId = 'VIEW-패밀리서비스-패밀리생성페이지'
            eventDtl = '진입'
            action = ''
            break
          case 'CLICK-FMLY-CRE-MAKE':
            eventId = 'CLICK-패밀리서비스-패밀리생성페이지'
            eventDtl = '만들기'
            action = '만들기'
            break
          case 'CLICK-FMLY-CRE-AGREE':
            eventId = 'CLICK-패밀리서비스-패밀리생성페이지'
            eventDtl = '약관팝업_동의하기'
            action = '동의하기'
            break
          case 'CLICK-FMLY-CRE-INV':
            eventId = 'CLICK-패밀리서비스-패밀리생성페이지'
            eventDtl = '멤버초대하기'
            action = '멤버초대하기'
            break
          // 패밀리서비스 생성
          // 패밀리서비스 수락
          case 'VIEW-FMLY-INV':
            eventId = 'VIEW-패밀리서비스-패밀리수락페이지'
            eventDtl = '진입'
            action = ''
            break
          case 'CLICK-FMLY-INV':
            eventId = 'CLICK-패밀리서비스-패밀리수락페이지'
            eventDtl = '가입할게요'
            action = '가입할게요'
            break
          case 'CLICK-FMLY-INV-AGREE':
            eventId = 'CLICK-패밀리서비스-패밀리수락페이지'
            eventDtl = '동의하기'
            action = '동의하기'
            break
          case 'VIEW-FMLY-INV-CHK': // 23.04..14 추가 요청건
            eventId = 'VIEW-패밀리서비스-가입확인페이지'
            eventDtl = '진입'
            action = ''
            break
          case 'VIEW-FMLY-COMP':
            eventId = 'VIEW-패밀리서비스-가입완료팝업'
            eventDtl = '가입할게요_동의하기'
            action = ''
            break
          case 'VIEW-FMLY-COMP-OVER':
            eventId = 'VIEW-패밀리서비스-정원초과팝업'
            eventDtl = '가입할게요_동의하기'
            action = ''
            break
          // 패밀리서비스 수락
          // 패밀리서비스
          case 'VIEW-FMLY-GRD':
            eventId = 'VIEW-패밀리서비스-패밀리등급페이지'
            eventDtl = '진입'
            action = ''
            break
          case 'CLICK-FMLY-GRD-BNFT':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = 'GS멤버십_등급혜택_보기'
            action = 'GS멤버십 등급혜택 보기'
            break
          case 'CLICK-FMLY-GRD-ADD':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = '멤버추가하기'
            action = '멤버추가하기'
            break
          case 'CLICK-FMLY-GRD-CURR':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = '이번_달_등급'
            action = '이번 달 등급'
            break
          case 'CLICK-FMLY-GRD-NEXT':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = '다음_달_예상_등급'
            action = '다음 달 예상 등급'
            break
          case 'CLICK-FMLY-GRD-UP':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = '등급을_올리려면'
            action = '등급을 올리려면'
            break
          case 'CLICK-FMLY-GRD-C':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = 'GS25_실적'
            action = 'GS25 실적 상세'
            break
          case 'CLICK-FMLY-GRD-S':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = 'GS더프레시_실적'
            action = 'GS더프레시 실적 상세'
            break
          case 'CLICK-FMLY-GRD-E':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = 'GS프레시몰_실적'
            action = 'GS프레시몰 실적 상세'
            break
          case 'CLICK-FMLY-GRD-H':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = 'GSSHOP_실적'
            action = 'GSSHOP 실적 상세'
            break
          case 'CLICK-FMLY-GRD-A':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = '합산_실적'
            action = '합산 실적 상세'
            break
          case 'CLICK-FMLY-GRD-NEW':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = '신규_패밀리_만들기'
            action = '신규 패밀리 만들기'
            break
          case 'CLICK-FMLY-GRD-NEWGO':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = '나의_패밀리_서비스_가기'
            action = '나의 패밀리 서비스 가기'
            break
          case 'CLICK-FMLY-GRD-EXIT':
            eventId = 'CLICK-패밀리서비스-패밀리등급페이지'
            eventDtl = '패밀리_그룹_나가기'
            action = '패밀리 그룹 나가기'
            break
          // 패밀리서비스
          // 패밀리서비스 탈퇴
          case 'VIEW-FMLY-EXIT':
            eventId = 'VIEW-패밀리서비스-패밀리탈퇴페이지'
            eventDtl = '진입'
            action = ''
            break
          case 'CLICK-FMLY-EXIT':
            eventId = 'CLICK-패밀리서비스-패밀리탈퇴페이지'
            eventDtl = '나갈게요'
            action = '나갈게요'
            break
          // 패밀리서비스 탈퇴x
          // 통합멤버십 혜택 딥링크
          case 'CLICK-MEM-BNFT-C':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = 'GS25 이동'
            action = 'GS25 이동'
            break
          case 'CLICK-MEM-BNFT-S':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = 'GS더프레시 이동'
            action = 'GS더프레시 이동'
            break
          case 'CLICK-MEM-BNFT-E':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = 'GS프레시몰 이동'
            action = 'GS프레시몰 이동'
            break
          case 'CLICK-MEM-BNFT-H':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = 'GSSHOP 이동'
            action = 'GSSHOP 이동'
            break
          case 'CLICK-MEM-BNFT-V':
            eventId = 'CLICK-GS멤버십-통합회원등급페이지'
            eventDtl = 'GSSHOP VIP 이동'
            action = 'GSSHOP VIP 이동'
            break
          // 통합멤버십 혜택 딥링크
          // 등급기준안내 딥링크
          case 'CLICK-GRD-BNFT-C':
            eventId = 'CLICK-등급혜택보기-등급기준안내페이지'
            eventDtl = 'GS25 이동'
            action = 'GS25 이동'
            break
          case 'CLICK-GRD-BNFT-S':
            eventId = 'CLICK-등급혜택보기-등급기준안내페이지'
            eventDtl = 'GS더프레시 이동'
            action = 'GS더프레시 이동'
            break
          case 'CLICK-GRD-BNFT-E':
            eventId = 'CLICK-등급혜택보기-등급기준안내페이지'
            eventDtl = 'GS프레시몰 이동'
            action = 'GS프레시몰 이동'
            break
          case 'CLICK-GRD-BNFT-H':
            eventId = 'CLICK-등급혜택보기-등급기준안내페이지'
            eventDtl = 'GSSHOP 이동'
            action = 'GSSHOP 이동'
            break
          case 'CLICK-GRD-BNFT-V':
            eventId = 'CLICK-등급혜택보기-등급기준안내페이지'
            eventDtl = 'GSSHOP VIP 이동'
            action = 'GSSHOP VIP 이동'
            break
          // 등급기준안내 딥링크

          // 신규 통합멤버십 앰플리튜드
          // 멤버십/패밀리 상단 다음달/등급업 채널
          case 'VIEW-INTG-GRD-C':
            eventId = 'VIEW-GS통합멤버십-등급업채널GS25'
            eventDtl = '등급업채널GS25'
            action = ''
            break
          case 'VIEW-INTG-GRD-S':
            eventId = 'VIEW-GS통합멤버십-등급업채널GS더프레시'
            eventDtl = '등급업채널GS더프레시'
            action = ''
            break
          case 'VIEW-INTG-GRD-H':
            eventId = 'VIEW-GS통합멤버십-등급업채널GSSHOP'
            eventDtl = '등급업채널GSSHOP'
            action = ''
            break
          case 'VIEW-INTG-GRD-A':
            eventId = 'VIEW-GS통합멤버십-등급업채널브랜드합산'
            eventDtl = '등급업채널브랜드합산'
            action = ''
            break
          case 'VIEW-INTG-GRD-EMPTY':
            eventId = 'VIEW-GS통합멤버십-등급업채널비노출'
            eventDtl = '등급업채널비노출'
            action = ''
            break
          case 'VIEW-INTG-GRD-VVIP':
            eventId = 'VIEW-GS통합멤버십-VVIP페이지'
            eventDtl = 'VVIP페이지'
            action = ''
            break
          // 멤버십(개인) 등급
          case 'VIEW-INTG-MEM-GRD':
            eventId = 'VIEW-GS통합멤버십-개인등급페이지'
            eventDtl = '멤버십개인등급페이지'
            action = ''
            break
          case 'CLICK-INTG-MEM-GRD-C':
            eventId = 'CLICK-GS통합멤버십-개인등급페이지'
            eventDtl = '등급 업 유도 채널 GS25'
            action = '등급업 GS25 이동'
            break
          case 'CLICK-INTG-MEM-GRD-S':
            eventId = 'CLICK-GS통합멤버십-개인등급페이지'
            eventDtl = '등급 업 유도 채널 GS더프레시'
            action = '등급업 GS더프레시 이동'
            break
          case 'CLICK-INTG-MEM-GRD-H':
            eventId = 'CLICK-GS통합멤버십-개인등급페이지'
            eventDtl = '등급 업 유도 채널 GSSHOP'
            action = '등급업 GSSHOP 이동P'
            break
          case 'CLICK-INTG-MEM-GRD-BNFT':
            eventId = 'CLICK-GS통합멤버십-개인등급페이지'
            eventDtl = '혜택한눈에보기'
            action = '혜택보기'
            break
          case 'CLICK-INTG-MEM-GRD-FMLY':
            eventId = 'CLICK-GS통합멤버십-개인등급페이지'
            eventDtl = '패밀리그룹유도 배너'
            action = '패밀리 그룹 배너'
            break
          case 'CLICK-INTG-MEM-PUR-C':
            eventId = 'CLICK-GS통합멤버십-개인등급페이지'
            eventDtl = '채널실적 GS25'
            action = '채널실적 GS25 이동'
            break
          case 'CLICK-INTG-MEM-PUR-S':
            eventId = 'CLICK-GS통합멤버십-개인등급페이지'
            eventDtl = '채널실적 GS더프레시'
            action = '채널실적 GS더프레시 이동'
            break
          case 'CLICK-INTG-MEM-PUR-H':
            eventId = 'CLICK-GS통합멤버십-개인등급페이지'
            eventDtl = '채널실적 GSSHOP'
            action = '채널실적 GSSHOP 이동'
            break
          case 'CLICK-INTG-MEM-GRD-WHAT':
            eventId = 'CLICK-GS통합멤버십-개인등급페이지'
            eventDtl = '통합멤버십'
            action = '통합 멤버십 배너'
            break
          // 멤버십(개인) 등급
          // 패밀리(그룹) 등급
          case 'VIEW-INTG-FMLY-GRD':
            eventId = 'VIEW-GS통합멤버십-그룹등급페이지'
            eventDtl = '멤버십그룹등급페이지'
            action = ''
            break
          case 'CLICK-INTG-FMLY-GRD-C':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '등급업채널GS25'
            action = '등급업 GS25 이동'
            break
          case 'CLICK-INTG-FMLY-GRD-S':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '등급업채널GS더프레시'
            action = '등급업 GS더프레시 이동'
            break
          case 'CLICK-INTG-FMLY-GRD-H':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '등급업채널GSSHOP'
            action = '등급업 GSSHOP 이동'
            break
          case 'CLICK-INTG-FMLY-GRD-BNFT':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '혜택한눈에보기'
            action = '혜택보기'
            break
          case 'CLICK-INTG-FMLY-GRD-INVITE':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '그룹멤버 초대'
            action = '멤버초대'
            break
          case 'CLICK-INTG-FMLY-PUR-MEM':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '개인 실적'
            action = '개인실적'
            break
          case 'CLICK-INTG-FMLY-PUR-FMLY':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '그룹 실적'
            action = '그룹실적'
            break
          case 'CLICK-INTG-FMLY-PUR-C':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '채널실적 GS25'
            action = '채널실적 GS25 이동'
            break
          case 'CLICK-INTG-FMLY-PUR-S':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '채널실적 GS더프레시'
            action = '채널실적 GS더프레시 이동'
            break
          case 'CLICK-INTG-FMLY-PUR-H':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '채널실적 GSSHOP'
            action = '채널실적 GSSHOP 이동'
            break
          case 'CLICK-INTG-FMLY-GRD-WHAT':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '통합멤버십'
            action = '통합 멤버십 배너'
            break
          case 'CLICK-INTG-FMLY-GRP-MORE':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '그룹 정보 더보기'
            action = '그룹 정보'
            break
          case 'CLICK-INTG-FMLY-GRP-UPDATE':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '그룹명 수정'
            action = '그룹명 수정'
            break
          case 'CLICK-INTG-FMLY-GRP-EXIT':
            eventId = 'CLICK-GS통합멤버십-그룹등급페이지'
            eventDtl = '그룹나가기'
            action = '그룹 나가기'
            break
          // 패밀리(그룹) 등급
          // 멤버십혜택안내
          case 'VIEW-INTG-GRD-BNFT':
            eventId = 'VIEW-GS통합멤버십-혜택안내페이지'
            eventDtl = '등급별혜택안내'
            action = ''
            break
          case 'VIEW-INTG-GRD-BNFT-VVIP':
            eventId = 'VIEW-GS통합멤버십-혜택안내페이지'
            eventDtl = 'VVIP혜택'
            action = 'VVIP 혜택'
            break
          case 'VIEW-INTG-GRD-BNFT-VIP':
            eventId = 'VIEW-GS통합멤버십-혜택안내페이지'
            eventDtl = 'VIP혜택'
            action = 'VIP 혜택'
            break
          // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
          case 'VIEW-INTG-GRD-BNFT-DIAMOND':
            eventId = 'VIEW-GS통합멤버십-혜택안내페이지'
            eventDtl = 'DIAMOND혜택'
            action = 'DIAMOND 혜택'
            break
          case 'VIEW-INTG-GRD-BNFT-GOLD':
            eventId = 'VIEW-GS통합멤버십-혜택안내페이지'
            eventDtl = 'GOLD혜택'
            action = 'GOLD 혜택'
            break
          case 'CLICK-INTG-GRD-BNFT-C':
            eventId = 'CLICK-GS통합멤버십-혜택안내페이지'
            eventDtl = '채널혜택 GS25'
            action = '채널혜택 GS25 이동'
            break
          case 'CLICK-INTG-GRD-BNFT-S':
            eventId = 'CLICK-GS통합멤버십-혜택안내페이지'
            eventDtl = '채널혜택 GS더프레시'
            action = '채널혜택 GS더프레시 이동'
            break
          case 'CLICK-INTG-GRD-BNFT-H':
            eventId = 'CLICK-GS통합멤버십-혜택안내페이지'
            eventDtl = '채널혜택 GSSHOP'
            action = '채널혜택 GSSHOP 이동'
            break
          case 'VIEW-INTG-GRD-STD':
            eventId = 'VIEW-GS통합멤버십-등급산정기준페이지'
            eventDtl = '등급산정기준'
            action = ''
            break
          case 'CLICK-INTG-GRD-STD-C':
            eventId = 'CLICK-GS통합멤버십-등급산정기준페이지'
            eventDtl = 'GS25'
            action = '등급기준 GS25'
            break
          case 'CLICK-INTG-GRD-STD-S':
            eventId = 'CLICK-GS통합멤버십-등급산정기준페이지'
            eventDtl = 'GS더프레시'
            action = '등급기준 GS더프레시'
            break
          case 'CLICK-INTG-GRD-STD-H':
            eventId = 'CLICK-GS통합멤버십-등급산정기준페이지'
            eventDtl = 'GSSHOP'
            action = '등급기준 GSSHOP'
            break
          case 'CLICK-INTG-GRD-STD-A':
            eventId = 'CLICK-GS통합멤버십-등급산정기준페이지'
            eventDtl = 'GS리테일브랜드합산'
            action = '등급기준 합산실적'
            break
          // 멤버십혜택안내
          // 패밀리소개/만들기
          case 'VIEW-INTG-FMLY-INF':
            eventId = 'VIEW-패밀리서비스-패밀리소개페이지'
            eventDtl = '패밀리소개'
            action = ''
            break
          case 'CLICK-INTG-FMLY-INF-CRE':
            eventId = 'CLICK-패밀리서비스-패밀리소개페이지'
            eventDtl = '패밀리 그룹 만들기'
            action = '그룹 만들기'
            break
          case 'CLICK-INTG-FMLY-INF-MOVE':
            eventId = 'CLICK-패밀리서비스-패밀리소개페이지'
            eventDtl = '패밀리 그룹 바로가기'
            action = '그룹 가기'
            break
          case 'CLICK-INTG-FMLY-INF-AGREE':
            eventId = 'CLICK-패밀리서비스-패밀리소개페이지'
            eventDtl = '동의하기'
            action = '동의하기'
            break
          case 'VIEW-INTG-FMLY-CRE':
            eventId = 'VIEW-패밀리서비스-그룹만들기페이지'
            eventDtl = '그룹명 입력'
            action = ''
            break
          case 'CLICK-INTG-FMLY-CRE-INVITE':
            eventId = 'CLICK-패밀리서비스-그룹만들기페이지'
            eventDtl = '멤버초대하기'
            action = '멤버 초대'
            break
          // 패밀리소개/만들기
          // 패밀리 수락
          case 'VIEW-INTG-FMLY-INV':
            eventId = 'VIEW-패밀리서비스-그룹수락페이지'
            eventDtl = '패밀리수락'
            action = ''
            break
          case 'CLICK-INTG-FMLY-INV-JOIN':
            eventId = 'CLICK-패밀리서비스-그룹수락페이지'
            eventDtl = '가입할게요'
            action = '가입하기'
            break
          case 'CLICK-INTG-FMLY-INV-AGREE':
            eventId = 'CLICK-패밀리서비스-그룹수락페이지'
            eventDtl = '동의하기'
            action = '동의하기'
            break
          case 'VIEW-INTG-FMLY-COMP':
            eventId = 'VIEW-패밀리서비스-가입완료팝업'
            eventDtl = '가입완료팝업'
            action = ''
            break
          case 'VIEW-INTG-FMLY-COMP-OVER':
            eventId = 'VIEW-패밀리서비스-정원초과팝업'
            eventDtl = '정원초과팝업'
            action = ''
            break
          case 'VIEW-INTG-FMLY-COMP-CONF':
            eventId = 'VIEW-패밀리서비스-기가입확인페이지'
            eventDtl = '기가입확인'
            action = ''
            break
          case 'CLICK-INTG-FMLY-COMP-MOVE':
            eventId = 'CLICK-패밀리서비스-기가입확인페이지'
            eventDtl = '기가입확인'
            action = '그룹 가기'
            break
          // 패밀리 수락
          // 패밀리 탈퇴
          case 'VIEW-INTG-FMLY-EXIT':
            eventId = 'VIEW-패밀리서비스-패밀리탈퇴페이지'
            eventDtl = '패밀리탈퇴'
            action = ''
            break
          case 'CLICK-INTG-FMLY-EXIT':
            eventId = 'CLICK-패밀리서비스-패밀리탈퇴페이지'
            eventDtl = '나갈게요'
            action = '그룹 나가기'
            break
          case 'CLICK-INTG-FMLY-EXIT-CNL':
            eventId = 'CLICK-패밀리서비스-패밀리탈퇴페이지'
            eventDtl = '계속이용할게요'
            action = '계속 이용하기'
            break
          case 'VIEW-INTG-FMLY-EXIT-COMP':
            eventId = 'VIEW-패밀리서비스-패밀리탈퇴완료페이지'
            eventDtl = '패밀리탈퇴완료'
            action = ''
            break
          // 패밀리 탈퇴
          // 신규 통합멤버십 앰플리튜드
          default:
            break
        }

        console.log('## tagId   : ' + tagId + ', isApp : ' + this.isApp)
        console.log('## eventId : ' + eventId + ', eventDtl : ' + eventDtl + ', action : ' + action)
        if (!this.$isEmpty(eventId)) {
          var chanlNm =
            this.chanlId == '2010'
              ? '우리동네GS'
              : this.chanlId == '2020'
              ? 'GS프레시몰'
              : this.chanlId == '3010'
              ? 'GSSHOP'
              : ''
          var chanlActKey =
            this.chanlId == '2010'
              ? 'sendAMPEventProperties'
              : this.chanlId == '2020'
              ? 'trackingAmplitudeSanLogEvent'
              : this.chanlId == '3010'
              ? 'sendAMPEventProperties'
              : ''
          var amplitudeInfo = {
            // 이벤트: eventDtl,
            액션: action,
            유입채널: chanlNm
          }
          console.log('## json.stringify : ' + JSON.stringify(amplitudeInfo) + ', actKey : ' + chanlActKey)
          console.log('## window.flutter_inappwebview : ' + !this.$isEmpty(window.flutter_inappwebview))

          var isFreshApp = '2020' == this.chanlId && !this.$isEmpty(window.flutter_inappwebview)
          console.log('## 통합멤버십 isApp 체크 : ' + this.isApp + ', 프래시몰 isApp 체크 : ' + isFreshApp)

          if (this.isApp || isFreshApp) {
            console.log('##### app amplitudeTag push #####')
            setTimeout(function () {
              console.log('>> flutter_inappwebview.callHandler 시작')
              var andEventPropertiesJson = JSON.stringify(amplitudeInfo)
              try {
                window.flutter_inappwebview
                  .callHandler('flutterAppInterface', {
                    actKey: chanlActKey, // 채널별 actKey
                    eventName: eventId, // 이벤트
                    reqparam: andEventPropertiesJson, // GS샵,우동G
                    param: andEventPropertiesJson // 프래시
                  })
                  .then(function (result) {
                    console.log('>> flutter_inappwebview.callHandler 결과')
                    console.log(result)
                  })
              } catch (e) {
                console.log('## flutter_inappwebview.callHandler Exception #####')
                console.log(e)
                console.log('## flutter_inappwebview.callHandler Exception #####')
              }
              console.log('>> flutter_inappwebview.callHandler 종료')
            }, 1000)
          } else {
            console.log('##### web amplitudeTag push #####')
            // amplitude 이벤트 수집
            // this.$amplitude.getInstance().logEvent(eventId)
            // amplitude properties 추가 수집
            this.$amplitude.getInstance().logEvent(eventId, amplitudeInfo)
          }
        } else {
          console.log('@@ eventId is null/empty')
        }
      } catch (e) {
        console.log('##### Exception #####')
        console.log(e)
        console.log('##### Exception #####')
      }
      console.log('@@@@@ TagManager tagging end @@@@@')
      return false
    }
  }
}
</script>
