// state
const state = {
  status: false,
  headerParam: {
    'System-Code': 'CMMFRT'
    // 'Access-Key': 'OWJlOGYyZTJhNzBmY2YwYjEzMjVlMDg5N2FiYjI1NjRhNDcxNjYwZmQyOWM5YWViNWRhODcxM2EzYWJmNDdjZA==',
  },
  bodyParam: {
    aud: 'montblanc',
    trnscNo: '0', //트랜잭션번호
    chanlCd: '', //채널코드  3010 : GS SHOP, 2010 : 우리동네GS, 2020 : GS Fresh Mall
    siteId: '', //2000 : 리테일, 3000 : 홈쇼핑
    intgMemNo: '',
    domainName: '',
    apiId: '', //API ID
    famyGrpId: '', //패밀리그룹ID
    openChanlCd: '', //오픈채널코드
    famyGrpTrmsAgreeYn: '', //패밀리그룹약관동의여부
    famyGrpTrmsAgreeDtm: '', //패밀리그룹약관동의일시
    invitCnt: '', //초대수
    procGbnCd: '', //처리구분코드
    admChanlCd: '', //가입채널코드
    wthdrwChanlCd: '', //탈퇴채널코드
    kakaoApi: '', //카카오API Key,
    exp: '',
    apiUrl: '', //채널별 몽블랑 API 정보
    chanlDomain: '', //채널별 도메인 정보
    chanlMyDomain: '', //Fresh 마이쇼핑 subdomain이 달라 추가함.
    ampkey: '', //채널별 amplitude key    
    gshBnftLink: '', //혜택 [GS샵 마이쇼핑 deep link]
    gsrBnftLink: '', //혜택 [GS프래쉬 마이쇼핑 deep link]
    wdgBnftLink: '', //혜택 [우동G 마이쇼핑 deep link]
    gshBnftVipLink: '' //혜택 [GS샵 VIP deep link]
  },
  historyBack: {
    closeUrl: ['IntgMemshpGrade', 'IntgFamilyJoin', 'IntgFamilyInfo'],
    moveUrl: []
  }
}
// 동기적 mutations
// 기본 접근방법 : this.$store.commit('함수명','파라미터')
const mutations = {
  changeApiStatus(state, newVal) {
    state.status = newVal
  },
  chageApiHeaderParam(state, newVal) {
    for (var i in Object.keys(newVal)) {
      state.headerParam[Object.keys(newVal)[i]] = Object.values(newVal)[i]
    }
  },
  chageApiBodyParam(state, newVal) {
    for (var i in Object.keys(newVal)) {
      state.bodyParam[Object.keys(newVal)[i]] = Object.values(newVal)[i]
    }
  }
}

// 비동기적 action
// 기본 접근방법 : this.$store.dispatch('경로명/함수명')
const actions = {
  // callMutation ({ commit, dispatch }, { newVal }) {
  //   console.log(newVal)
  //   commit('changeStatus', newVal)
  // },
  // TIME ({commit}, value) {
  //   return setTimeout(() => {
  //     commit('SET_TEST', value)
  //   }, 1000)
  // }
}

// getters
// 기본 접근방법 : this.$store.getters["경로명/함수명"];
//                this.$store.getters.doneTodosCount
//                this.$store.getters.getTodoById(2)
const getters = {
  // getStatus () {
  //   return `${state.status} => Status : ${state.status}`
  // }
}

export default {
  state,
  mutations,
  actions,
  getters
}
