import VueJwtDecode from 'vue-jwt-decode'

export default {
  async install(Vue) {
    // null체크
    Vue.prototype.$isEmpty = function (value) {
      if (
        value == '' ||
        value == null ||
        value == 'null' ||
        value == undefined ||
        value == 'undefined' ||
        (value != null && typeof value == 'object' && !Object.keys(value).length)
      ) {
        return true
      } else {
        return false
      }
    }
    // 토큰 deocde
    Vue.prototype.$decodeToken = function () {
      var cookiesKeys = this.$cookies.keys()
      var index = cookiesKeys.indexOf('sAs')
      var param = {}
      if (index > -1) {
        var tokenTempData = VueJwtDecode.decode(this.$cookies.get(cookiesKeys[index]))
        param = {
          aud: tokenTempData.aud,
          intgMemNo: tokenTempData.intg_mem_no,
          chanlCd: tokenTempData.chanl_cd,
          exp: tokenTempData.exp,
          siteId: tokenTempData.chanl_cd.substring(0, 1) + '000'
        }
        this.$store.commit('chageApiBodyParam', param)
        return true
      } else {
        return false
      }
    }
    //Trns 코드 채번
    Vue.prototype.$getTrnscNo = function (siteId) {
      if (!this.$isEmpty(siteId)) {
        var date = this.$dayjs().format('YYYYMMDDHHmmssSSS')
        let num = ''
        for (var i = 0; i < 3; i++) {
          num += Math.floor(Math.random() * 10)
        }
        var TrnscNo = siteId + date + num
        return TrnscNo
      } else {
        return false
      }
    }
    //이전 월
    Vue.prototype.$subtractDate = function (field, input, format, day) {
      if (null === format || '' === format) {
        format = 'YYYYMMDDHHmmssSSS'
      }
      var date
      if (('year' === field || 'month' === field) && input >= 0) {
        // console.log('field : ' + field + ' input : ' + input + ' day : ' + day)
        if (null === day || undefined === day || '' === day) {
          date = this.$dayjs(new Date()).subtract(input, field).format(format)
        } else {
          date = this.$dayjs(new Date()).subtract(input, field).format(format) + '.' + day
        }
        // console.log(' date : ' + date)
      } else {
        date = this.$dayjs().format(format)
      }
      return date
    }
    //통화 함수
    // 입력된 value 가 숫자 형태의 숫자,숫자형문자인 경우 콤마를 찍어서 return
    // 입력된 value 가 숫자가 아닌 경우 입력된 값을 return
    Vue.prototype.$toCurrency = function (value, defaultValue) {
      if (null === value || undefined === value || '' === value || isNaN(value)) {
        if (null != defaultValue && '' != defaultValue && undefined != defaultValue) {
          return defaultValue
        } else {
          return value
        }
      } else {
        return new Intl.NumberFormat().format(value)
      }
    }

    //<!-- 통합멤버십 UI 개편 -->
    // 레이어 오픈 전/후 body scroll-rock 설정/해제
    Vue.prototype.$onScroll = function (boolean) {
      if (boolean) {
        console.log('--->> common onScroll(true) <<---')
        // 레이어 띄울때 body fixed
        document.querySelector('html').classList.add('scroll-rock')
        document.body.classList.add('scroll-rock')
      } else {
        console.log('--->> common onScroll(false) <<---')
        // 레이어 띄울때 body fixed 제거
        document.body.classList.remove('scroll-rock')
        document.querySelector('html').classList.remove('scroll-rock')
        document.body.removeAttribute('class')
        document.body.removeAttribute('style')
      }
    }
    // window close 기능
    Vue.prototype.$windowClose = function (isApp, chanlCd) {
      console.log('--->> common window close(back) <<---')
      sessionStorage.removeItem('inCheck1')
      //GSSHOP 앱(웹뷰) 여부 체크
      // alert('패밀리서비스 이동 임시 알럿:' + navigator.userAgent + ', isApp[' + this.isApp + ']')
      if (isApp) {
        if ('2010' === chanlCd || '3010' === chanlCd) {
          // alert('패밀리서비스 임시 창닫기 : 앱 닫기 toapp://close 호출')
          location.href = 'toapp://close'
        } else {
          // alert('패밀리서비스 임시 창닫기 : 앱 뒤로가기 toapp://back 호출')
          location.href = 'toapp://back'
        }
      } else {
        // alert('패밀리서비스 임시 창닫기 : 웹 뒤로가기 go(-1) 호출')
        this.$router.go(-1)
      }
      // console.log('--->> common window close(back) 종료 <<---')
    }
  }
}
