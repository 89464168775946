<!-- 패밀리 더보기 클릭 메뉴 -->
<template>
  <div id="modal">
    <div class="layerWrap">
      <div class="toast md-container">
        <!-- 전사 통합 -->
        <div class="inner">
          <div class="title-wrap left">
            <h1>패밀리 정보 더보기</h1>
          </div>
          <div class="cont-wrap">
            <div class="contents">
              <!-- contents -->
              <div class="icon-menu">
                <ul>
                  <li>
                    <a @click="onLayer('family')"><i class="ico_family-info"></i>패밀리 정보 보기</a>
                  </li>
                  <li v-if="'exit' != fmlyJoinType && !('rejoin' == fmlyJoinType && !isNewFamilyGrp)">
                    <a @click="onLayer('rename')"><i class="ico_family-rename"></i>패밀리명 변경</a>
                  </li>
                  <li v-if="'exit' != fmlyJoinType && !('rejoin' == fmlyJoinType && !isNewFamilyGrp)">
                    <a @click="onLayer('exit')"><i class="ico_family-exit"></i>패밀리 그룹 나가기</a>
                  </li>
                </ul>
              </div>
              <!-- //contents -->
            </div>
          </div>
        </div>
        <div class="close">
          <button aria-label="닫기" @click="onLayer('menu')"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalFamilyMenu',
  props: {
    fmlyJoinType: String,
    isNewFamilyGrp: Boolean
  },
  data() {
    return {}
  },
  mounted() {
    console.log('## ModalFamilyMenu Layer fmlyJoinType : ' + this.fmlyJoinType + ', ' + this.isNewFamilyGrp)
  },
  methods: {
    onLayer(type) {
      console.log('>> onLayer type : ' + type)
      this.$emit('onLayer', type, false)
      return
    }
  }
}
</script>
