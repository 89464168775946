<template>
  <div id="modal">
    <div class="layerWrap">
      <div class="toast membership re-name">
        <div class="inner">
          <div class="cont-wrap">
            <div>
              <h2>패밀리 초대자 휴대폰 번호 입력</h2>
              <div class="membership-box">
                <div class="notification">
                  <div class="text">
                    <i class="ico-info_circle_full"></i>
                    <p class="desc">패밀리 가입을 위해, 나를 초대한 분의 휴대폰 번호 맨 뒤 4자리를 입력해 주세요!</p>
                  </div>
                </div>
                <div class="family-membership">
                  <div
                    class="family-ipt"
                    :class="{
                      'ipt-typing': inputCheck == true,
                      'ipt-end': inputEnd == true && isError == false,
                      'ipt-error': isError == true
                    }"
                  >
                    <label for="certNo">초대자 휴대폰 번호 뒤 4자리</label>
                    <input
                      :disabled="nameLoad"
                      type="tel"
                      placeholder="휴대폰 번호 뒤 4자리"
                      ref="certNo"
                      :value="certNo"
                      v-on:input="typingCheck"
                      :maxlength="maxlength"
                    />
                    <div class="ipt-error-box">
                      <p class="ipt-error-txt">{{ validMessage }}</p>
                      <button type="button" class="ipt-error-btn"><span class="hidden">오류</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="b-btn-wrap">
            <div>
              <button
                class="check"
                :class="{loading: nameLoad == true, disabled: certNo.length < 4 || isError}"
                @click="closeApply"
              >
                <span>확인</span>
              </button>
            </div>
          </div>
        </div>
        <div class="close">
          <button aria-label="닫기" @click="onLayer('cert')"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalFamilyRename',
  props: {
    msgTitle: String,
    msg: String,
    hideReName: Boolean,
    confirmCencel2: Boolean
  },
  data() {
    return {
      certNo: '',
      inputCheck: false, // 입력 중 체크
      inputEnd: false, // 입력 완료 체크
      isError: false, //입력문자 유효성 검증
      validMessage: '',
      nameLoad: false,
      maxlength: 4 //최대 입력 글자 수
    }
  },
  mounted() {
    console.log('## ModalFamilyRename Layer')
    this.setFocus()
  },
  methods: {
    onLayer(type) {
      console.log('>> onLayer type : ' + type)
      switch (type) {
        case 'cert': // 인증번호 레이어 닫기
          this.$emit('onLayer', type, false)

          break
        default:
          break
      }
      return
    },
    closeApply() {
      console.log('this.isError : ' + this.isError + ', this.inputEnd : ' + this.inputEnd)
      if (this.isError === false && this.inputEnd == true) {
        console.log('## 패밀리 초대 수락 인증번호 요청 호출')
        this.$emit('onCertNo', this.certNo)
        this.$emit('onLayer', 'cert', false)
      } else {
        this.nameLoad = false
      }
    },
    setFocus() {
      this.$refs.certNo.focus()
    },
    typingCheck(event) {
      const isNumber = /^[0-9]{1,4}$/.test(event.target.value)
      console.log(
        '@@@@@@@ typingCheck @@@@@@@@@ ' + event.target.value + ': isNumber:' + isNumber + ', certNo : ' + this.certNo
      )

      if (null != event.target.value && '' != event.target.value && isNumber === false) {
        // console.log('@@ 특수문자 입력 오류 메시지 노출 필요함. : isError[' + this.isError + '] ' + this.familyNameValue)
        this.isError = true
        this.validMessage = '초대자 휴대폰 번호 뒤 4자리를 입력해주세요.'
        // {{ validMessage }}"는 입력할 수 없는 단어입니다.
        console.log('@@@@@@@ isError : ' + this.validMessage)
      } else {
        this.isError = false
        this.validMessage = ''
      }

      if (isNaN(event.target.value)) {
        console.log('@@@@@@@ isNaN : ' + isNaN(event.target.value))
        this.isError = true
        this.validMessage = '초대자 휴대폰 번호 뒤 4자리를 입력해주세요.'
      }
      console.log(
        '>>>>> is Error : ' +
          this.isError +
          ', this.inputEnd : ' +
          this.inputEnd +
          ', event.target.value : ' +
          event.target.value +
          ', this.certNo : ' +
          this.certNo
      )

      if (event.target.value.length === 4) {
        this.inputEnd = true
      } else {
        this.inputEnd = false
      }
      this.certNo = event.target.value
      console.log('@@@@@@@ typingCheck @@@@@@@@@ ' + event.target.value + ':' + this.certNo)
    },
    reset() {
      this.setFocus()
      this.certNo = ''
      this.inputCheck = false
      this.inputEnd = false
    },
    clear(e) {
      e.preventDefault()
    }
  }
}
</script>
