<template>
  <div id="app">
    <the-layout>
      <router-view />
    </the-layout>
  </div>
</template>
<script>
import TheLayout from '@/layouts/index.vue'

export default {
  name: 'App',
  components: {
    TheLayout
  },
  data() {
    return {}
  },
  created() {
    process.env.NODE_ENV == 'development' ? this.$decodeToken() : ''
  },
  mounted() {}
}
</script>
<style lang="scss">
@import '@/assets/scss/reset.scss';
@import '@/assets/scss/modules.scss';
</style>
