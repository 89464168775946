import memberRouter from '@/router/member'
import familyRouter from '@/router/family'
import Main from '@/views/index.vue'
import indexList from '@/views/indexList.vue'
import SetToken from '@/views/setToken.vue'
import ExitPage from '@/views/comm/ExitPage.vue'

const commonRouter = [
  {
    path: '/montblanc/indexList',
    name: 'indexList',
    component: indexList
  },
  {
    path: '/montblanc/cmm/gradeStandard',
    redirect: '/montblanc/intg/gradeStandard'
  },
  {
    path: '/montblanc/exitPage',
    name: 'ExitPage',
    component: ExitPage
  }
]

// - 개발모드 URL 설정
if ('development' === process.env.NODE_ENV) {
  commonRouter.push(
    {
      path: '/montblanc',
      name: 'Main',
      component: Main
    },
    //공통 영역
    {
      path: '/montblanc/setToken',
      name: 'SetToken',
      component: SetToken
    }
  )
}

const routes = [...commonRouter, ...memberRouter, ...familyRouter]

export default routes
