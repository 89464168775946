// state
const state = {
    status: false,
    // 시스템 오류 메세지
    sysErr: '시스템 오류가 발생하였습니다. 고객센터로 문의 주세요.',
    // 시스템 점검 메세지
    sysChk: '서비스 점검 중입니다. 잠시 후 다시 시도해주세요.'
}

export default {
    state
}