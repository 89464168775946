<!-- 다음 등급 적용일 -->
<template>
  <div id="modal">
    <div class="layerWrap">
      <div class="toast md-container">
        <!-- 전사 통합 -->
        <div class="inner">
          <div class="cont-wrap">
            <div class="contents">
              <!-- contents -->
              <div class="md-myfamily-info">
                <header class="sect-head">
                  <div class="head">
                    <h2 class="ttl">{{ famyGrpNm }}</h2>
                  </div>
                </header>
                <div class="family-my">
                  <dl>
                    <dt>아이디</dt>
                    <dd>{{ famyGrpId }}</dd>
                  </dl>
                  <dl>
                    <dt>개설일</dt>
                    <dd>{{ openDt }}</dd>
                  </dl>
                </div>
              </div>
              <!-- //contents -->
            </div>
          </div>
        </div>
        <div class="close">
          <button aria-label="닫기" @click="onLayer('fmlyClose')"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalFamilyInfo',
  props: {
    // famyGrpId: String,
    // famyGrpNm: String,
    // openDt: String
    familyInfo: Object
  },
  created() {
    this.famyGrpId = this.familyInfo.famyGrpId
    this.famyGrpNm = this.familyInfo.famyGrpNm
    this.openDt = this.$dayjs(this.familyInfo.openDt).format('YYYY.MM.DD')
  },
  data() {
    return {
      famyGrpId: '',
      famyGrpNm: '',
      openDt: ''
    }
  },
  mounted() {
    console.log('## ModalFamilyInfo Layer layerType : ' + this.layerType)
    console.log(
      '## 패밀리 그룹 정보 보기 레이어 : famyGrpId[' +
        this.famyGrpId +
        '], famyGrpNm[' +
        this.famyGrpNm +
        '], openDt[' +
        this.openDt +
        ']'
    )
  },
  methods: {
    onLayer(type) {
      console.log('>> onLayer type : ' + type)
      this.$emit('onLayer', type, false)
    }
  }
}
</script>
