<template>
  <div id="modal" v-if="show">
    <div class="layerWrap">
      <div class="alert show">
        <div class="txt">
          <p>{{ msg }}</p>
        </div>
        <div class="btn">
          <div>
            <button class="check" @click="onEmitCencel"><span>취소</span></button>
            <button class="check" @click="onEmitOk"><span>확인</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modalConfirm',
  props: {
    msg: String,
    code: String,
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onEmitOk() {
      this.$emit('confirmOk', false)
    },
    onEmitCencel() {
      this.$emit('confirmCencel', false)
    }
  }
}
</script>
