<template>
  <div id="modal" v-if="show">
    <div class="layerWrap">
      <div class="alert show">
        <div class="txt">
          <p>{{ msg }}</p>
        </div>
        <div class="btn">
          <div>
            <button class="check" @click="onEmit"><span>확인</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modalAlert',
  props: {
    msg: String,
    code: String,

    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onEmit() {
      this.$emit('alertOk', false)
    }
  }
}
</script>
