<template>
  <div class="membership-box integrate">
    <!-- 패밀리 초대 -->
    <div class="family-member-wrap">
      <!-- 채널 -->
      <div class="fm_accept">
        <div>
          <header class="sect-head">
            <div class="head-colum new">
              <sub>멤버십 혜택을 함께 받는, 패밀리 서비스!</sub>
              <h2 class="ttl">초대를 수락하려면<br />이용할 앱을 선택해 주세요!</h2>
            </div>
          </header>
          <!-- [GRIT-141984] UT기반 패밀리서비스 UIUX 개선 요청 : 24.07.31 -->
          <!--  UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14 -->
          <div v-if="'3010' === chanlCd" class="accept_btn_wrap">
            <div class="link-box gsshop">
              <button @click="goShop">
                <img src="@/assets/images/member/app-logo-gsshop.png" alt="GS SHOP" />
                <p>GS SHOP</p>
              </button>
            </div>
            <div class="link-box woodongs">
              <button @click="goWoodongs">
                <img src="@/assets/images/member/app-logo-woodongs.png" alt="우리동네 GS" />
                <p>우리동네GS</p>
              </button>
            </div>
          </div>
          <div v-else class="accept_btn_wrap">
            <div class="link-box woodongs">
              <button @click="goWoodongs">
                <img src="@/assets/images/member/app-logo-woodongs.png" alt="우리동네 GS" />
                <p>우리동네GS</p>
              </button>
            </div>
            <div class="link-box gsshop">
              <button @click="goShop">
                <img src="@/assets/images/member/app-logo-gsshop.png" alt="GS SHOP" />
                <p>GS SHOP</p>
              </button>
            </div>
          </div>
          <p class="dot-blst">
            위의 GS리테일 앱 중, 어느 앱이나 패밀리 서비스 이용 가능! 지금 이용 중이거나 앞으로 이용하고 싶은 앱을
            선택해주세요.
          </p>
          <!-- //[GRIT-141984] UT기반 패밀리서비스 UIUX 개선 요청 : 24.07.31 -->
        </div>
      </div>
      <!-- 채널 -->

      <!-- 소개글
      <section class="family-intro">
        <div class="intro point">
          <img src="@/assets/images/member/family-gspoint.png" alt="GS&POINT 1000포인트 지급" />

          <div class="tit-area">
            <h1 class="tit">패밀리 서비스 최초 가입 시,<br />GS&POINT 1,000P 지급!</h1>
            <sub>패밀리 그룹 멤버 2인 이상시 지급</sub>
          </div>
        </div>

        <div class="intro group">
          <div class="tit-area">
            <p class="desc">패밀리 그룹 생성 3초면 끝!</p>
            <h1 class="tit"><span class="point">패밀리 그룹</span> 만들고 <br />멤버 확산실적으로 빠른 등급 UP!</h1>
            <p class="desc point">가족, 친구 누구나 최대 4명까지</p>
          </div>

          <img src="@/assets/images/member/family-intro.png" alt="패밀리 이미지" />

          <p class="desc">
            패밀리 그룹을 만들어 멤버들의 구매 실적을<br />
            하나로 모아 <b>멤버십 등급 UP</b>과 <b>등급 혜택</b>을 함께 <br />
            누릴 수 있는 그룹 멤버십 서비스입니다.
          </p>
        </div>
      </section>
      //소개글 -->
    </div>
    <!-- //패밀리 초대 -->
  </div>
</template>

<script>
export default {
  name: 'IntgFamilyInvite',
  inject: ['sendTitle'],
  data() {
    return {
      title: '초대 수락하기',

      //[GRIT-141984] UT기반 패밀리서비스 UIUX 개선 요청 : 24.07.31
      chanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
      famyGrpId: this.$route.params.famyGrpId,
      gshDomain: process.env.VUE_APP_GSH_DOMAIN,
      gsrDomain: process.env.VUE_APP_GSR_DOMAIN,
      wdgDomain: process.env.VUE_APP_WDG_DOMAIN,
      deepLink: ''
    }
  },
  components: {},
  created() {
    console.log('')
    console.log('######## 패밀리 그룹 초대(카카오) UI ########')
    console.log('')

    // 패밀리그룹 초대/수락 이슈 : 24.06.11
    if (!this.$isEmpty(this.$route.query.famyGrpId)) {
      console.log('#### query  famyGrpId : ' + this.$route.query.famyGrpId)
      this.famyGrpId = this.$route.query.famyGrpId
    } else if (!this.$isEmpty(this.$route.params.famyGrpId)) {
      console.log('#### params famyGrpId : ' + this.$route.params.famyGrpId)
      this.famyGrpId = this.$route.params.famyGrpId
    }
  },
  mounted() {
    document.title = this.title
    this.sendTitle(this.title)
    window.scrollTo(0, 0)

    console.log('@@@@@@@@ chanlCd[' + this.chanlCd + '], famyGrpId[' + this.famyGrpId + ']')
    console.log('@@@ encodeURI 1 : ' + encodeURIComponent(this.famyGrpId))
    console.log('@@@ encodeURI 2 : ' + encodeURIComponent(encodeURIComponent(this.famyGrpId)))
  },
  methods: {
    // GS SHOP 이동 처리
    goShop() {
      if (null != this.famyGrpId && '' != this.famyGrpId) {
        console.log('@@@@@ GS SHOP Deep Link 설정')
        var setUrl = this.gshDomain + '/customer/deeplink.gs?link='
        // 패밀리그룹 초대/수락 이슈 : 24.06.11
        // var params = this.gshDomain + '/customer/mblac/fmlygate.gs?famyGrpId=' + this.famyGrpId
        var params = this.gshDomain + '/customer/mblac/fmlygate.gs?famyGrpId=' + encodeURIComponent(this.famyGrpId)
        this.deepLink = setUrl + params
        this.goPage()
      } else {
        console.log('@@@@@ 패밀리그룹ID가 존재하지 않습니다.')
      }
    },
    goWoodongs() {
      if (null != this.famyGrpId && '' != this.famyGrpId) {
        console.log('@@@@@ WooDongG Deep Link 설정')
        // this.deepLink = this.wdgDomain + '/link?view=inviteFamilyMembership&famyGrpId=' + this.famyGrpId
        // 23.01.17 우동G 담당자 요청사항 수정
        // 패밀리그룹 초대/수락 이슈 : 24.06.11
        // this.deepLink =
        //   this.wdgDomain + '/link?' + encodeURIComponent('view=inviteFamilyMembership&famyGrpId=') + this.famyGrpId

        // 패밀리그룹 초대/수락 이슈 : 24.06.11
        //       특수문자 문제로 encodeURI 세번 : 카카오 > 브라우저(1) > 우동G app(2) > 몽블랑(3) 이동
        this.deepLink =
          this.wdgDomain +
          '/link?' +
          encodeURIComponent('view=inviteFamilyMembership&famyGrpId=') +
          encodeURIComponent(encodeURIComponent(encodeURIComponent(this.famyGrpId)))
        this.goPage()
      } else {
        console.log('@@@@@ 패밀리그룹ID가 존재하지 않습니다.')
      }
    },
    goPage() {
      if (null != this.deepLink && '' != this.deepLink) {
        console.log('@@@@@ 각 앱 Deep Link 이동 gogo : ' + this.deepLink)

        location.href = this.deepLink
      }
    }
  }
}
</script>
