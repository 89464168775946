<template>
  <div class="membership-box"></div>
</template>
<script>
export default {
  name: 'ExitPage',
  inject: ['sendTitle'],
  data() {
    return {}
  }
}
</script>
