<template>
  <div id="content">
    <default-header />
    <slot />
  </div>
</template>
<script>
import DefaultHeader from '@/layouts/headers/DefaultHeader.vue'
import {computed} from 'vue'
export default {
  name: 'DefaultLayout',
  components: {
    DefaultHeader
  },
  provide() {
    return {
      injectTitle: computed(() => this.title),
      sendTitle: this.sendTitle
    }
  },
  data() {
    return {
      title: '메인'
    }
  },
  methods: {
    sendTitle(title) {
      this.title = title
    }
  }
}
</script>
<style lang="scss"></style>
