<!-- 다음 등급 적용일 -->
<template>
  <div id="modal">
    <div class="layerWrap">
      <div class="toast md-container">
        <!-- 전사 통합 -->
        <div v-if="'next' != layerType" class="inner">
          <div class="cont-wrap">
            <div class="contents">
              <!-- contents -->
              <!-- 패밀리 그룹 초대 : 패밀리 멤버 수 초과 안내 -->
              <div v-if="'full' == layerType" class="md-text-box">
                <header class="sect-head">
                  <div class="head">
                    <h2 class="ttl">가입하려는 패밀리 그룹<br />정원이 가득찼어요!</h2>
                  </div>
                </header>
                <div class="text">
                  <p>패밀리 그룹은 최대 4명까지만 가능해요.<br />직접 패밀리 그룹을 만들어 패밀리를 초대해 보세요.</p>
                </div>
              </div>

              <!-- 패밀리 그룹 초대 : 패밀리 서비스 초대(가입) 완료 -->
              <div v-else-if="'complete' == layerType" class="md-text-box">
                <header class="sect-head">
                  <div class="head">
                    <h2 class="ttl">패밀리 서비스 가입완료</h2>
                  </div>
                </header>
                <div class="text">
                  <p>패밀리 그룹 멤버들과 다양한 혜택을 누리세요!</p>
                </div>
              </div>

              <!-- 패밀리 그룹 탈퇴 : 패밀리 탈퇴 완료 -->
              <div v-else-if="'exit' == layerType" class="md-text-box">
                <header class="sect-head">
                  <div class="head">
                    <h2 class="ttl">패밀리 그룹 나가기 완료</h2>
                  </div>
                </header>
                <div class="text">
                  <p>다음에 다시 만나요!</p>
                </div>
              </div>
              <!-- //contents -->
            </div>
          </div>
          <!-- 패밀리 그룹 초대 : 패밀리 멤버 수 초과 안내 -->
          <div v-if="'full' == layerType" class="b-btn-wrap type2">
            <div>
              <button type="button" class="white-btn lg" @click="onLayer('full')"><span>다음에 할게요</span></button>
            </div>
            <div>
              <button type="button" @click="goPage('fmlyInfo')" class="check lg">
                <span>패밀리 그룹 만들기</span>
              </button>
            </div>
          </div>
          <!-- 패밀리 그룹 초대 : 패밀리 서비스 초대(가입) 완료 -->
          <div v-else-if="'complete' == layerType" class="b-btn-wrap">
            <div>
              <button class="check lg" @click="goPage('fmlyGrade')"><span>확인</span></button>
            </div>
          </div>
          <!-- 패밀리 그룹 탈퇴 : 패밀리 탈퇴 완료 -->
          <div v-else-if="'exit' == layerType" class="b-btn-wrap">
            <div>
              <button class="check lg" @click="goPage('exit')"><span>확인</span></button>
            </div>
          </div>
        </div>

        <!-- 등급 적용일 기준 안내 레이어 -->
        <div v-if="'next' == layerType" class="inner">
          <div class="title-wrap left">
            <h1>등급 적용일 기준</h1>
          </div>
          <div class="cont-wrap">
            <div class="contents">
              <!-- contents -->
              <div class="detail-infobox left">
                <header class="sect-head">
                  <div class="head">
                    <h2 class="ttl">다음 등급 적용일</h2>
                  </div>
                </header>
                <div class="text">
                  <ul class="dot-list">
                    <li>매월 1일~25일 신규 가입 : 익월부터 적용</li>
                    <li>매월 26일~말일 신규 가입 : 익익월부터 적용</li>
                  </ul>
                </div>
              </div>
              <!-- //contents -->
            </div>
          </div>
        </div>
        <!-- 등급 적용일 기준 안내 레이어 -->
        <div v-if="'next' == layerType" class="close">
          <button aria-label="닫기" @click="onLayer('next')"></button>
        </div>
        <!-- 등급 적용일 기준 안내 레이어 -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalFamilyComplete',
  props: {
    layerType: String
  },
  data() {
    return {
      // 기본 정보
      chanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
      chanlDomain: this.$store.state.apiStore.bodyParam.chanlDomain,
      chanlMyDomain: this.$store.state.apiStore.bodyParam.chanlMyDomain,
      isApp:
        /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) ||
        '2010' === this.$store.state.apiStore.bodyParam.chanlCd
    }
  },
  mounted() {
    console.log('## ModalFamilyComplete Layer layerType : ' + this.layerType)
  },
  methods: {
    onLayer(type) {
      console.log('>> onLayer type : ' + type)
      this.$emit('onLayer', type, false)
    },
    // URL 이동 처리
    goPage(goType) {
      console.log('이동 유형 goType : ' + goType)
      switch (goType) {
        case 'fmlyGrade': //패밀리 그룹
          this.bodyFixedNone()
          this.$router.push('/montblanc/intg/memshpGrade')
          break
        case 'fmlyInfo': //패밀리 그룹 소계/생성 시작
          this.bodyFixedNone()
          this.$router.push('/montblanc/intg/familyInfo')
          break
        case 'exit': //패밀리 그룹 탈퇴 완료 마이쇼핑 이동 처리 필요
          console.log('>> 패밀리 탈퇴 완료 채널(마이쇼핑) 이동처리')
          this.bodyFixedNone()
          this.windowClose()
          break
        default:
          break
      }
    },
    // 기본 설정
    //통합 화면 채널이동(닫기) 처리
    // 패밀리 탈퇴 후 채널이동(마이쇼핑) 처리
    windowClose() {
      console.log('#### 패밀리서비스 채널 마이쇼핑 windowClose 시작 ####')
      console.log(
        '## isApp[' + this.isApp + '], chanlCd[' + this.chanlCd + '], chanlMyDomain[' + this.chanlMyDomain + ']'
      )
      if (this.isApp) {
        console.log('>> 탈퇴 완료 후 채널 이동시... app 세션스토리지 삭제 및 채널(마이쇼핑) 이동 처리')
        sessionStorage.clear()
        if ('3010' == this.chanlCd) {
          location.href = 'toapp://newpage?closeYn=Y&url=' + this.chanlMyDomain + '/mygsshop/myshopInfo.gs'
        } else if ('2010' == this.chanlCd) {
          location.href = 'toapp://close'
        } else {
          location.href = 'toapp://back'
        }
      } else {
        console.log('>> 탈퇴 완료 후 채널 이동시... web 채널(마이쇼핑) 이동 처리')
        if ('3010' === this.chanlCd) {
          location.href = this.chanlMyDomain + '/mygsshop/myshopInfo.gs'
        } else if ('2020' === this.chanlCd) {
          location.href = this.chanlMyDomain + '/cu/my_page'
        } else {
          this.$router.go(-1)
        }
      }
      // console.log(sessionStorage)
      console.log('#### 패밀리서비스 채널 마이쇼핑 windowClose 끝 ####')
    },
    // 모달
    bodyFixed() {
      document.querySelector('html').classList.add('scroll-rock')
      document.body.classList.add('scroll-rock')
    },
    bodyFixedNone() {
      document.body.classList.remove('scroll-rock')
      document.querySelector('html').classList.remove('scroll-rock')
      document.body.removeAttribute('class')
      document.body.removeAttribute('style')
    }
    // 기본 설정
  }
}
</script>
