<template>
  <div class="membership-box integrate">
    <!-- 패밀리 초대 -->
    <div class="family-member-wrap">
      <!-- 소개글 -->
      <section class="family-intro">
        <!-- 수락 시 활성화 -->
        <header class="sect-head">
          <div class="head-colum">
            <h2 class="ttl">패밀리 초대를 수락해주세요.</h2>
          </div>
        </header>
        <!-- //수락 시 활성화 -->

        <!--  UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14 -->
        <section class="family-intro type02">
          <div class="center">
            <span class="highlight">패밀리가 되면 받는 혜택!</span>
          </div>
          <!-- //그룹 생성 설명 -->
          <div class="family-box">
            <div class="tit">
              <h2>패밀리 멤버의 구매 실적이 모여 <br /><strong class="big point">더 빠르게 등급 UP!</strong></h2>
            </div>
            <div class="integration-explanation">
              <p class="emphasis">VVIP</p>
            </div>
          </div>
          <div class="family-box">
            <div class="tit">
              <h2>
                함께 달성한 멤버십 등급의 혜택도 <br /><strong class="big point">멤버 모두 똑같이 받아요!</strong>
              </h2>
            </div>
            <figure class="img-family">
              <img src="@/assets/images/member/family-intro.png" alt="패밀리 이미지" />
            </figure>
          </div>
          <div class="family-box">
            <div class="tit">
              <h2>첫 패밀리 결성 시, <br /><strong class="big point">GS&POINT 1,000P 지급!</strong></h2>
              <sub>단, 패밀리 멤버 2명 이상 시 지급</sub>
            </div>
            <figure class="img-point">
              <img src="@/assets/images/member/family-gspoint.png" alt="GS&POINT 1000포인트 지급" />
            </figure>
          </div>
        </section>
        <!-- // UT기반 패밀리서비스 UIUX 개선 (2차) : 24.08.14 -->
      </section>
      <!-- //소개글 -->
    </div>
    <!-- //패밀리 초대 -->

    <!-- 서비스 유의사항 -->
    <div class="family-notice drop-box" :class="isFmlyNotice == true ? 'drop-open' : ''">
      <div class="drop-tit">
        <button type="button" class="noti-tit" @click="onLayer('notice')">서비스 유의사항</button>
      </div>
      <div class="drop-cont">
        <ul class="dot-list">
          <li>패밀리 서비스는 GS리테일 통합회원 및 패밀리 서비스 이용 약관에 동의하셔야 이용 가능합니다.</li>
          <li>패밀리는 1개만 생성 및 이용 가능하며, 멤버는 총 4명까지 가능합니다.</li>
          <li>
            패밀리 생성 후 멤버 1명 추가 시 또는 초대 받은 패밀리에 가입시 최초 1회 GS&POINT 1,000 포인트가 지급되며,
            포인트를 받은 이후에는 패밀리 생성 및 초대해도 포인트는 지급되지 않습니다.
          </li>
          <li>GS&POINT 미 가입 GS SHOP 회원은 이벤트 적립금으로 지급됩니다.</li>
          <li>
            전월 25일 24시에 등록된 구성원을 기준으로 구성원별 구매실적을 합산하여 당월 등급이 산정됩니다 (매월 1일
            반영되는 등급은 채널에 따라 3개월/6개월 26일~전월 25일까지 실적을 기준으로 합니다.)
          </li>
          <li>초대장을 통해서만 패밀리 가입이 가능합니다.</li>
        </ul>
      </div>
    </div>
    <!-- //서비스 유의사항 -->

    <!-- 버튼 -->
    <div class="b-btn-wrap fixed">
      <div>
        <!-- 버튼 비활성화 disabled 클래스 추가-->
        <button @click="onFmlyTerms" class="check lg">
          <span>초대 수락하기</span>
        </button>
      </div>
    </div>

    <!-- 패밀리 서비스 약관 -->
    <ModalToastTerms
      v-if="isFmlyTerms"
      @onLayer="onLayer"
      @onFmlyJoin="onFmlyJoin"
      @tagging="tagging"
      :join="true"
      :isEncFamyGrpId="isEncFamyGrpId"
    />
    <!-- 패밀리 서비스 초대 완료/오류 레이어 -->
    <ModalFamilyComplete v-if="isFmlyComplete" @onLayer="onLayer" :layerType="layerType" />
    <!-- 공통 Alert 레이어 -->
    <ModalAlert v-if="isAlert" @onLayer="onLayer" :alert="alert" :type="alert.type" />
    <!-- 공통 인증 레이어 -->
    <!-- // 패밀리그룹 초대/수락 이슈 : 24.06.11 -->
    <ModalCert v-if="isCert" @onLayer="onLayer" @onCertNo="onCertNo" />
    <!-- 앰플리튜드 기능 추가-->
    <TagManager v-if="true" ref="tagManager" />
  </div>
</template>

<script>
import ModalToastTerms from '@/views/comm/integrate/ModalToastTerms.vue'
import ModalFamilyComplete from '@/views/comm/integrate/ModalFamilyComplete.vue'
import ModalAlert from '@/views/comm/integrate/ModalAlert.vue'
import ModalCert from '@/views/comm/integrate/ModalCert.vue'
import TagManager from '@/views/comm/TagManager.vue'

export default {
  name: 'IntgFamilyJoin',
  inject: ['sendTitle'],
  data() {
    return {
      title: '초대 수락하기',
      // 기본 정보
      chanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
      chanlDomain: this.$store.state.apiStore.bodyParam.chanlDomain,
      isApp:
        /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) ||
        '2010' === this.$store.state.apiStore.bodyParam.chanlCd,
      // 레이어
      isFmlyTerms: false, // 패밀리 서비스 약관
      isFmlyNotice: true, //패밀리 서비스 유의사항 보기,
      isFmlyComplete: false, // 패밀리 가입완료 레이어 노출 여부
      isJoinError: false, // 패밀리 초과 레이어 노출 여부?????? 오류 화면?????
      layerType: 'complete', //패밀리 초대 완료/오류 유형 설정 (레이어네 문구 노출)
      // 데이터
      famyGrpId: '', // 패밀리그룹ID (파람정보)
      // 패밀리그룹 초대/수락 이슈 : 24.06.11
      isEncFamyGrpId: false, // 패밀리그룹ID 암호화 여부
      isCert: false, // 패밀리 초대 수락 인증번호 레이어 노출 여부
      certNo: '', // 입력된 인증 번호
      //패밀리그룹 가입 데이터 (패밀리 가입여부, 패밀리 멤버수 체크)
      famyAdmData: {
        exposFamyGrpId: '', //이전 패밀리그룹ID
        exposFamyGrpNm: '', //이전 패밀리그룹명
        famyGrpAdmYn: 'N', //패밀리그룹가입여부
        famyGrpId: '', //현 패밀리그룹ID
        famyGrpNm: '', //현 패밀리그룹명
        famyGrpMberCnt: 0, //패밀리멤버 수
        intgMemNo: '' //통합회원번호
      },
      //패밀리그룹 약관동의 데이터
      famyTrmsData: {
        trmsAgreeDtm: '',
        trmsAgreeYn: 'N'
      },
      //패밀리그룹 상세 데이터
      famyGrpDtlData: {},

      // <!-- 알럿(예외처리) 함수 -->
      isAlert: false, //알럿(예외처리) 노출 여부
      // 알럿(예외처리) 정보
      alert: {
        type: 'error', // 유형
        code: '', // (노출)코드
        message: '' // (노출)메시지
      }, //알럿(예외처리) 코드/메세지 정보
      // <!-- //알럿(예외처리) 함수 -->
      isTest: false
    }
  },
  components: {
    ModalToastTerms,
    ModalFamilyComplete,
    ModalAlert,
    // 패밀리그룹 초대/수락 이슈 : 24.06.11
    ModalCert,
    // 앰플리튜드 기능 추가
    TagManager
  },
  created() {
    console.log('')
    console.log('######## 패밀리 그룹 초대/수락 UI ########')
    console.log('')

    if (this.$isEmpty(this.$route.query.famyGrpId)) {
      console.log('@@ 오류 메세지 : 파람 정보 없음 처리 필요')
      // 패밀리그룹ID가 없는 경우 알럿(예외처리) 노출
      this.onAlert('9998', '초대받은 패밀리 정보가 없습니다.')
    } else {
      this.famyGrpId = this.$route.query.famyGrpId // 카톡 딥링크에서 건내준 패밀리ID

      // 패밀리그룹 초대/수락 이슈 : 24.06.11
      this.isEncFamyGrpId = this.getEncFamyGrp(this.famyGrpId)
      console.log('>>>> this.famyGrpId : ' + this.famyGrpId + ', this.isEncFamyGrpId : ' + this.isEncFamyGrpId)

      this.retrieveChFamyGrpAdmYn() // 패밀리 그룹 가입 여부 조회 API
    }
  },
  mounted() {
    document.title = this.title
    this.sendTitle(this.title)
    window.scrollTo(0, 0)

    this.isTest = false
    if (this.isTest) {
      //레이어 테스트
      // this.isCert = true
      // this.layerType = 'cert'
      this.onLayer('cert', true)
    }

    // 앰플리튜드 추가
    this.tagging('VIEW-INTG-FMLY-INV')
  },
  methods: {
    // 레이어 통합 관리
    onLayer(type, boolean) {
      console.log('>>> type : ' + type + ', boolean : ' + boolean)
      switch (type) {
        case 'terms': // 패밀리그룹 서비스 약관 레이어 (비)노출 처리
          this.isFmlyTerms = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
          }
          break
        case 'notice': // 패밀리그룹 서비스 유의사항 레이어 (비)노출 처리
          if (this.isFmlyNotice) {
            this.isFmlyNotice = false
          } else {
            this.isFmlyNotice = true
          }
          break
        case 'full': //패밀리그룹 서비스 초대 멤버 초과 (비)노출 처리
          this.isFmlyComplete = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
            console.log('초대받은 패밀리 멤버 초과. 레이어 닫은 후 닫기 처리')
          }
          break
        case 'complete': //패밀리그룹 서비스 초대 수락 완료/멤버 초과 (비)노출 처리
          this.isFmlyComplete = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
          }
          break
        case 'alert': // 알럿(예외처리) 레이어 (비)노출 처리
          this.isAlert = boolean

          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
            if (
              this.alert.code.startsWith('50') ||
              '9998' == this.alert.code ||
              '9999' == this.alert.code ||
              '4000' == this.alert.code
            ) {
              console.log('>> 알럿(예외처리) 노출 후 마이쇼핑 이동(웹뷰 닫기) 케이스 : ' + this.alert.code)
              this.windowClose()
            } else {
              console.log('그외 채널 이동 처리')
            }
          }
          break
        // 패밀리그룹 초대/수락 이슈 : 24.06.11
        case 'cert': //인증번호 입력 레이어
          this.isCert = boolean
          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
          }
          break
        default:
          break
      }
      return
    },
    // 화면 닫기 처리
    windowClose() {
      console.log('#### windowClose back ####')
      sessionStorage.removeItem('inCheck1')
      //GSSHOP 앱(웹뷰) 여부 체크
      // alert('패밀리서비스 이동 임시 알럿:' + navigator.userAgent + ', isApp[' + this.isApp + ']')
      if (this.isApp) {
        if ('2010' === this.chanlCd || '3010' === this.chanlCd) {
          // alert('패밀리서비스 임시 창닫기 : 앱 닫기 toapp://close 호출')
          location.href = 'toapp://close'
        } else {
          // alert('패밀리서비스 임시 창닫기 : 앱 뒤로가기 toapp://back 호출')
          location.href = 'toapp://back'
        }
      } else {
        // alert('패밀리서비스 임시 창닫기 : 웹 뒤로가기 go(-1) 호출')
        this.$router.go(-1)
      }
      console.log('#### 패밀리서비스 windowClose app/web 창닫기 끝')
      return
    },
    // 화면 이동 처리
    windowMove() {
      return
    },
    // 모달
    bodyFixed() {
      document.querySelector('html').classList.add('scroll-rock')
      document.body.classList.add('scroll-rock')
    },
    bodyFixedNone() {
      document.body.classList.remove('scroll-rock')
      document.querySelector('html').classList.remove('scroll-rock')
      document.body.removeAttribute('class')
      document.body.removeAttribute('style')
    },
    // <!-- 알럿(예외처리) 함수 -->
    onAlert(code, message) {
      console.log('>>> onAlert(알럿/예외처리) 기능 code : ' + code + ', message : ' + message)
      this.alert.code = code
      // 4404 errMsg : 패밀리그룹 가입 이력이(가) 존재합니다.
      // 9998 resMsg : 가입 패밀리그룹이 없는 경우, 파람, API
      // 9999 resMsg : API 오류.
      this.alert.message = message

      this.onLayer('alert', true)
    },
    // <!-- //알럿(예외처리) 함수 -->
    // AS-IS 컴포넌트 사용 함수

    // 기능 부분
    onFmlyTerms() {
      if (this.isTest) {
        console.log('#################### 테스트 데이터 설정 : isTest : ' + this.isTest)
        this.famyTrmsData.trmsAgreeYn = 'N'
        // this.famyAdmData.famyGrpId = 'F000000542'
        this.famyAdmData.famyGrpId = ''
        // this.famyAdmData.famyGrpMberCnt = 4
        this.famyAdmData.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      console.log('#################### 결과 데이터')
      // console.log(this.famyAdmData)
      // console.log(this.famyTrmsData)
      console.log(
        '>> famyGrpId : ' +
          this.famyAdmData.famyGrpId +
          ', intgMemNo : ' +
          this.famyAdmData.intgMemNo +
          ', famyGrpMberCnt : ' +
          this.famyAdmData.famyGrpMberCnt +
          ', trmsAgreeYn : ' +
          this.famyTrmsData.trmsAgreeYn +
          ', trmsAgreeDtm : ' +
          this.famyTrmsData.trmsAgreeDtm
      )
      console.log('#################### 결과 데이터')
      console.log('')

      // 앰플리튜드 추가
      this.tagging('CLICK-INTG-FMLY-INV-JOIN')

      console.log('## 1단계 : 패밀리 기 가입 고객(현 패밀리그릅ID) 체크 famyGrpId : ' + this.famyAdmData.famyGrpId)
      // CASE 1  : 패밀리 그룹 기 가입 고객 경우
      if (!this.$isEmpty(this.famyAdmData.famyGrpId)) {
        console.log('## 기 가입자로 가입정보 화면으로 이동 처리 필요')
        this.$router.push('/montblanc/intg/familyAlready')

        return
      }

      // CASE 3 : 패밀리 그룹 미 가입 고객 > 약관 노출 후 초대 패밀리 수락 처리
      console.log('## 2단계 : 패밀리 그룹 약관 동의 체크 trmsAgreeYn : ' + this.famyTrmsData.trmsAgreeYn)
      if ('Y' != this.famyTrmsData.trmsAgreeYn) {
        console.log('>> 약관 미동의 고객 약관 노출 처리')
        this.onLayer('terms', true)
      } else {
        console.log('>> 약관 동의 고객 패밀리 멤버 수 체크 후 가입 전 인증번호 요청 처리')
        // 패밀리그룹 초대/수락 이슈 : 24.06.11
        if (this.isEncFamyGrpId) {
          this.onLayer('cert', true)
        } else {
          this.onFmlyJoin()
        }
      }
    },
    // 패밀리그룹 초대/수락 이슈 : 24.06.11
    onCertNo(certNo) {
      console.log('#### 입력 인증번호 : ' + certNo + ', this.certNo : ' + this.certNo)
      this.certNo = certNo
      if (!this.$isEmpty(this.certNo) && 4 === this.certNo.length) {
        console.log(
          '#### 입력 인증번호 : ' + certNo + ', this.certNo : ' + this.certNo + ' 패밀리 초대 수락 호출 gogo '
        )
        this.onFmlyJoin()
      } else {
        console.log('#### 인증번호 없음 오류 발생.....')
      }
    },
    onFmlyJoin() {
      console.log('## 3단계 : 패밀리 그룹 멤버 수 체크')
      console.log('## 4단계 : 패밀리 그룹 초대 처리')

      // 패밀리그룹 초대/수락 이슈 : 24.06.11
      // 패밀리그룹ID 암호화로 인하여 패밀리 그룹 멤버 초과 여부 조회 불가
      // CASE 2  : 패밀리 그룹 멤버 초과 경우
      // this.retrieveChFamyGrpInfoDtl()
      // 패밀리그룹 초대/수락 이슈 : 24.06.11
      this.saveChFamyGrpInvitAcp()
      console.log('## 4단계 : 패밀리 그룹 가입여부 체크 종료')
    },
    // 정리 부분
    // API 호출
    // F014		retrieveChFamyGrpAdmYn	패밀리 그룹 가입 여부 조회 API
    async retrieveChFamyGrpAdmYn() {
      console.log('#### methods 1단계 : 패밀리 그룹 가입 여부[F014 : retrieveChFamyGrpAdmYn] str ####')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChFamyGrpAdmYn'
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/retrieveChFamyGrpAdmYn', bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          // console.log(resData)
          if (!this.$isEmpty(resData)) {
            //참고 famyGrpAdmYn : 패밀리 탈퇴 후 등급 적용 경우 'Y'임 > 패밀리 가입여부 값으로 판단 불가 데이터
            this.famyAdmData.famyGrpAdmYn = resData.famyGrpAdmYn //현(이전) 패밀리그룹가입여부
            this.famyAdmData.famyGrpId = resData.famyGrpId //현 패밀리그룹ID
            this.famyAdmData.famyGrpMberCnt = resData.famyGrpMberCnt //패밀리그룹 멤버 수

            console.log('@@@ 패밀리 가입 여부 : famyGrpAdmYn : ' + resData.famyGrpAdmYn)
            this.retrieveChFamyGrpTrmsAgree()
            if ('N' == resData.famyGrpAdmYn) {
              console.log('>>> 패밀리 미가입자 동의 여부 체크 필요')
            } else {
              console.log('>>> 패밀리 가입자 패밀리 가입 확인 페이지 이동 처리 필요대상.')
            }
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert('9999', e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      console.log('#### methods 1단계 : 패밀리 그룹 가입 여부[F014 : retrieveChFamyGrpAdmYn] end ####')
    },
    // F007		retrieveChFamyGrpTrmsAgree	패밀리 그룹 이용약관 동의 여부 조회 API
    async retrieveChFamyGrpTrmsAgree() {
      console.log('#### methods 2단계 : 패밀리 그룹 약관 동의 여부[F007 : retrieveChFamyGrpTrmsAgree] str ####')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChFamyGrpTrmsAgree'
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/retrieveChFamyGrpTrmsAgree', bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          // console.log(resData)
          if (!this.$isEmpty(resData)) {
            this.famyTrmsData.trmsAgreeYn = resData.trmsAgreeYn
            this.famyTrmsData.trmsAgreeDtm = resData.trmsAgreeDtm
            console.log('>>> 패밀리 그룹 약관동의 여부 trmsAgreeYn : ' + resData.trmsAgreeYn)
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert('9999', e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      console.log('#### methods 2단계 : 패밀리 그룹 약관 동의 여부[F007 : retrieveChFamyGrpTrmsAgree] end ####')
    },
    // F003		retrieveChFamyGrpInfoDtl	패밀리 그룹 정보 및 구성원 조회 API
    // async retrieveChFamyGrpInfoDtl() {
    //   console.log('#### methods 3단계 : 패밀리 그룹 상세정보 조회[F003 : retrieveChFamyGrpInfoDtl] set ####')
    //   var headerParam = this.$store.state.apiStore.headerParam
    //   var bodyParam = {
    //     apiId: 'retrieveChFamyGrpInfoDtl',
    //     famyGrpId: this.famyGrpId //		초대받은 패밀리그룹ID
    //   }

    //   // 로컬인 경우 파람정보 추가
    //   if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
    //     bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
    //     bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
    //     bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
    //   }

    //   await this.$axios
    //     .post('/retrieveChFamyGrpInfoDtl', bodyParam, {headers: headerParam})
    //     .then((res) => {
    //       const resData = res.data
    //       console.log(resData)
    //       if (!this.$isEmpty(resData) && '0000' === resData.resCd) {
    //         var count = resData.familyMemberList.reduce((cnt, element) => cnt + ('Y' == element.famyGrpMemStCd), 0)

    //         this.famyGrpDtlData = resData
    //         this.famyAdmData.famyGrpMberCnt = count
    //         console.log('## 패밀리 그룹 멤버 수 체크 famyGrpMberCnt : ' + count)

    //         if (count < 4) {
    //           console.log('>> 패밀리 그룹 초대 수락 처리 요청')
    //           this.saveChFamyGrpInvitAcp()
    //         } else {
    //           console.log('>> 패밀리 그룹 정원 초과 레이어 노출')
    //           this.layerType = 'full'
    //           this.onLayer('full', true)
    //           // 앰플리튜드 추가
    //           this.tagging('VIEW-INTG-FMLY-COMP-OVER')
    //           return
    //         }
    //       } else {
    //         console.log('>> 패밀리 그룹 조회 오류 레이어 노출')

    //         this.onAlert('9998', resData.resMsg)
    //         return
    //       }
    //     })
    //     .catch((e) => {
    //       console.log('@@@@@@@@@@@@@@@@@@@@@@')
    //       console.log(e)
    //       console.log('@@@@@@@@@@@@@@@@@@@@@@')

    //       //API 호출 오류 시 즉시 오류 노출 처리
    //       if (null != e.response && null != e.response.data) {
    //         this.onAlert('9999', e.response.data.resMsg)
    //       } else {
    //         this.onAlert('9999', this.$store.state.message.sysErr)
    //       }
    //       //API 호출 오류 시 즉시 오류 노출 처리
    //     })
    //   console.log('#### methods 3단계 : 패밀리 그룹 상세정보 조회[F003 : retrieveChFamyGrpInfoDtl] end ####')
    // },
    // F005		saveChFamyGrpInvitAcp	패밀리 그룹 초대 수락 API
    async saveChFamyGrpInvitAcp() {
      console.log('#### methods 4단계 : 패밀리 그룹 초대 수락[F005 : saveChFamyGrpInvitAcp] str ####')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'saveChFamyGrpInvitAcp',
        // 패밀리그룹 초대/수락 이슈 : 24.06.11
        famyGrpId: !this.isEncFamyGrpId ? this.famyGrpId : '', //		초대받은 패밀리그룹ID
        admChanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
        famyGrpTrmsAgreeYn: 'Y',
        famyGrpTrmsAgreeDtm: this.$dayjs().format('YYYYMMDDHHmmss'),
        // 패밀리그룹 초대/수락 이슈 : 24.06.11
        // 암호화 값으로 변경
        famyInvitEncKey: this.isEncFamyGrpId ? this.famyGrpId : '',
        telnoCertNo: this.certNo
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/saveChFamyGrpInvitAcp', bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          // console.log(resData)
          if (!this.$isEmpty(resData) && '0000' === resData.resCd) {
            console.log('### 초대 완료 레이어 노출 처리')
            // this.saveChFamyGrpInvitAcpData = res.data
            this.layerType = 'complete'
            this.onLayer('complete', true)
            // 앰플리튜드 추가
            this.tagging('VIEW-INTG-FMLY-COMP')
          } else {
            var msg = resData.resMsg
            if ('4420' === resData.resCd) {
              console.log('>> 패밀리 그룹 정원 초과 레이어 노출')
              this.layerType = 'full'
              this.onLayer('full', true)
              // 앰플리튜드 추가
              this.tagging('VIEW-INTG-FMLY-COMP-OVER')
            } else if ('5304' === resData.resCd) {
              console.log('### 초대자 휴대폰 번호 틀린 경우. 오류 레이어 노출 처리')
              msg = '초대자의 휴대폰 번호가 맞지 않아요.<br/> 번호 확인 후, 다시 입력해주세요.'
              this.onAlert(resData.resCd, msg)
            } else if ('5303' === resData.resCd || '5305' === resData.resCd || '5301' === resData.resCd) {
              console.log('### 기간 만료, 암호화값 오류 레이어 노출 처리')
              msg =
                '패밀리 초대장의 유효기간이 종료되었어요.(유효 기간 : 3일 이내)<br/> 초대자에게 초대장을 다시 요청해주세요.'
              this.onAlert(resData.resCd, msg)
            } else {
              console.log('### 초대 요청 오류 레이어 노출 처리')
              this.onAlert(resData.resCd, msg)
            }
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert('9999', e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      console.log('#### methods 4단계 : 패밀리 그룹 초대 수락[F005 : saveChFamyGrpInvitAcp] end ####')
    },
    // API 호출
    // 정리 부분
    // 앰플리튜드 추가
    tagging(tagId) {
      if (!this.$isEmpty(tagId)) {
        console.log('##### tagging : ' + tagId)
        this.$refs.tagManager.tagging(tagId)
        console.log('>>>>> tagging call end')
      } else {
        console.log('>>>>> tagId is null/empty')
      }
    },
    // 패밀리그룹 초대/수락 이슈 : 24.06.11
    getEncFamyGrp(arg) {
      if (arg.length == 10 && arg.startsWith('F') && !isNaN(arg.substring(1))) {
        console.log('##### >>> 평문 : ' + arg)
        return false
      } else {
        console.log('##### >>> 암호화 : ' + arg)
        return true
      }
    }
  }
}
</script>
