<template>
  <div class="membership-box">
    <div class="family-membership">
      <h3 class="fm-tit">테스트 화면</h3>
      <p class="fm-tit2">TokenPayload</p>
      <div class="family-my gray-box">
        <dl>
          <dt>aud :</dt>
          <dd>{{ this.tokenPaylord.aud }}</dd>
        </dl>
        <dl>
          <dt>통합회원번호 :</dt>
          <dd>{{ this.tokenPaylord.intg_mem_no }}</dd>
        </dl>
        <dl>
          <dt>채널코드 :</dt>
          <dd>{{ this.tokenPaylord.chanl_cd }}</dd>
        </dl>
        <dl>
          <dt>토큰 유효기간 :</dt>
          <dd>{{ tokenExp }}</dd>
        </dl>
      </div>
      <div class="b-btn-wrap">
        <div>
          <button type="button" class="check" @click="postApi"><span>Montblanc API Test</span></button>
        </div>
      </div>

      <div class="family-my gray-box">
        <dl>
          <dt>ApiResult :</dt>
          <dd>{{ apiResult }}</dd>
        </dl>
      </div>
      <button @click="setCookies">.</button>
    </div>
  </div>
</template>
<script>
import VueJwtDecode from 'vue-jwt-decode'
export default {
  name: 'App',
  inject: ['sendTitle'],
  data() {
    return {
      title: '테스트화면',
      tokenPaylord: {
        aud: '',
        intg_mem_no: '',
        chanl_cd: '',
        exp: ''
      },
      tokenExp: '',
      apiResult: ''
    }
  },
  mounted() {
    this.sendTitle(this.title)
    console.log(this.decodeToken())
  },
  methods: {
    async decodeToken() {
      var cookiesKeys = await this.$cookies.keys()
      var index = await cookiesKeys.indexOf('sAs')
      var param = {}
      if (index > -1) {
        var tokenTempData = await VueJwtDecode.decode(this.$cookies.get(cookiesKeys[index]))
        param = {
          aud: tokenTempData.aud,
          intg_mem_no: tokenTempData.intg_mem_no,
          chanl_cd: tokenTempData.chanl_cd,
          exp: tokenTempData.exp
        }
        this.tokenPaylord = param
        await this.tokenExpChk()
        return true
      } else {
        return false
      }
    },

    tokenExpChk() {
      const d = new Date(this.tokenPaylord.exp * 1000)
      this.tokenExp = this.$dayjs(d).format('YYYY-MM-DD HH:mm:ss')
    },
    async postApi() {
      var headerParam = this.$store.state.apiStore.headerParam
      var param = {
        trnscNo: this.$getTrnscNo(this.tokenPaylord.chanl_cd),
        siteId: this.tokenPaylord.chanl_cd,
        apiId: 'retrieveChFamyGrpAdmYn',
        intgMemNo: this.tokenPaylord.intg_mem_no
      }
      await this.$axios
        .post('/retrieveChFamyGrpAdmYn', param, {headers: headerParam})
        .then((res) => {
          console.log(res)
          this.apiResult = 'api 통신 성공'
        })
        .catch((e) => {
          console.log(e)
          this.apiResult = 'api 통신 실패 채널로 return'
        })
    },
    setCookies() {
      var path = ''
      if (process.env.VUE_APP_API_URL == 'http://localhost:8080/montblanc') path = 'localhost'
      else path = 'gshs.co.kr'
      this.$cookies.set(
        'sAs',
        'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJtb250YmxhbmMiLCJpbnRnX21lbV9ubyI6IjEwMDAwMjY4NjYiLCJjaGFubF9jZCI6IjMwMTAiLCJleHAiOjE2Njg2NjU3MTksImlhdCI6MTY2ODY2NTUzOX0.tr8u_UMpYAXh8mUYfHkoFTy7HadzGrYOAK6RmakJFiH94IAIhPTFQsF3FNM4uUmpMeO8vF3Sm0XDicRowPHxnQ',
        '7d',
        '/montblanc',
        path,
        true,
        'Strict'
      )
    }
  }
}
</script>
